import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    language: null
  },
  getters: {
    LANG: state => {
      return state.language
    }
  },
  mutations: {
    set_lang: (state, payload) => {
      state.language = payload
    }
  },
  actions: {
    set_language: (context, language) => {
      context.commit('set_lang', language)
    }
  },
  modules: {
  }
})
