<template>
  <div class="terms">
    <v-row justify="center">
      <v-col xs="11" sm="10" md="6" lg="5" xl="5">
        <center><h3>Terms and Conditions</h3></center>
        <h4>General information: </h4>
        <p>This website <a href="https://www.contractflows.com">www.contractflows.com</a> operated and owned by “Crazy Things, S.A.” The terms “us”, “we”, and “our” refer to “Crazy Things, S.A.”. The access to the site and the use of the services is conditioned to the acceptance of all the terms, conditions, policies, and stipulations contained here. <br>
        By accessing our website and /or using our services, you are accepting all the terms and conditions contained here (“Terms and Conditions”). These terms apply, without limitation, to any user that accesses this website, and /or uses our services, including users that are visitors, clients, providers, content collaborators, amongst others. Please read carefully the Terms and Conditions before accessing our site or using our services. If you don’t agree with our Terms and Conditions, please refrain from accessing our website and using our services. </p>
        <h4> Changes to the Terms of Use: </h4>
        <p>These Terms and Conditions may be freely changed or modified by us. Any change on the Terms and Conditions will be notified on the website, and if applicable, to your email address.  Changes will be effective thirty days after notification. This term will not apply to new users of our website. The continuous use of our website constitutes an agreement to the Terms and Conditions. <br>
        The Terms of Use were last updated on June 20, 2020. </p>
        <h4>Changes to the website: </h4>
        <p>We reserve the right to modify the contents of the website at any time. However, it is not our obligation to do so. You accept that it is your responsibility to keep up to date with any changes in the website. Any new functionality or new service that is added to the website will be subject to the terms and conditions. </p>
        <h4>General conditions: </h4>
        <p>You must be of legal age to use this website.  You may not access or use our website if you are not of legal age, or if you are not allowed to access it by any regulation from the country you are resident. <br>
        You may not use our services or products in any unlawful or unauthorized manner. The website may not be used to violate any law in your jurisdiction. <br>
        You may not upload any document or template from a third party that is subject to copyright protection. <br>
        The failure to comply with the Terms and Conditions gives us the right to suspend the rendering of services to you. </p>
        <h4>Account: </h4>
        <p>If you create and use an account, you are responsible for maintaining the confidentiality of your account and password and for restricting access to it. You agree to accept responsibility for all activities that occur under your account or password. Organizations that use our services are responsible for maintaining the confidentiality of their account, and the account of its members. All the account holders are responsible for their actions and the user of the services. In no way can Crazy Things, S.A., the shareholders, employees, directors, legal representatives, or any other member of Crazy Things, S.A. or any of its subsidiaries be held liable for the actions of the account holders. </p>
        <h4>Right to Refuse. </h4>
        <p>You accept that we reserve the right to refuse service to any individual or organization and to cancel access at any time. </p>
        <h4>Copyright: </h4>
        <p>Any of the contents of this website are either our property or are used under license from our suppliers. Our rights, and those of our suppliers, are protected by local and International intellectual property laws, including those for the protection of copyrights, trademarks, and database rights. Misuse of this website may incur liability. </p>
        <h4>Trademarks: </h4>
        <p>All trademarks, logos and service marks displayed on the Site are our property or the property of third parties. You are not permitted to use these without the consent of the owners. The unauthorized use may constitute an infringement of the holder's rights. By accessing our website and/or using our services, you agree that third-party owned protected trademarks may not be uploaded to the website without previous authorization. </p>
        <h4>Description of services: </h4>
        <p>We provide a subscription service “Contractflows” that allows users and organizations to use tools to simplify the creation of document templates and the drafting, review and generation of documents. During the subscription we grant you a limited, non-exclusive, non-transferable right to access and use Contractflows. No right, title or interest shall be transferred to you. </p>
        <h4>Requirements: </h4>
        <p>To use our service, you must have internet access, a device capable of running an updated web browser, and provide us with one or more payment method. </p>
        <h4>Free trial: </h4>
        <p>Your subscription may start with a free trial. The duration of the free trial period will be specified during the sign-up process. We may limit eligibility or duration of the free trial on our own discretion. At the end of the free trial period, if the subscription is not cancelled, we will charge the user the subscription fee for the next billing cycle. The fee will vary depending of the plan selected by the user. </p>
        <h4>Prices: </h4>
        <p>The price of our services may be modified by us in our sole discretion. The price of the service will vary depending on the selected plan. Any applicable taxes will be determined before the subscription is confirmed. </p>
        <h4>Subscribing: </h4>
        <p>Please verify that all the information regarding the price, duration, taxes, payment details, and subscription plan are accurate before submitting a subscription order. After a subscription order is submitted, you will receive a confirmation an email containing your order information. You accept that any receipt or invoice may be sent directly to your email. </p>
        <h4>Billing and renewal: </h4>
        <p>The subscription will automatically renew at the end of the subscription period, unless you cancel your subscription before the end of the subscription period. The cancellation will take effect the day after the last day of the current subscription period. We do not provide refunds or credits for any partial subscription periods. </p>
        <h4>Warranties: </h4>
        <p>The Contractflows service is provided “as is” and “as available”, without any warranty or condition. Our service may not be uninterrupted or error-free. </p>
        <h4>Liability: </h4>
        <p>We shall not be liable to you in contract, or otherwise for any business losses, such as loss of data, profits, revenue, business, opportunity, goodwill, reputation or business interruption or for any losses arising directly or indirectly from the use of or your inability to use our Services. <br>
        In no event will Crazy Things, its officers, shareholders, employees, agents, directors, subsidiaries, affiliates, successors, suppliers be liable for any indirect, special, incidental, punitive, exemplary or consequential damages, any loss of use, data, business or profits, arising out of the use or inability to use our service. You waive all special, indirect and consequential damages against us. <br>
        Nothing in these Terms and Conditions excludes or limits our liability that cannot be excluded or limited by law. </p>
        <h4>Prohibitions: </h4>
        <p>It is forbidden to use this website for: </p>
        <ul>
          <li> Providing your username or password to any other person or using any other person’s username and password </li>
          <li> Any illegal purpose.</li>
          <li> Infringing or violating treaties, laws, and regulations.</li>
          <li> Infringing copyrighted content.</li>
          <li> Infringing trademark rights.</li>
          <li> Violating the security of the site.</li>
        </ul>
        <h4>Data Privacy: </h4>
        <p>To learn about the use and protection of your information, please review our Privacy Policy.
        <h4>Force Majeure: </h4>
        <p>For purposes of this Agreement, Force Majeure shall mean acts of God, acts, orders, decrees, instructions or other requirements of governmental entities or instrumentalities, insurrections, mobilizations, riots, acts of terrorism, vandalism, sabotage, strikes, lock-outs or other labor disturbances, quarantines, floods, storms, hurricanes or other names storms, tornadoes, droughts or other adverse weather conditions, fires, explosions, embargoes, or by other cause not reasonably within the control of either Party. If either Party is hindered or prevented from performing its obligations under this Agreement as a result of any Force Majeure, the time for the Party’s performance hereunder shall be extended for a period equal to the duration of such Force Majeure. If the Force Majeure extends more than thirty (30) days, we shall be entitled to terminate the Agreement without liability to you. </p>
        <h4>Divisibility: </h4>
        <p>In case it is determined that any stipulation of these Terms and Conditions is not valid or legal, such determination should not affect the validity and applicability of the rest of stipulations. The remaining stipulations will remain in full force and effect. </p>
        <h4>Applicable law: </h4>
        <p>These Terms and Conditions will be governed and interpreted according to the laws of the Republic of Guatemala. </p>
        <p>These Terms and Conditions are the entire agreement between you and us. These Terms substitute and replace any previous Term and Conditions, proposals and communications. </p>
        <h4>Our information: </h4>
        <p>Crazy Things, S.A. <br>
        Registration number: 105334006<br>
        Address:  20 Street, 5-65, zone 10, 5fth floor, Guatemala, Guatemala. <br>
        Contact: contact@contractflows.com</p>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: 'Terms',
  components: {
  },
  data: () => ({
  }),
  mounted () {
    const recaptcha = this.$recaptchaInstance
    recaptcha.hideBadge()
  }
}
</script>
