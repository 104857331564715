<template>
  <div id="settingTemplate">
    <v-container>
      <v-row>
        <v-col>
          <div class="text-body-1 text-justify">
            <div class="text-center text-h4">Configurar formularios:</div>
            <div id="uploadingTemplate" v-intersect="{
              handler: onIntersect,
              options: intersectOptions
            }">
              <br />
              <br />
              <div class="text-h5 mt-0 text-center">Subir una plantilla</div>
              <br />
              <div>
                Despúes de terminar la plantilla, la puedes subir a ContractFlows siguiendo los siguientes pasos:
              </div>
              <br />
              <ol>
                <li>En el menú principal, haz click en el botón de Plantillas</li>
                <li>Haz click en el campo "File Input" . Se abrirá una ventana del explorador.</li>
                <li>
                  Selecciona la plantilla que desees subir y haz click en el botón "Abrir".
                </li>
                <li>Haz click en el botón "Subir Documento".</li>
              </ol>
              <br />
              <div>Antes de cargar una plantilla, revisa que todos los campos estén agregados correctamente.
                No será posible cargar la plantilla a Contractflows si tiene algún error en cuanto a la redacción de los campos.
                Asegurate de seguir las fórmulas según ésta documentación.
              </div>
            </div>
            <v-divider class="my-5"></v-divider>
            </div>
          <div class="text-body-1 text-justify">
            <div id="fieldLabels" v-intersect="{
              handler: onIntersect,
              options: intersectOptions
            }">
              <br />
              <div class="text-h5 mt-0 text-center">Renombrar campos</div>
              <br />
              <div>
                Puedes cambiar el nombre de los campos del formulario para que sea más
                fácil para el usuario llenar la información. Sigue los pasos siguientes para cambiar el nombre a los campos:
              </div>
              <br />
              <ol>
                <li>En la página de Plantillas, haz click en el botón de "Configuración" del formulario que quieras configurar.</li>
                <li>
                  En la sección "Manage Placeholder Names", haz click en el campo al que le cambiarás el nombre.
                </li>
                <li>Escribe el nombre del campo.</li>
                <li>Repite los dos pasos anterioes por cada campo.</li>
                <li>Haz click en el botón "Establecer nuevos nombres" para guardar la configuración.</li>
              </ol>
              <br />
              <div>Puedes cambiar el nombre de los campos cuantas veces sea necesario.</div>
            </div>
            <div id="fieldHints" v-intersect="{
              handler: onIntersect,
              options: intersectOptions
            }"> <br />
            </div>
              <br />
             <div class="text-h5 mt-0 text-center">Establecer pistas</div>
             <br>
             <div>
                Puedes incluir pistas debajo de cada campo en el formulario. Las pistas pueden ser una descripción del campo
                o un ejemplo de cómo el usuario puede llenar la información. Para establecer pistas sigue los siguientes pasos:
            </div>
            <br>
              <ol>
                <li>En la página de Plantillas, haz click en el botón de "Configuración" del formulario que quieras configurar.</li>
                <li>En la sección de Pistas, haz click en el campo que quieras configurar una pista.</li>
                <li>Escribe el texto que quieres que aparezca debajo del campo.</li>
                <li>Repite los dos pasos anteriores para los campos en los que quieras establecer una pista. No es necesario que lo hagas en todos los campos, es una funcionalidad opcional.</li>
                <li>Haz click en el botón "Establecer nuevas pistas" para guardar la configuración.</li>
              </ol>
              <br />
              <div>Puedes cambiar los "hints" cuantas veces necesites.</div>
            <v-divider class="my-5"></v-divider>
            <div id="groupLabelsAndOrder" v-intersect="{
              handler: onIntersect,
              options: intersectOptions
            }"> <br />
              <div class="text-h5 mt-0 text-center">Configurar los nombres y el orden de los grupos</div>
              <br />
              <div>Para realizar lo anterior, sigue los siguientes pasos:</div>
              <br />
              <ol>
                <li>En la página de Plantillas, haz click en el botón de "Configuración" del formulario que quieras configurar.</li>
                <li>En la sección "Nombres de Grupos y Orden", haz click en el grupo que deseas cambiar el nombre.</li>
                <li>Escribe el nuevo nombre del grupo</li>
                <li>Repite los últimos dos pasos por cada grupo.</li>
                <li>Para cambiar el órden en que aparecen los grupos en el formulario, haz click en el campo del grupo y arrastralo en la posición deseada.</li>
                <li>Haz click en el botón "Establecer nombres y orden" para guardar la configuración.</li>
              </ol>
              <br />
              <div>
                Puedes renombrar y reodenar los grupos cuantas veces sea necesario.
              </div>
            </div>
            <v-divider class="my-5"></v-divider>
            <div id="dropdownFields" v-intersect="{
              handler: onIntersect,
              options: intersectOptions
            }"> <br />
              <div class="text-h5 mt-0 text-center">Configurar los campos desplegables</div>
              <br />
              <div>
                Para configurar los campos desplegables sigue los siguientes pasos:
              </div>
              <br />
              <ol>
                <li>En la página de Plantillas, haz click en el botón de "Configuración" del formulario que quieras configurar.</li>
                <li>En la sección de Campos Desplegables, haz click en el campo que deseas configurar.</li>
                <li>Escribe el texto que quieres que aparezca dentro de las opciones de la lista del campo desplegable y presiona la tecla enter.</li>
                <li>Puedes escribir cuantas opciones necesitas.</li>
                <li>Repite los dos pasos anteriores para los demás campos.</li>
                <li>Para guardar los cambios, haz click en el botón"Establecer campos desplegables".</li>
              </ol>
              <br />
              <div>
                Haz click en el botón "x" para borrar una opción de la lista del campo desplegable.
              </div>
           <v-divider class="my-5"></v-divider>
           <div id="directory" v-intersect="{
              handler: onIntersect,
              options: intersectOptions
            }"> <br />
              <div class="text-h5 mt-0 text-center">Configurar un directorio</div>
              <br />
              <div>
                Puedes ordenar tus formularios utilizando directorios. Los directorios funcionan como carpetas, los cuales pueden contener subcdirectorios o formularios.
              </div>
              <br>
              <div>
                Sigue los siguientes pasos para configurar un directorio:
              </div>
              <br />
              <ol>
                <li>En la página de Plantillas, haz click en el botón de "Configuración" del formulario que quieras configurar.</li>
                <li>En la sección de "Configurar Directorio", escribe la tecla diagonal "/".</li>
                <li>Escribe el nombre del directorio. El nombre puede contener letras, números y guiones bajos "_". El nombre no puede contener caracteres especiales o espacios en blanco.</li>
                <li>Para agregar un subdirectorio, escribe la tecla diagonal "/".</li>
                <li>Escribe el nombre del subdirectorio.</li>
                <li>Haz click en el botón "Establecer Directorio" para guardar los cambios.</li>
              </ol>
              <br />
              <div>
                Los directorios pueden contener subdirectorios.
              </div>
              <br>
              <div>
                Ejemplo:  /agreements/leasing
              </div>
              <br>
              <div>
                El directorio aparecería de la siguiente forma:
              </div>
            </div>
            </div>
          </div>
          <br>
          <v-img src='@/assets/img/directory.png' max-width="50%">
               </v-img>
          <v-divider class="my-5"></v-divider>
            <div id="copyConfiguration" v-intersect="{
              handler: onIntersect,
              options: intersectOptions
            }"> <br />
              <div class="text-h5 mt-0 text-center">Copiar configuración de otro formulario</div>
              <br />
              <div>
                Puedes copiar la configuración de un formulario para usarla en otro formulario. Esta funcionalidad aplica
                únicamente para los campos que tengan en común el formulario del cual se copia la configuración y el formulario al cual
                se le aplicará la configuración. Los campos en común son los campos que tienen exactamente el mismo nombre.
                <br>
                Sigue estos pasos para copiar la configuración:
              </div>
              <br />
              <ol>
                <li>En la página de Plantillas, haz click en el botón de "Copiar" del formulario al quieras copiar la configuración.</li>
                <li>Del listado que aparecerá, escoge el formulario del cual quieres copiar la configuración.</li>
                <li>Haz click en el botón "Copiar".</li>
              </ol>
              <br />
              <div>
                La plantilla a la cual se le copió la configuración incluirá el texto "copy" dentro de su descripción.
              </div>
              </div>
        </v-col>
        <v-col class="d-none d-md-flex" lg="2" md="3">
          <subNav :items="items" :isIntersecting="isIntersecting"></subNav>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import SubNav from '@/components/docs/SubNav'

export default {
  name: 'configurarTemplate',
  components: {
    SubNav
  },
  data: () => ({
    isIntersecting: false,
    items: [
      { text: 'Cargar una plantilla', id: 'uploadingTemplate' },
      { text: 'Renombrar campos', id: 'fieldLabels' },
      { text: 'Establecer pistas', id: 'fieldHints' },
      { text: 'Renombrar y ordenar grupos', id: 'groupLabelsAndOrder' },
      { text: 'Campos desplegables', id: 'dropdownFields' },
      { text: 'Configurar directorio', id: 'directory' },
      { text: 'Copiar configuración', id: 'copyConfiguration' }
    ],
    intersectOptions: {
      trackVisibility: true,
      delay: 100,
      rootMargin: '0px',
      threshold: [0.01, 0.02, 0.03, 0.04, 0.05, 0.06, 0.07, 0.08, 0.09, 0.1, 0.11, 0.12, 0.13, 0.14, 0.15, 0.16, 0.17, 0.18, 0.19, 0.2, 0.21, 0.22, 0.23, 0.24, 0.25, 0.26, 0.27, 0.28, 0.29, 0.3, 0.31, 0.32, 0.33, 0.34, 0.35, 0.36, 0.37, 0.38, 0.39, 0.4, 0.41, 0.42, 0.43, 0.44, 0.45, 0.46, 0.47, 0.48, 0.49, 0.5, 0.51, 0.52, 0.53, 0.54, 0.55, 0.56, 0.57, 0.58, 0.59, 0.6, 0.61, 0.62, 0.63, 0.64, 0.65, 0.66, 0.67, 0.68, 0.69, 0.7, 0.71, 0.72, 0.73, 0.74, 0.75, 0.76, 0.77, 0.78, 0.79, 0.8, 0.81, 0.82, 0.83, 0.84, 0.85, 0.86, 0.87, 0.88, 0.89, 0.9, 0.91, 0.92, 0.93, 0.94, 0.95, 0.96, 0.97, 0.98, 0.99, 1, 0]
    }
  }),
  methods: {
    onIntersect (entries, observer, isIntersecting) {
      // https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
      if (entries[0].intersectionRect.top < 100 && (isIntersecting && entries[0].intersectionRatio > 0.50)) {
        // console.log(entries[0].target.id)
        // console.log(entries[0])
        this.isIntersecting = entries[0].target.id
      }
      // this.isIntersecting = isIntersecting // entries[0].intersectionRatio >= 0.5
    }
  },
  mounted () {
    // const recaptcha = this.$recaptchaInstance
    // recaptcha.hideBadge()
  }
}
</script>

<style></style>
