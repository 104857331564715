<template>
    <v-card class="text-center align-stretch card-outter" height="100%">
      <v-card-title class="justify-center pb-0" >
        <div class="static">
          {{plan.title}}
        </div>
      </v-card-title>
      <v-row>
        <v-col class="pb-0">
          <div v-if="!annual" class="text-body-1 text--secondary">{{ $t('pricingM.discount') }}</div>
          <div v-else class="text-body-1 text-decoration-line-through text--disabled">${{ plan.priceUser[0] }}</div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-0">
          <div class="text-h3">
            <div v-if="!annual" class="primary--text">
              ${{ calculatePrice(plan.priceUser[0], nUsers) }}
            </div>
            <div v-else class="green--text">
              ${{ calculatePrice(plan.priceUser[1], nUsers) }}
            </div>
            <div class="text-subtitle-1 text--secondary">{{ $t('pricingM.priceUser') }}</div>
            <v-row align="center" justify="center" class="text-center">
              <v-col cols="4">
                <v-divider class="mb-1" ></v-divider>
              </v-col>
            </v-row>
            <div>
              <div v-if="!annual" class="text-subtitle-1 text--secondary"><span class="primary--text">${{ calculatePrice(plan.priceUserYear[0], nUsers) }}</span>{{ $t('pricingM.priceUserYear') }}</div>
              <div v-else class="text-subtitle-1 text--secondary"><span class="green--text">${{ calculatePrice(plan.priceUserYear[1], nUsers) }}</span>{{ $t('pricingM.priceUserYear') }}</div>
            </div>
            <!-- payments total -->
            <div v-if="!annual">
              <div class="text-subtitle-1 text--secondary">${{ calculatePrice(nUsers*plan.priceUser[0], nUsers) }} {{ $t('pricingM.priceTotalMonth') }}</div>
              <div class="text-subtitle-1 text--secondary">${{ calculatePrice(nUsers*plan.priceUserYear[0], nUsers) }} {{ $t('pricingM.priceTotalYear') }}</div>
            </div>
            <div v-else>
              <div class="text-subtitle-1 text--secondary">${{ calculatePrice(nUsers*plan.priceUser[1], nUsers) }} {{ $t('pricingM.priceTotalMonth') }}</div>
              <div class="text-subtitle-1 text--secondary">${{ calculatePrice(nUsers*plan.priceUserYear[1], nUsers) }} {{ $t('pricingM.priceTotalYear') }}</div>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn outlined large color="primary" :href="'https://app.contractflows.com/free-trial'" target='_blank'>{{ $t('callToAction') }}</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <v-row v-for="(detail, idx) in filterActive(plan.details)" :key="idx" class="px-5 pb-0">
        <v-col class="text-left py-1" cols="auto">
          <v-icon v-if="detail[1] === true" color="green darken-2">mdi-check</v-icon>
          <v-icon v-else color="yellow darken-2">mdi-clock-outline</v-icon>
        </v-col>
        <v-col class="text-left py-2">
          <div v-if="detail[1] === true" class="text-body-1 text--secondary">{{ detail[0] }}</div>
          <div v-else class="text-body-1 text--disabled">{{ detail[0] }}</div>
        </v-col>
        <v-col class="text-left py-1" cols="auto">
          <v-tooltip bottom max-width="200">
            <template v-slot:activator="{ on, attrs }">
              <v-icon small color="grey" dark v-bind="attrs" v-on="on">
                mdi-information-outline
              </v-icon>
            </template>
            <span>{{ $t('pricingM.functionalitiesDescribed')[detail[0]] }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-snackbar v-model="snackbar" :color="snackbarColor">
        {{ snackbarText }}
        <template v-slot:action="{ attrs }">
          <v-btn color="" text v-bind="attrs" @click="snackbar = false">
            {{ $t('close') }}
          </v-btn>
        </template>
      </v-snackbar>
    </v-card>
</template>

<script>
var axios = require("axios")

export default {
  name: 'planCard',
  props: {
    plan: {
      required: true,
      type: Object
    },
    annual: {
      required: true,
      type: Boolean
    },
    nUsers: {
      required: true,
      type: Number
    },
    planIdx: {
      required: true,
      type: Number
    },
  },
  components: {
  },
  data: () => ({
    whatsappWebLink: 'https://web.whatsapp.com/send?phone=50255122473',
    whatsappAppLink: 'https://wa.me/50255122473',
    gettingCheckoutURL: false,
    updateDialog: false,
    confirmationUpdate: '',
    updatingPlan: false,
    rerender: 1,
    // snackbar
    snackbar: false,
    snackbarText: '',
    snackbarTimeout: 5000,
    snackbarColor: '',
  }),
  computed: {
    // eslint-disable-next-line
    styleObject: function () {
      if(this.active){
        return { outline: '3px solid #2196f3'}
      }
      else return
    }
  },
  watch: {
    annual: function(){
      this.rerender += 1
    }
  },
  methods: {
    getSignupLink(plan) {
      return 'https://app.contractflows.com/signup' + (plan ? '?plan=' + plan : '') + (this.annual ? '&annual=true' : '&annual=false')
    },
    getWhatsappLink(text=null){
      if(text == null){
        if(this.$vuetify.breakpoint.mobile){
          return this.whatsappAppLink
        }
        return this.whatsappWebLink
      }
      if(this.$vuetify.breakpoint.mobile){
        return encodeURI(this.whatsappAppLink + '?text='+text)
      }
      return encodeURI(this.whatsappWebLink + '&text='+text)
    },
    setSnackbar (text, color, timeout = 5000) {
      this.snackbarText = text
      this.snackbarColor = color
      this.snackbarTimeout = timeout
      this.snackbar = true
    },
    filterActive (details) {
      var activeDetails = []
      // details.forEach(detail => {
      //   if (detail[1] === true || detail[1] === 'inProgress') {
      //     activeDetails.push(detail)
      //   }
      // })
      for (var key in details) {
        if (details[key] === true) {
          activeDetails.push([key, details[key]])
        }
      }
      return activeDetails
    },
    calculatePrice(total, nUsers){
      if(nUsers>5){
        var newPrice =  (total*0.9).toFixed(2).toString()
        if(newPrice.slice(-2) == '00') newPrice = newPrice.slice(0,-3)
        return newPrice
      }
      else{
        return total
      }
    },
    getCheckoutURL (){
      let vm = this
      vm.gettingCheckoutURL = true
      var data = JSON.stringify({
        uid: vm.$store.getters.UID,
        orgId: vm.$store.getters.ORGID,
        nUsers: vm.nUsers,
        plan: vm.plan.key,
        annual: vm.annual,
        token: vm.$store.getters.TOKEN
      })
      var config = {
        method: 'POST',
        url: process.env.VUE_APP_ENGINE+'payments/checkoutLink',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      }
      // eslint-disable-next-line
      axios(config).then(response => {
        vm.gettingCheckoutURL = false
        var resData = response.data
        window.location.href = resData.checkoutURL
      // eslint-disable-next-line
      }).catch(err => {
        console.error(err)
        vm.gettingCheckoutURL = false
      })
    },
    updateCurrentPlan() {
      let vm = this
      vm.updatingPlan = true
      var data = JSON.stringify({
        uid: vm.$store.getters.UID,
        orgId: vm.$store.getters.ORGID,
        nUsers: vm.nUsers,
        token: vm.$store.getters.TOKEN
      }) 
      var config = {
        method: 'POST',
        url: process.env.VUE_APP_ENGINE+'payments/update/current',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      }
      // eslint-disable-next-line
      axios(config).then(response => {
        vm.confirmationUpdate = ''
        vm.updatingPlan = false
        vm.updateDialog = false
        vm.setSnackbar(vm.$t('payments.updateSuccess'), 'success')
      // eslint-disable-next-line
      }).catch(err => {
        vm.confirmationUpdate = ''
        vm.updatingPlan = false
        vm.updateDialog = false
        vm.setSnackbar(vm.$t('payments.updateError'), 'error', 10000)
      })
    },
    updatePlan() {
      let vm = this
      vm.updatingPlan = true
      var data = JSON.stringify({
        uid: vm.$store.getters.UID,
        orgId: vm.$store.getters.ORGID,
        nUsers: vm.nUsers,
        plan: vm.plan.key,
        annual: vm.annual,
        token: vm.$store.getters.TOKEN
      }) 
      var config = {
        method: 'POST',
        url: process.env.VUE_APP_ENGINE+'payments/update/',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      }
      // eslint-disable-next-line
      axios(config).then(response => {
        vm.confirmationUpdate = ''
        vm.updatingPlan = false
        vm.updateDialog = false
        vm.setSnackbar(vm.$t('payments.updateSuccess'), 'success')
      // eslint-disable-next-line
      }).catch(err => {
        vm.confirmationUpdate = ''
        vm.updatingPlan = false
        vm.updateDialog = false
        vm.setSnackbar(vm.$t('payments.updateError'), 'error', 10000)
      })
    },
    toOrg(){
      this.$router.push('manageorg')
    }
  },
  mounted () {
  }
}
</script>

<style>
.card-outter {
  position: relative;
  padding-bottom: 50px;
}
.card-actions {
  position: absolute;
  bottom: 0;
}
</style>
<style lang="sass" scoped>

</style>
