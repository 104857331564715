var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"workflow"}},[_c('v-container',[_c('v-row',[_c('v-col',[_c('div',{staticClass:"text-body-1"},[_c('div',{staticClass:"text-center text-h4"},[_vm._v("Flujo de trabajo:")]),_c('br'),_c('br'),_c('div',[_vm._v(" Para crear y completar un proceso debes seguir las siguientes etapas: ")]),_c('br'),_c('ol',[_c('li',[_vm._v("Iniciar un proceso")]),_c('li',[_vm._v("Revisar un proceso")]),_c('li',[_vm._v("Generar un documento")])]),_c('br'),_c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:({
            handler: _vm.onIntersect,
            options: _vm.intersectOptions
          }),expression:"{\n            handler: onIntersect,\n            options: intersectOptions\n          }"}],attrs:{"id":"startingNewProcess"}},[_c('br'),_c('div',{staticClass:"text-h5 mt-0 text-center"},[_vm._v("Crear un proceso")]),_c('br'),_c('div',[_vm._v("Sigue los siguientes pasos para crear un proceso:")]),_c('br'),_c('ol',[_c('li',[_vm._v(" En el menú principal, haz click en el botón de Procesos. ")]),_c('li',[_vm._v("Selecciona un fomulario. Puedes acceder a los formularios utilizando la vista por tabla o la vista por directorio. Puedes seleccionarlo de los formularios creados por tu organización o de los proporcionados por Contractflows. ")]),_c('li',[_vm._v("En el campo \"Título del nuevo proceso\" escribe el nombre de tu documento.")]),_c('li',[_vm._v("Haz click en el botón de \"Guardar borrador\", localizado en la parte inferior de la página.")]),_c('li',[_vm._v("Completa todos los cambos del formulario. Es necesario llenar todos los cambios para poder mandar el proceso a revisión.")]),_c('li',[_vm._v("Haz click en el botón de \"Mandar a revisión\" para continuar con el proceso.")])]),_c('br'),_c('div',[_vm._v(" Si decides continuar llenando la información en otra ocasión, haz click en el botón \"Guardar borrador\" antes de salir de la página. El proceso se guardará en la sección \"Tus Borradores\". ")])]),_c('br'),_c('v-divider',{staticClass:"my-5"}),_c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:({
            handler: _vm.onIntersect,
            options: _vm.intersectOptions
          }),expression:"{\n            handler: onIntersect,\n            options: intersectOptions\n          }"}],attrs:{"id":"reviewingAProcess"}},[_c('br'),_c('div',{staticClass:"text-h5 mt-0 text-center"},[_vm._v("Revisar un proceso")]),_c('br'),_c('div',[_vm._v(" Sigue los siguientes pasos para revisar un proceso: ")]),_c('br'),_c('ol',[_c('li',[_vm._v(" En el menú principal, haz click en Procesos para Revisión. ")]),_c('li',[_vm._v("Selecciona el proceso a revisar.")]),_c('li',[_vm._v("Revisa el contenido de los cambios. Puedes modificar el contenido antes de aprobarlo o de rechazarlo.")]),_c('li',[_vm._v("Si quieres rechazar el proceso para que sea envíado al creador del proceso para que lo corrija, haz click en el botón \"Rechazar\".")]),_c('li',[_vm._v("Para aprobar un proceso y enviarlo a la etapa de generar docuemnto, haz click en el botón de \"Aprobar\".")])]),_c('br'),_c('v-divider',{staticClass:"my-5"}),_c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:({
            handler: _vm.onIntersect,
            options: _vm.intersectOptions
          }),expression:"{\n            handler: onIntersect,\n            options: intersectOptions\n          }"}],attrs:{"id":"generateDocument"}},[_c('br'),_c('div',{staticClass:"text-h5 mt-0 text-center"},[_vm._v("Generar un documento")]),_c('br'),_c('div',[_vm._v(" Sigue los siguentes pasos para generar un documento: ")]),_c('br'),_c('ol',[_c('li',[_vm._v(" En el menú principal, selecciona Procesos. ")]),_c('li',[_vm._v(" En la parte superior, haz click en el botón \"Genera un documento\". ")]),_c('li',[_vm._v(" Selecciona el documento que quieres generar. ")]),_c('li',[_vm._v(" Haz click en el botón de \"Generar documento\". ")])]),_c('br'),_c('div',[_vm._v(" El documento se descargará de forma automática en tu computadora. Los documentos generados se almacenarán en la carpeta que tu computadora tenga configurada para almacenar descargas. ")]),_c('br'),_c('div',[_vm._v(" Puedes generar los documentos cuantas veces quieras. ")]),_c('br')])],1)],1),_c('br'),_c('v-divider',{staticClass:"my-5"}),_c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:({
            handler: _vm.onIntersect,
            options: _vm.intersectOptions
          }),expression:"{\n            handler: onIntersect,\n            options: intersectOptions\n          }"}],attrs:{"id":"copyContent"}},[_c('div',{staticClass:"text-h5 mt-0 text-center"},[_vm._v("Copia el contenido de un documento aprobado")]),_c('br'),_c('div',[_vm._v(" Puedes completar la información de un borador con la información contenida en un documento aprobado. Esto aplica para los campos que tengan en común el fomulario del borrador y el formulario del documento aprobado. Los campos en común son aquellos que tienen exactamente el mismo nombre. Los únicos campos cuyo contenido no se copia son los campos de fecha, los cuales deben ser ingresados nuevamente. ")]),_c('br'),_c('div',[_vm._v(" Sigue estos pasos para copiar el contenido de un documento aprobado. ")]),_c('br'),_c('ol',[_c('li',[_vm._v(" En el menú principal, haz click en el botón de Procesos. ")]),_c('li',[_vm._v(" Si ya tienes creado un borrador, sigue con el siguiente paso. Crea un proceso y guardalo como borrador. ")]),_c('li',[_vm._v(" En la parte superior, haz click en el botón \"Genera un documento\". ")]),_c('li',[_vm._v(" Selecciona el documento aprobado del cual quieres copiar el contenido y haz click en el icono copiar. ")]),_c('li',[_vm._v(" De la lista de borradores que aparecerá, selecciona el borrador al cual quieres copiar el contenido. ")]),_c('li',[_vm._v(" Haz click en el botón \"Copiar\". ")])]),_c('br'),_c('div',[_vm._v(" Una vez realizada la copia, puedes continuar con el proceso y enviarlo a revisión. ")]),_c('br')]),_c('v-divider',{staticClass:"my-5"}),_c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:({
            handler: _vm.onIntersect,
            options: _vm.intersectOptions
          }),expression:"{\n            handler: onIntersect,\n            options: intersectOptions\n          }"}],attrs:{"id":"batchGenerate"}},[_c('div',{staticClass:"text-h5 mt-0 text-center"},[_vm._v("Batch Documents Generation")]),_c('br'),_c('div',[_vm._v(" Es posible generar varios documentos utilizando la información contenida en un documento de Excel. ")]),_c('br'),_c('div',[_vm._v(" Para generar varios documentos, sigue los siguientes pasos: ")]),_c('br'),_c('ol',[_c('li',[_vm._v(" En el menú principal, haz click en Procesos. ")]),_c('li',[_vm._v(" Selecciona un formulario. ")]),_c('li',[_vm._v(" Haz click en el botón \"Batch Generate\". ")]),_c('li',[_vm._v(" Haz click en el botón \"Click here for template\". Se descargará un n documento de Exceldocumento de Excel en tu dispositivo. ")]),_c('li',[_vm._v(" Abre el documento de Excel que se descargó y completa la información. Las columnas tienen en los encabezados el nombre de los campos. Completa la información en el documento de acuerdo al tipo de campo. (Para los Campos de Números, utiliza únicamente números. Para los Campos de Fecha, escribe la fecha en formato YYYY-MM-DD. Para los Campos Opcionales, escribe \"True\", si quieres que la opción sea seleccionada. Para los Campos Desplegables, escribe el texto que quieres que se consigne en el documento. Después de llenar la información, guarda el archivo y cierralo. ")]),_c('li',[_vm._v(" En Contractflows, haz click en \"seleccionar un documento\", se abrirá una ventana del explorador. Selecciona el archivo Excel y haz click en el botón de \"abrir\". ")]),_c('li',[_vm._v(" Haz click en el botón \"Batch Generate\". Se descargará a tu dispositivo un archivo zip que contiene los documentos generados. ")])]),_c('br'),_c('br')])],1),_c('v-col',{staticClass:"d-none d-md-flex",attrs:{"lg":"2","md":"3"}},[_c('subNav',{attrs:{"items":_vm.items,"isIntersecting":_vm.isIntersecting}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }