<template>
  <div id="documentation" class="pa-4 text-left">
    <v-row>
      <v-col cols="12" sm="12" md="auto">
        <v-card class="mx-auto" tile>
          <v-list>
            <!-- <v-subheader>REPORTS</v-subheader> -->
            <v-list-item-group v-model="item" color="primary">
              <v-list-item
                v-for="(item, i) in this.items[this.$i18n.locale]"
                :value="item.key"
                :key="i"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
      <v-col>
        <v-sheet class="pa-8">
          <v-component v-bind:is="item"></v-component>
        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Fields from '@/components/docs/Fields'
import OrganizingTemplate from '@/components/docs/OrganizingTemplate'
import SettingTemplate from '@/components/docs/SettingTemplate'
import Workflow from '@/components/docs/Workflow'
import Campos from '@/components/docs/Campos'
import OrganizarTemplate from '@/components/docs/OrganizarTemplate'
import Flow from '@/components/docs/Flow'
import ConfigurarTemplate from '@/components/docs/ConfigurarTemplate'
export default {
  name: 'Documentation',
  components: {
    Fields,
    OrganizingTemplate,
    SettingTemplate,
    Workflow,
    Campos,
    OrganizarTemplate,
    Flow,
    ConfigurarTemplate
  },
  data: () => ({
    item: 'fields',
    items: {
      en: [
        { key: 'fields', text: 'Fields', icon: 'mdi-text' },
        { key: 'organizingTemplate', text: 'Organizing a template', icon: 'mdi-file-document-outline' },
        { key: 'settingTemplate', text: 'Setting up a template', icon: 'mdi-file-cog-outline' },
        { key: 'workflow', text: 'Workflow', icon: 'mdi-file-send-outline' }
      ],
      es: [
        { key: 'campos', text: 'Tipos de campos', icon: 'mdi-text' },
        { key: 'organizarTemplate', text: 'Organizar formularios', icon: 'mdi-file-document-outline' },
        { key: 'flow', text: 'Flujo de Trabajo', icon: 'mdi-file-cog-outline' },
        { key: 'configurarTemplate', text: 'Configurar formularios', icon: 'mdi-file-send-outline' }
      ]
    }
  }),
  computed: {
  },
  mounted () {
    this.$vuetify.goTo('#documentation')
    this.item = this.items[this.$i18n.locale][0].key

    this.$store.watch(
      (state, getters) => getters.LANG,
      // eslint-disable-next-line
      (newValue, oldValue) => {
        this.item = this.items[this.$i18n.locale][0].key
      }
    )
  },
  watch: {
    item: function (newItem, oldItem) {
      if (newItem == null) {
        this.item = oldItem
      }
    }
  }
}
</script>

<style>
.v-card__text, .v-card__title {
  word-break: normal; /* maybe !important  */
}
</style>
