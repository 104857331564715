<template>
    <!-- <v-row justify="center">
      <v-col>
        <div class="text-h4">{{ $t('pricingM.templateCustomization') }}</div>
      </v-col>
    </v-row> -->
    <v-card height="100%" hover style="cursor: initial;" class="pb-5">
      <v-row justify="center" class="px-5 mx-3 text-center">
        <v-col>
          <div class="text-h4">{{ $t('pricingM.customizationSlogan') }}</div>
        </v-col>
      </v-row>
      <v-row justify="center" class="px-5 mx-3 text-center">
        <v-col class="pt-0">
          <div class="text-h6">{{ $t('pricingM.customizationSlogan2') }}</div>
        </v-col>
      </v-row>
      <v-row justify="center" class="px-5 mx-3 text-center">
        <v-col class="pt-0" cols="12" md="10" lg="8">
          <div class="text-body-2"><span class="text-h3">${{ $t('pricingM.customizationPrice') }}</span></div>
          <div class="text-body-2">{{ $t('pricingM.customizationPriceDes') }}</div>
        </v-col>
      </v-row>
      <v-row justify="center" class="px-5 mx-3">
        <v-col class="text-justify" cols="12" md="10" lg="12">
          <div class="text-body-1 text--secondary">{{ $t('pricingM.customizationDes') }}</div>
        </v-col>
      </v-row>
      <!-- <v-row justify="center" class="px-5 mx-3 text-body-1">
        <v-col class="pt-0" cols="12">
          <ol>
            <li v-for="step in $t('pricingM.customizationSteps')" :key="step">
              {{ step }}
            </li>
          </ol>
        </v-col>
      </v-row> -->
      <v-row justify="center" class="px-5 mx-0 text-left text-body-1" >
        <v-col class="pt-0" cols="auto">
          <v-btn outlined large class="mt-3" color="primary" to="contact">{{ $t('contactButton') }}</v-btn>
        </v-col>
      </v-row>
    </v-card>
</template>

<script>
export default {
  name: 'customization',
  props: {
  },
  components: {
  },
  data: () => ({
  }),
  computed: {
  },
  methods: {
  }
}
</script>

<style>

</style>
