<template>
  <div id="organizingTemplate">
    <v-container>
      <v-row>
        <v-col>
          <div class="text-body-1 text-justify">
            <div class="text-center text-h4">Organizar formularios</div>
            <br />
            <br>
            <div id="agruparCampos" v-intersect="{
              handler: onIntersect,
              options: intersectOptions
            }">
              <div class="text-h5 mt-0 text-center" text-center>Agrupar campos</div>
              <br />
              <div>
                Para organizar el formulario y facilitar el llenado de la información,
                puedes agrupar los campos dentro de la plantilla. Para hacerlo, los campos que deseas agrupar
                deben contener el nombre del grupo más un punto más el nombre del campo,
                todo entre llaves "{}". El nombre del campo puede contener únicamente
                letras de la A a la Z, números del 0 al 9, y guión bajo "_". El nombre
                del grupo no puede contener acentos, espacios en blanco o caracteres
                especiales.
              </div>
              <br />
              <div>
                <span class="font-weight-bold">Fórmula: </span> {nombreGrupo.nombreCampo}
              </div>
              <br />
              <div>Puedes agrupar cualquier tipo de campos.</div>
              <br />
              <div class="text-decoration-underline">
                Ejemplos: campos agrupados:
              </div>
              <br />
              <div>Campo simple: {condiciones.producto}</div>
              <div>Campo de fecha: {condiciones.fechaPago_date}</div>
              <div>Campo de números: {condiciones.cantidad_letters} ({condiciones.cantidad})</div>
              <div>Campo dropdown: {condiciones.transaccion_drop}</div>
              <div>Campo de opción: {#condiciones.tecladosGratis} Texto opcional {/condiciones.tecladosGratis}</div>
            </div>
            <v-divider class="my-5"></v-divider>
            <div id="gruposOpcionales" v-intersect="{
              handler: onIntersect,
              options: intersectOptions
            }"> <br>
              <div class="text-h5 mt-0 text-center">Grupo opcional</div>
              <br />
              <div>
                Un grupo opcional es un derivado de un campo opcional. Los grupos opciones se muestran como un checkbox en la parte superior de los
                formularios. Cuando se selecciona, se muestra en el formulario un grupo nuevo con los campos asociados al grupo opcional.
              </div> <br/>
              <div>
                Si el grupo opcional no es seleccionado, el contenido opcional (textos y/o campos) no se incluirán en el documento que se genere.
              </div><br>
              <div>
                Los grupos opcionales están compuestos de tres elementos:
              </div>
              <br>
              <ol>
                <li>
                  Campo de inicio: {#nombreGrupo}
                </li><br>
                <li>
                  Contenido opcional:
                  <ul>
                    <li>
                      Texto:
                    </li>
                    <div>
                      Puede contener cualquier text o textos.
                    </div>
                    <li>
                      campos:
                    </li>
                    <div>
                      Puede contener otro tipo de campos que se organicen en el grupo opcional.
                    </div>
                  </ul>
                </li><br>
                <li>
                 Campo final: {/nombreGrupo}
                </li>
              </ol>
              <br>
              <div>
                <span class="font-weight-bold">Fórmula: </span> {#nombreGrupo} Contenido opcional (texto y/o campos)
                 {/nombreGrupo}
              </div><br>
              <div class="text-decoration-underline">Ejemplo de un grupo opcional:</div>
              <br>
              <div>
                Texto: “Juan Fernández le vende 10 computadoras por $5,000.00.”
              </div>
              <div>
                Contenido opcional del grupo: “Se incluirán ________(___) teclados gratis por la compra de las computadoras"
              </div>
              <br>
              <div>
                Campos de grupo opcional: {#tecladosGratis}  {/tecladosGratis}
              </div>
              <div>
                Campos dentro del contenido opcional: {tecladosGratis.cantidad} {tecladosGratis.cantidad_letters}
              </div>
              <div>
                Texto con campos de grupo opcional: “Juan Fernández le vende 10 computadoras por $5,000.00
                {#tecladosGratis} Se incluirán {tecladosGratis.cantidad_letters} ({tecladosGratis.cantidad}) teclados gratis por la compra de las computadoras{/tecladosGratis}”
              </div>
              <br>
              <div>
                Si el grupo opcional es seleccionado:
              </div>
              <div>
                *Se muestra el grupo en el formulario de ContractFlows con el campo "{tecladosGratis.cantidad}"
              </div>
              <div>
                Input: 10
              </div>
              <div>
                Resultado:
                "Juan Fernández le vende 10 computadoras por $5,000.00.
                 Se incluirán diez (10) teclados gratis por la compra de las computadoras"
              </div>
            </div>
            <br />
          </div>
        </v-col>
        <v-col class="d-none d-md-flex" lg="2" md="3">
          <subNav :items="items" :isIntersecting="isIntersecting"></subNav>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import SubNav from '@/components/docs/SubNav'

export default {
  name: 'organizarTemplate',
  components: {
    SubNav
  },
  data: () => ({
    isIntersecting: false,
    items: [
      { text: 'Agrupar campos', id: 'agruparCampos' },
      { text: 'Grupos opcionales', id: 'gruposOpcionales' }
    ],
    intersectOptions: {
      trackVisibility: true,
      delay: 100,
      rootMargin: '0px',
      threshold: [0.01, 0.02, 0.03, 0.04, 0.05, 0.06, 0.07, 0.08, 0.09, 0.1, 0.11, 0.12, 0.13, 0.14, 0.15, 0.16, 0.17, 0.18, 0.19, 0.2, 0.21, 0.22, 0.23, 0.24, 0.25, 0.26, 0.27, 0.28, 0.29, 0.3, 0.31, 0.32, 0.33, 0.34, 0.35, 0.36, 0.37, 0.38, 0.39, 0.4, 0.41, 0.42, 0.43, 0.44, 0.45, 0.46, 0.47, 0.48, 0.49, 0.5, 0.51, 0.52, 0.53, 0.54, 0.55, 0.56, 0.57, 0.58, 0.59, 0.6, 0.61, 0.62, 0.63, 0.64, 0.65, 0.66, 0.67, 0.68, 0.69, 0.7, 0.71, 0.72, 0.73, 0.74, 0.75, 0.76, 0.77, 0.78, 0.79, 0.8, 0.81, 0.82, 0.83, 0.84, 0.85, 0.86, 0.87, 0.88, 0.89, 0.9, 0.91, 0.92, 0.93, 0.94, 0.95, 0.96, 0.97, 0.98, 0.99, 1, 0]
    }
  }),
  methods: {
    onIntersect (entries, observer, isIntersecting) {
      // https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
      if (entries[0].intersectionRect.top < 100 && (isIntersecting && entries[0].intersectionRatio > 0.50)) {
        // console.log(entries[0].target.id)
        // console.log(entries[0])
        this.isIntersecting = entries[0].target.id
      }
      // this.isIntersecting = isIntersecting // entries[0].intersectionRatio >= 0.5
    }
  },
  mounted () {
    // const recaptcha = this.$recaptchaInstance
    // recaptcha.hideBadge()
  }
}
</script>

<style></style>
