var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"workflow"}},[_c('v-container',[_c('v-row',[_c('v-col',[_c('div',{staticClass:"text-body-1"},[_c('div',{staticClass:"text-center text-h4"},[_vm._v("Workflow:")]),_c('br'),_c('br'),_c('div',[_vm._v(" To create and complete a process, the following stages must be completed: ")]),_c('br'),_c('ol',[_c('li',[_vm._v("Start a process")]),_c('li',[_vm._v("Review the process")]),_c('li',[_vm._v("Generate the document")])]),_c('br'),_c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:({
            handler: _vm.onIntersect,
            options: _vm.intersectOptions
          }),expression:"{\n            handler: onIntersect,\n            options: intersectOptions\n          }"}],attrs:{"id":"startingNewProcess"}},[_c('br'),_c('div',{staticClass:"text-h5 mt-0 text-center"},[_vm._v("Starting a new process")]),_c('br'),_c('div',[_vm._v("To start a new process follow these steps:")]),_c('br'),_c('ol',[_c('li',[_vm._v(" In the main menu, click on Processes. ")]),_c('li',[_vm._v("Select a template. You can access the templates using Table View and Directory View. You can select it from your Organization' templates or from Contraflows' templates. ")]),_c('li',[_vm._v("Type the name of your document in the \"New Process Name\" field.")]),_c('li',[_vm._v("Click on the Save Draft button located at the bottom of the page.")]),_c('li',[_vm._v("Fill all the fields on the form. In order to send a process for review, all the fields must be filled.")]),_c('li',[_vm._v("Click on the Send for Review button to continue the process.")])]),_c('br'),_c('div',[_vm._v(" If you decide to continue filling the form in another time, click on Save Draft button before leaving the webpage. The process will be saved in \"Your Drafts\" Section. ")])]),_c('br'),_c('v-divider',{staticClass:"my-5"}),_c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:({
            handler: _vm.onIntersect,
            options: _vm.intersectOptions
          }),expression:"{\n            handler: onIntersect,\n            options: intersectOptions\n          }"}],attrs:{"id":"reviewingAProcess"}},[_c('br'),_c('div',{staticClass:"text-h5 mt-0 text-center"},[_vm._v("Reviewing a Process")]),_c('br'),_c('div',[_vm._v(" To review a process follow these steps: ")]),_c('br'),_c('ol',[_c('li',[_vm._v(" In the main menu, click on Reviews. ")]),_c('li',[_vm._v("Select the process to review.")]),_c('li',[_vm._v("Review the content of the fields. You can modify the content before approving or rejecting the process.")]),_c('li',[_vm._v("If you want to reject the process and send it to the process' creator for corrections, click on the Reject Button.")]),_c('li',[_vm._v("To approve the process, click on the approve button.")])]),_c('br'),_c('v-divider',{staticClass:"my-5"}),_c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:({
            handler: _vm.onIntersect,
            options: _vm.intersectOptions
          }),expression:"{\n            handler: onIntersect,\n            options: intersectOptions\n          }"}],attrs:{"id":"generateDocument"}},[_c('br'),_c('div',{staticClass:"text-h5 mt-0 text-center"},[_vm._v("Generating the document")]),_c('br'),_c('div',[_vm._v(" To generate the document follow these steps: ")]),_c('br'),_c('ol',[_c('li',[_vm._v(" In the main menu, click on Processes. ")]),_c('li',[_vm._v(" Click on the Generate Approved button. ")]),_c('li',[_vm._v(" Select the document you want to generate. ")]),_c('li',[_vm._v(" Click on the Generate Document button. ")])]),_c('br'),_c('div',[_vm._v(" The document will be automatically downloaded to your computer. You can find the documents generated through Contractflows in your computer's designated download location. ")]),_c('br'),_c('div',[_vm._v(" You can generate the documents as many times as needed. ")]),_c('br')])],1)],1),_c('br'),_c('v-divider',{staticClass:"my-5"}),_c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:({
            handler: _vm.onIntersect,
            options: _vm.intersectOptions
          }),expression:"{\n            handler: onIntersect,\n            options: intersectOptions\n          }"}],attrs:{"id":"copyContent"}},[_c('div',{staticClass:"text-h5 mt-0 text-center"},[_vm._v("Copy an approved process' information")]),_c('br'),_c('div',[_vm._v(" You can fill the draft process' infromation by copying an approved process'information. This applies to fields that are shared (same name) between the draft's template and the approved process' template. This does not apply to date fields. ")]),_c('br'),_c('div',[_vm._v(" To copy an approved process' information, follow these steps: ")]),_c('br'),_c('ol',[_c('li',[_vm._v(" In the main menu, click on Processes. ")]),_c('li',[_vm._v(" If you already have created the draft process to which you want to copy the information, continue with the next step. Else, create a process and save it as a draft. ")]),_c('li',[_vm._v(" Click on the Generate Approved button. ")]),_c('li',[_vm._v(" Choose the approved process from which you wish to copy the content, and click on the copy icon. ")]),_c('li',[_vm._v(" From the pop-up list, select the draft process to which you want to copy the information. ")]),_c('li',[_vm._v(" Click on the \"Copy\" button. ")])]),_c('br'),_c('div',[_vm._v(" After copying the content, you can continue with the process by completing or changing informationa and sending it for review. ")]),_c('br')]),_c('v-divider',{staticClass:"my-5"}),_c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:({
            handler: _vm.onIntersect,
            options: _vm.intersectOptions
          }),expression:"{\n            handler: onIntersect,\n            options: intersectOptions\n          }"}],attrs:{"id":"copyContent"}},[_c('div',{staticClass:"text-h5 mt-0 text-center"},[_vm._v("Batch Documents Generation")]),_c('br'),_c('div',[_vm._v(" You can batch generate documents using information from an excel file. ")]),_c('br'),_c('div',[_vm._v(" To batch generate documents, follow these steps: ")]),_c('br'),_c('ol',[_c('li',[_vm._v(" In the main menu, click on Processes. ")]),_c('li',[_vm._v(" Select a template. ")]),_c('li',[_vm._v(" Click on the \"Batch Generate\" button. ")]),_c('li',[_vm._v(" Click on the \"Click here for template\" button. An Excel file will be downloaded to your device. ")]),_c('li',[_vm._v(" Open the downloaded Excel file and fill in the information. Each column has a heading with the name of the template's field. Fill in the information according to the type of field (For Number Fields, use only numbers. For Date Fields, write the date in YYYY-MM-DD format. For Option Fields, write \"True\" to select the field. For Dropwdown fields, write the text that will be displyed in the document). After you finish filing in the information, save the file and close it. ")]),_c('li',[_vm._v(" In Contractflows, click on \"select a file button\", an explorer window will be displayed. Select the Excel file and click on \"open\" button. ")]),_c('li',[_vm._v(" Click on the \"Batch Generate\" button. A zip file containing the generated documents will be downloaded to your device. ")])]),_c('br'),_c('br')])],1),_c('v-col',{staticClass:"d-none d-md-flex",attrs:{"lg":"2","md":"3"}},[_c('subNav',{attrs:{"items":_vm.items,"isIntersecting":_vm.isIntersecting}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }