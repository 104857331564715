<template>
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 91.22 114.51">
    <path class="cls-1" d="M90.92,81.2C89.83,63.57,76.13,50.47,58.35,50.06c-1.6,0-3.21-.05-4.81-.05A25.73,25.73,0,0,0,71,25.66,25.58,25.58,0,0,0,63.44,7.38,25.12,25.12,0,0,0,45.63,0h-.2a25.14,25.14,0,0,0-18,7.61A25.75,25.75,0,0,0,37.67,50c-1.71,0-3.47,0-5.21,0-16.8.4-29.9,12.08-31.84,28.4-.88,7.33-.64,14.78-.4,22v.21c.11,3.17,3.22,6.39,6,7.31a123.58,123.58,0,0,0,39.3,6.54h0a124.93,124.93,0,0,0,39.15-6.44c3-1,6-4.86,6.24-8.11A137.72,137.72,0,0,0,90.92,81.2Zm-7,18.22a4.63,4.63,0,0,1-1.45,2,114.93,114.93,0,0,1-74-.1,4,4,0,0,1-1.18-1.15C7,93.25,6.77,86.09,7.58,79.3,9.1,66.53,19.4,57.39,32.63,57.07c2.92-.07,5.91,0,8.79,0l4.28,0,3.81,0h.13c2.81,0,5.72,0,8.55,0,14.28.34,24.86,10.44,25.74,24.58A129.77,129.77,0,0,1,83.88,99.42ZM58.5,12.35A18.75,18.75,0,0,1,58.44,39a17.92,17.92,0,0,1-13,5.33,18.71,18.71,0,0,1-13-31.78A18.21,18.21,0,0,1,45.5,7l0-1.5V7A18.28,18.28,0,0,1,58.5,12.35Z"/><polygon class="cls-1" points="81.68 45.01 83.52 47.85 87.96 47.85 89.46 47.77 89.46 45.8 85.51 40.13 88.95 35.06 89.21 34.58 89.21 32.62 83.23 32.62 81.68 35.27 80.14 32.62 75.66 32.62 74.16 32.72 74.16 34.68 77.86 40.13 74.18 45.41 73.91 45.88 73.91 47.85 79.84 47.85 81.68 45.01"/>
  </svg>
</template>

<script>
export default {
  name: 'noError'
}
</script>

<style>
  .cls-1{
    fill:#0e3a5e;
  }
</style>