<template>
  <v-app>
    <v-app-bar app elevate-on-scroll fixed color="primary" dark>
      <v-app-bar-nav-icon dark class="d-md-none" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title @click="$router.push('/')" style="cursor: pointer;">
        <v-row v-if="!$vuetify.breakpoint.mobile" no-gutters align="center" class="d-none d-md-flex">
          <v-col cols="auto" class="text-left pr-0">
            <v-img :src="require('@/assets/logo.png')" contain :max-width="$vuetify.application.top-0.8 + 'px'" :max-height="$vuetify.application.top*0.8 + 'px'"></v-img>
          </v-col>
          <v-col cols="auto pr-0" class="text-left"  >
            <img class="pt-2" style="left: -25px" :src="require('@/assets/logoLetters.png')" :height="$vuetify.application.top*0.5 + 'px'">
          </v-col>
        </v-row>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn class="d-none d-md-flex" href="" target="" text  to='pricing'>
        {{$t('pricing')}}
        <v-icon></v-icon>
      </v-btn>
      <!-- <v-btn class="d-none d-md-flex" target="" text to='functions'>
        Funciones
        <v-icon></v-icon>
      </v-btn>
      <v-btn class="d-none d-md-flex" target="" text to='useCases'>
        Casos de Uso
        <v-icon></v-icon>
      </v-btn> -->
      
      <v-btn class="d-none d-md-flex" target="" text to="contact">
        {{$t('contact')}}
        <v-icon></v-icon>
      </v-btn>
      <v-col cols="auto">
        <v-select style="width:128px" outlined class="pr-3 d-none d-md-flex" v-model="language" :items="languages" item-text="title" item-value="language" hide-details single-line dense flat></v-select>
      </v-col>
      <v-divider vertical></v-divider>
      <v-btn color="white" class="primary--text" :class="[!$vuetify.breakpoint.smAndDown ? 'ml-6 mr-2' : '']" solo href="https://app.contractflows.com/free-trial" target='_blank'>
        {{$t('CTA')}}
        <v-icon></v-icon>
      </v-btn>
      <v-tooltip v-if="!$vuetify.breakpoint.smAndDown" bottom class="d-none d-md-flex text-center">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="" to v-bind="attrs" v-on="on" icon href="https://app.contractflows.com/">
            <v-icon>mdi-account</v-icon>
          </v-btn>
        </template>
        <span>{{$t('login')}}</span>
      </v-tooltip>
    </v-app-bar>
    <v-navigation-drawer app temporary v-model="drawer">
      <template v-slot:prepend>
        <div class="pa-2">
          <v-select
            class="px-0"
            v-model="language"
            :items="languages"
            item-text="title"
            item-value="language"
            hide-details
            single-line
            dense
            solo
            flat
          ></v-select>
          <v-divider></v-divider>
        </div>
      </template>
      <v-list nav dense>
        <v-list-item-group v-model="group" active-class="text--accent-4">
          <v-list-item to="/home">
            <v-list-item-title>{{ $t('home') }}</v-list-item-title>
          </v-list-item>

          <v-list-item to="pricing">
            <v-list-item-title>{{ $t('pricing') }}</v-list-item-title>
          </v-list-item>

          <v-list-item to="contact">
            <v-list-item-title>{{ $t('contact') }}</v-list-item-title>
          </v-list-item>

          <v-list-item href="https://app.contractflows.com/">
            <v-list-item-title>{{ $t('login') }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <router-view></router-view>
    </v-main>
    
    <v-footer app absolute padless dark style="z-index: 0" :style="'height:'+$vuetify.application.footer+'px'">
      <v-card text tile class="text-center" width=100%>
        <v-card-text>
          <v-row justify="center">
            <v-col cols="auto" class="text-center">
              <v-img :src="require('@/assets/logo.png')" contain :max-width="$vuetify.application.top + 'px'" :max-height="$vuetify.application.top + 'px'"></v-img>
            </v-col>
            <v-col cols="auto" class="px-0 text-center">
              <v-img :src="require('@/assets/logoLetters.png')" contain :max-height="$vuetify.application.top*0.3 + 'px'"></v-img>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="12" md="auto" class="px-0">
              <v-btn to="/pricing" text>{{$t('pricing')}}</v-btn>
            </v-col>
            <v-col cols="12" md="auto" class="px-0">
              <v-btn href="https://app.contractflows.com" target="_blank" text>LOG IN</v-btn>
            </v-col>
            <v-col cols="12" md="auto" class="px-0">
              <v-btn to="/contact" text>{{$t('contact')}}</v-btn>
            </v-col>
            <v-col cols="12" md="auto" class="px-0">
              <v-btn to="/docs" text>{{$t('DOCS')}}</v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="px-0">
              <v-btn v-for="icon in icons" :key="icon.icon" icon :href="icon.link"  target=“_blank”>
                <v-icon size="24px">{{ icon.icon }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row justify="center" align="center" class="grey--text pb-0 mb-0">
            <v-col class="text-right" cols="6" >
              <a href="terms" depressed class="grey--text">
                {{ $t('terms') }}
              </a>
            </v-col>
            <!-- <v-divider vertical></v-divider> -->
            <v-col class="text-left" cols="6">
              <a href="privacy" depressed class="grey--text">
                {{ $t('privacy') }}
              </a>
            </v-col>
            <v-col cols="12" class="px-0">
              <div class="grey--text">{{ new Date().getFullYear() }} — <strong>Contractflows</strong></div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>

import i18n from '@/plugins/i18n'
import {analytics} from '@/services/firebase'
import {logEvent} from 'firebase/analytics'

export default {
  name: 'App',

  components: {
  },

  data: () => ({
    //
    whatsappWebLink: 'https://web.whatsapp.com/send?phone=50255122473',
    contractflows: 'Contractflows',
    drawer: false,
    group: null,
    icons: [
      {icon: 'mdi-facebook', link:'https://www.facebook.com/contractflows'},
      {icon: 'mdi-instagram', link:'https://www.instagram.com/contractflows/'},
      {icon: 'mdi-linkedin', link:'https://www.linkedin.com/company/contractflows'},
    ],
    languages: [
      { flag: 'us', language: 'en', title: 'English' },
      { flag: 'es', language: 'es', title: 'Español' }
    ],
    language: 'es'
  }),
  computed: {
  },
  methods: {
    getWhatsappLink(text=null){
      if(text == null){
        return this.whatsappWebLink
      }
      return encodeURI(this.whatsappWebLink + '&text='+text)
    },
    changeLocale (locale) {
      i18n.locale = locale
    },
    getBrowserLocale (options = {}) {
      const defaultOptions = { countryCodeOnly: false }
      const opt = { ...defaultOptions, ...options }
      const navigatorLocale =
        navigator.languages !== undefined
          ? navigator.languages[0]
          : navigator.language
      if (!navigatorLocale) {
        return undefined
      }
      const trimmedLocale = opt.countryCodeOnly
        ? navigatorLocale.trim().split(/-|_/)[0]
        : navigatorLocale.trim()
      return trimmedLocale
    }
  },
  computed: {

  },
  mounted () {
    if (localStorage.language) {
      this.language = localStorage.language
    } else {
      const browserLocale = this.getBrowserLocale({ countryCodeOnly: true })
      if (['en', 'es'].includes(browserLocale)) {
        this.language = browserLocale
        // this.language = 'es'
      } else {
        this.language = 'es'
      }
    }
    // const recaptcha = this.$recaptchaInstance
    // recaptcha.hideBadge()
  },
  watch: {
    language: function () {
      localStorage.language = this.language
      this.$i18n.locale = this.language
      this.$store.dispatch('set_language', this.language)
    }
  }
}
</script>

<style lang="sass">
  #language
    width: 2000
  a
    text-decoration: none

  .v-card__text, .v-card__title
    word-break: normal
</style>
