<template>
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 83.08 90.38">
    <path class="cls-1" d="M80.81,14c-1.26-1.29-3.61-2.77-7.61-2.48h0A33.17,33.17,0,0,1,60.83,10C55.25,8.22,50.65,5,46.89,2a8.33,8.33,0,0,0-10.61-.15L35,2.79c-.81.62-1.62,1.23-2.45,1.82C29.08,7.06,24.38,10,19,10.74c-2.9.41-5.95.55-8.89.67L8,11.51a8.09,8.09,0,0,0-7.7,5.78A7.75,7.75,0,0,0,0,18.8V19s.14,4.84.49,9.43l.05.29a10.48,10.48,0,0,1,.22,1.7c.05.85.11,1.7.2,2.63.6,4.4,2.56,15.74,7.9,26.08.94,1.64,2,3.5,3.12,5.29.08.13.16.25.26.43.47.72.94,1.44,1.44,2.15,6.2,8.79,14.31,15.4,22.14,21.23a9.49,9.49,0,0,0,5.67,2.16,9.39,9.39,0,0,0,5.57-2.1A121.73,121.73,0,0,0,63.18,74.39a66.81,66.81,0,0,0,15.3-25.47A102.27,102.27,0,0,0,82.88,24c0-.48.05-.95.07-1.43s0-1.1.08-1.65A8.55,8.55,0,0,0,80.81,14ZM7.47,19.27s.4-.24,2-.21h2c10.2.15,19.57-3.34,28.6-10.73,1-.79,1.25-.85,1.29-.85s.32,0,1.29.78c4,3.09,9.42,6.9,15.92,8.92A46.92,46.92,0,0,0,74.4,19a1.59,1.59,0,0,1,1,.23.9.9,0,0,1,.13.6c-.46,11.76-2.1,27-10.83,40.39-3.92,6-9.08,11.56-16.26,17.4C47.08,78.8,45.6,79.92,44.16,81L42.1,82.59a.79.79,0,0,1-1.19,0C33.2,76.93,24.48,69.85,18.25,60.31A59.07,59.07,0,0,1,9.5,38.25c-.88-5-1.38-10.17-1.86-15.18l-.2-2A4.86,4.86,0,0,1,7.47,19.27Z"/><path class="cls-1" d="M55.48,36.73a4.25,4.25,0,0,0-4.68-2.6A7.83,7.83,0,0,0,47.21,36c-3,2.83-5.91,5.84-9.08,9-1.11-1.17-2-2.19-3-3.13a4.9,4.9,0,0,0-6.77,0,4.72,4.72,0,0,0,0,6.62c2.06,2.14,4.16,4.23,6.3,6.3a4.71,4.71,0,0,0,7.06-.09Q48.16,48.4,54.49,42A4.61,4.61,0,0,0,55.48,36.73Z"/>
  </svg>
</template>

<script>
export default {
  name: 'secure'
}
</script>

<style>
  .cls-1{
    fill:#0e3a5e;
  }
</style>