import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const messages = {
  en: {
    DOCS: 'DOCS',
    CTA: 'Try it for free',
    close: 'Close',
    home: 'Home',
    pricing: 'Pricing',
    contact: 'Let\'s Talk',
    login: 'Log in',
    slogan: {
      first: 'Automate the process of drafting, revising, and signing of contracts.',
      second: 'Optimize the reach of your services.',
    },
    videoCTA: 'Take a peek at the platform',
    // QUOTES //
    quotes:{
      title: 'Some quotes from our users',
      items: [
        {text: 'fdsafdasfdsafdsa dfasf dsaf h fdshfdsf dshddasfdas', name:'Ajfljkas f', company: ''},
        {text: 'fd asfds fasd fdsaf asf dahfds  fdshdsfh s', name:'fdsaf a', company: 'fdsasd '},
        {text: 'f sadfd sa fdasf dsaf dasf sa gfdgfdsg fds ghfdsh fds', name:'fdas ', company: 'asdfasd'},
        {text: ' fdsafs dfsa fd asdfd sa fsdafdsa dsfa fds af', name:'fdsa dsa', company: 'fdsafdsaf'},
        {text: 'f fdsa fsda fdhfdh fd shfshfdsf ds  fds sagds shhfds', name:'ffdsaf ddsa', company: 'asdfdasfs'},
      ]
    },
    ///////// CLIENTS ////////
    clients: {
      title: 'Our clients are proof of the added value'
    },
    ///////// BENEFITS ///////
    benefits: {
      main: [
        {icon: '$easy', text:"Easy to use"},
        {icon: '$eficient', text:"20x more productive"},
        {icon: '$centralized', text:"Centralized"},
        {icon: '$noError', text:"No human error"},
        {icon: '$secure', text:"Secure & Confidential"},
      ],
      CTA: 'Check the results for yourself'
    },
    ///////// HOWITWORKS ///////
    howItWorks: {
      title: 'Contracts in 4 easy steps',
      caption: 'Interactive example',
      steps: {
        select: {
          title: 'Select a template',
          text: 'Use a personalized or a pre-uploaded template',
          caption: 'These are a few examples from the available templates'
        },
        fill: {
          title: 'Fill in the information',
          text: 'With our easy to use dynamic forms',
          example: {
            personType: 'Kind of person',
            contactType: 'Contact type',
            date: "Date",
            dateHint: "Enter today's date",
            name: "Name",
            nameHint: "Write your name",
            number: "Phone number",
            numberHint: "Enter your phone number",
            email: "Email",
            emailHint: "Enter your email",
            reference: "Reference Num.",
            referenceHint: "Autogenerated",
            company: "Company",
            companyHint: "Enter your company"
          }
        },
        generate: {
          title: 'Generate the document',
          text: 'In an editable Word .docx file',
        },
        sign:{
          title: 'Sign',
          text: 'With electronic signature or physically',
          example:{
            signHere: 'Sign Here',
            erase: 'Reset',
            send: 'Send'
          }
        }
      }
    },
    ///////// PRICING ///////
    plans: {
      text: 'Schedule a meeting and see for yourself how Contractflows simplifies your processes',
      CTA: 'Schedule a Demo',
      title: 'Pricing plans starting from $25/month',
      seePlans: 'Go to Pricing'
    },
    ///////// USECASES ///////
    useCases: {
      title: 'USE CASES',
      CTA: 'Learn more',
      realEstate: {
        title: 'Real Estate',
        text: 'Explore our specialized tools for real estate law, saving months in time'
      },
      lawFirms: {
        title: 'Law Firms',
        text: 'Your team will get more things done, in less time, with no errors'
      },
      entrepreneurs: {
        title: 'Entrepreneurs', 
        text: 'Develop your business with legal requirements at a fraction of the cost'
      },
    },
    ///////// FUNCTIONS ///////
    functions: {
      title: 'FEATURES',
      CTA: 'Learn more about our features',
      items: [
        {title: 'DOCUMENTS READY TO SIGN IN MINUTES', text:'Easily fill in the info with our dynamic forms.', img: require('@/assets/img/ready.jpg')},
        {title: 'USE YOUR OWN TEMPLATES', text:'Upload as many of your own templates. With Contractflows you can use your own or use the pre-uploaded set from each country.', img: require('@/assets/img/templates.jpg')},
        {title: 'NEVER FORGET IMPORTANT DATES', text:'Be proactive by maintaining an ordered control of deadlines and important dates, providing a better service.', img: require('@/assets/img/dates.jpg')},
        {title: 'VISUALIZE YOUR TEAMS\'S PRODUCTIVITY', text:'Visualize key performance indicators of your team in real time in our dashboard.', img: require('@/assets/img/dashboardP.jpg')},
      ],
    },
    ///////// FAQ ///////
    faq: {
      title: 'FRECUENTLY ASKED QUESTIONS',
      CTA: 'You have more questions?',
      caption: 'Enter your email and we will contact you',
      CTA2: 'Try it for free',
      caption2: 'Do not wait to transform your processes, do it today in a few minutes',
      send: 'Send',
      items: [
        {
          action: 'mdi-ticket',
          items: [{ title: 'You just need a browser and internet connection (We do not support Internet Explorer).' }],
          title: 'Do I need to install any software to use the service?',
        },
        {
          action: 'mdi-school',
          items: [{ title: 'A Word .docx file is generated and downloaded to your computer, you can make any changes later.' }],
          title: 'Can I make changes to the generated documents?',
        },
        {
          action: 'mdi-school',
          items: [{ title: 'We have a Word-Addin available to make it easy for you to modify setup your templates. Once they are done, you can upload them in the `Templates` view.' }],
          title: 'How do I configure the templates from my company and use them in Contractflows?',
        },
        {
          action: 'mdi-school',
          items: [{ title: 'We offer free training in your facilities or through video conferencing. We also provide visual material so you can learn on your own.' }],
          title: 'Do you offer any kind of training?',
        },
        {
          action: 'mdi-school',
          items: [{ title: 'We have different pricing plans. Choose a type of plan, decide between monthly or annual payments, and select the number of users.'}],
          title: 'How do the pricing plans work?',
        },
      ],
    },
    // contact
    contactTitle: 'Contact us',
    name: 'Name',
    nameRules: [
      v => !!v || 'Name is required',
      v => (v && v.length <= 30) || 'Name must be less than 30 characters'
    ],
    country: 'Country',
    countryRules: [
      v => !!v || 'Country is required'
    ],
    email: 'Email',
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
    ],
    subject: 'Subject',
    subjectRules: [
      v => !!v || 'Subject is required'
    ],
    message: 'Message',
    messageRules: [
      v => !!v || 'Message is required'
    ],
    agreement: 'Accept the privacy policy',
    agreementRules: [
      v => !!v || 'You must agree to continue!'
    ],
    submit: 'Submit',
    whatsText1: 'You can send us a message',
    whatsText2: 'to our WhatsApp',
    // pricing
    pricingM: {
      personalizedTemplatesText: 'In every plan you can use your own ',
      personalizedTemplates: 'personalized templates',
      users: 'User | Users',
      choose: 'Three options, pick one.',
      noSize: 'No matter your needs, we got your back.',
      monthly: 'Monthly',
      annual: 'Annual',
      priceUser: '/ user per month',
      discount: 'Save 20% with annual payments',
      bulkDiscount: 'Save an additional 10% when acquiring more than 5 user subscriptions',
      $: '$',
      priceUserYear: ' / user per year',
      priceTotalMonth: 'total per month',
      priceTotalYear: 'total per year',
      minimum: 'Includes {n} paid users',
      additionalUsers: '{price} extra per additional user',
      functions: 'Functions',
      comparison: 'Plan Comparison',
      coming: 'Coming soon',
      functionalities: [
        'Access to Contractflows templates',
        'Unlimited processes',
        'Unlimited personalized templates',
        'Standard support',
        'Dedicated support',
        'Review Process',
        'Word Add-in',
        'Dashboard',
        'Alert System',
        'Batch Generation',
        'Form sharing',
        'Public Forms',
        'File Attachments',
        'Electronic Signature',
        'Query RGP-GT',
        'Planifier',
        'Custom Workflows',
        'AI Template Automation'
      ],
      functionalitiesDescribed: {
        'Access to Contractflows templates': 'Use any of the ready to use templates provided by us.',
        'Unlimited processes': 'Create as many processes as you need to generate documents.',
        'Unlimited personalized templates': 'You can upload as many personalized templates as you need, available only to your organization.',
        'Standard support': 'Chat and email our support team 18/5',
        'Dedicated support': 'Direct support channel, any day, any time.',
        'Review Process': 'Before generating a document, send it to a review process to greatly reduce errors.',
        // eslint-disable-next-line
        'Dashboard': 'Get to know what your team is working on and plan ahead.',
        'Alert System': 'Create alerts to remind you for specific deadlines and bring better support to your clients.',
        'Batch Generation': 'Generate a batch of documents from an Excel File',
        'Form sharing': 'Pass on the work and get the information you need directly from your clients, easy and secure.',
        'Public Forms': 'Share links with your clients to fill a form and start a process seamlessly.',
        'File Attachments': 'Add files to your processes as support',
        'Word Add-in': 'Easily create personalized templates with the help of our Word Add-In, available at the office store.',
        'Electronic Signature': 'Complete your legal processes with integrated electronic signatures.',
        'Query RGP-GT': 'Automate your queries to the General Property Registry of Guatemala',
        'Planifier': 'Create Aziumuts for multiple plans from a single Excel file.',
        'Custom Workflows': 'Enforce steps and requirements to approve a process.',
        'AI Template Automation': 'Makes personalized templates easier, by scanning your documents with entity recognition AI algorithms.'
      },
      plans: [
        {
          id: 'basic',
          title: 'Basic',
          price: ['150', '1440'],
          priceUserYear: ['360', '288'],
          priceUser: ['30', '24'],
          minUsers: '5',
          details: {
            'Access to Contractflows templates': true,
            'Unlimited processes': true,
            'Unlimited personalized templates': true,
            'Standard support': true,
            'Word Add-in': true,
            'Electronic Signature': true,
            'Review Process': false,
            // eslint-disable-next-line
            'Dashboard': false,
            'Alert System': false,
            'Batch Generation': false,
            'Form sharing': false,
            'Public Forms': false,
            'File Attachments': false,
            'Query RGP-GT': false,
            'Planifier': false,
          }
          // details: [
          //   ['Access to Contractflows templates', true],
          //   ['Unlimited processes', true],
          //   ['Unlimited personalized templates', true],
          //   ['Standard support', true],
          //   ['Review Process', true],
          //   ['Dashboard', false],
          //   ['Alert System', false],
          //   ['Batch Generation', false],
          //   ['Form sharing', false],
          //   ['Word Add-in', true],
          //   ['Electronic Signature', 'inProgress']
          // ]
        },
        {
          id: 'standard',
          title: 'Standard',
          price: ['250', '4320'],
          priceUserYear: ['480', '384'],
          priceUser: ['40', '32'],
          minUsers: '10',
          details: {
            'Access to Contractflows templates': true,
            'Unlimited processes': true,
            'Unlimited personalized templates': true,
            'Standard support': true,
            'Word Add-in': true,
            'Electronic Signature': true,
            'Query RGP-GT': true,
            'Planifier': true,
            'Review Process': true,
            // eslint-disable-next-line
            'Dashboard': true,
            'Alert System': true,
            'Batch Generation': false,
            'Form sharing': false,
            'Public Forms': false,
            'File Attachments': false,
            // eslint-disable-next-line
            'Custom Workflows': 'inProgress',
            'AI Template Automation': 'inProgress'
          }
        },
        {
          id: 'advanced',
          title: 'Advanced',
          price: ['450', '4430'],
          priceUserYear: ['600', '480'],
          priceUser: ['50', '40'],
          minUsers: '20',
          details: {
            'Access to Contractflows templates': true,
            'Unlimited processes': true,
            'Unlimited personalized templates': true,
            'Dedicated support': true,
            'Word Add-in': true,
            'Electronic Signature': true,
            'Query RGP-GT': true,
            'Planifier': true,
            'Review Process': true,
            // eslint-disable-next-line
            'Dashboard': true,
            'Alert System': true,
            'Batch Generation': true,
            'Form sharing': true,
            'Public Forms': true,
            'File Attachments': true,
            // eslint-disable-next-line
            'Custom Workflows': 'inProgress',
            'AI Template Automation': 'inProgress'
          }
        }
      ],
      // template customization
      templateCustomization: 'Template Customization',
      customizationSlogan: 'Don\'t want to create templates by yourself?',
      customizationSlogan2: 'We got you covered.',
      customizationDes: 'Contractflows offers a template customization service just for you. We take responsibility for meeting your requirements and ensuring quality for your custom templates. The good thing is we create it once, and you can use the same template multiple times.',
      customizationPrice: '35',
      customizationPriceDes: 'per template',
      customizationSteps: ['Base document identification', 'Use cases definitions', 'Field definitions', 'Two iterations for refining details', 'Template delivery'],

      // single User
      singleTitle: 'Need a single user?',
      singlePrice: '45',
      perMonth: 'per Month',
      singleFeatures: {
        'Access to Contractflows templates': true,
        'Unlimited processes': true,
        'Unlimited personalized templates': true,
        'Standard support': true,
        // eslint-disable-next-line
        'Word Add-in': true,
      },
      signUp: 'Sign Up'

    },
    pricingSlogan: 'It\'s simple, just choose how many users you need',
    users: 'Users',
    itemsInclude: ['Unlimited templates', 'Unlimited document generation', 'Unlimited processes',
      'Access to predefined templates', 'Standard Support',
      'Billed monthly', 'No setup fee', 'Ready to use'],
    callToAction: 'Subscribe',
    moreUsers: 'Need more users?',
    contactAction: 'Contact sales and we will find a good fit for you',
    contactButton: 'Contact sales',
    months: '/ month',
    // footer
    terms: 'Terms & Conditions',
    privacy: 'Privacy Policy',

    // support
    support: {
      title: 'Support',
      message: 'Please refer to our documentation page at contractflows.com/docs. If the issue persists, please contact us at:',
      email: 'support@contractflows.com',
      message2: "We'll be glad to answer your questions and find solutions together!"
    }
  },
  es: {
    DOCS: 'DOCS',
    CTA: 'PRUÉBALO GRATIS',
    close: 'Cerrar',
    home: 'Inicio',
    pricing: 'Planes',
    contact: 'Hablemos',
    login: 'Ingresar',
    slogan: {
      first: 'Automatiza el proceso de creación, revisión y firma de tus contratos.',
      second: 'Optimiza el alcance de tus servicios.',
    },
    videoCTA: 'Dale un vistazo a la plataforma',
    // QUOTES //
    quotes:{
      title: 'Nuestros usuarios piensan que...',
      items: [
        {text: 'fdsafdasfdsafdsa dfasf dsaf h fdshfdsf dshddasfdas', name:'Ajfljkas f', company: ''},
        {text: 'fd asfds fasd fdsaf asf dahfds  fdshdsfh s', name:'fdsaf a', company: 'fdsasd '},
        {text: 'f sadfd sa fdasf dsaf dasf sa gfdgfdsg fds ghfdsh fds', name:'fdas ', company: 'asdfasd'},
        {text: ' fdsafs dfsa fd asdfd sa fsdafdsa dsfa fds af', name:'fdsa dsa', company: 'fdsafdsaf'},
        {text: 'f fdsa fsda fdhfdh fd shfshfdsf ds  fds sagds shhfds', name:'ffdsaf ddsa', company: 'asdfdasfs'},
      ]
    },
    ///////// CLIENTS ///////
    clients: {
      title: 'Nuestros clientes lo comprueban'
    },
    ///////// BENEFITS ///////
    benefits: {
      main: [
        {icon: '$easy', text:"Fácil de usar"},
        {icon: '$eficient', text:"20x más eficiente"},
        {icon: '$centralized', text:"Centralizado"},
        {icon: '$noError', text:"Sin error humano"},
        {icon: '$secure', text:"Confidencial y seguro"},
      ],
      CTA: 'Comprueba los resultados por tí mismo'
    },
    ///////// HOWITWORKS ///////
    howItWorks: {
      title: 'Contratos en sólo 4 pasos',
      caption: 'Interactúa con el ejemplo',
      steps: {
        select: {
          title: 'Selecciona una plantilla',
          text: 'Utiliza una plantilla pre-cargada o una personalizada',
          caption: 'Estos son unos ejemplos de las plantillas disponibles'
        },
        fill: {
          title: 'Completa la información',
          text: 'Por medio de formularios dinámicos, fáciles de usar',
          example: {
            personType: 'Tipo de persona',
            contactType: 'Vía de contacto',
            date: "Fecha",
            dateHint: "Ingresar la fecha de hoy",
            name: "Nombre",
            nameHint: "Escribe tu nombre",
            number: "Número de teléfono",
            numberHint: "Ingresa tu teléfono",
            email: "Correo Electrónico",
            emailHint: "Ingresa tu email",
            reference: "Referencia",
            referenceHint: "Autogenerado",
            company: "Empresa",
            companyHint: "Escribe tu empresa"
          }
        },
        generate: {
          title: 'Genera el documento',
          text: 'En formato Word editable',
        },
        sign:{
          title: 'Firma',
          text: 'Con firma electrónica o físicamente',
          example:{
            signHere: 'Firma Aquí',
            erase: 'Borrar',
            send: 'Enviar'
          }
        }
      }
    },
    ///////// PRICING ///////
    plans: {
      text: 'Agenda una reunión para ver cómo Contractflows puede aportar valor en tu negocio',
      CTA: 'Agendar Demo',
      title: '¡Planes desde $25 al mes!',
      seePlans: 'Ver Planes'
    },
    ///////// USECASES ///////
    useCases: {
      title: 'CASOS DE USO',
      CTA: 'Conoce más',
      realEstate: {
        title: 'Inmobiliarias',
        text: 'Contamos con herramientas especiales para el derecho inmobiliario'
      },
      lawFirms: {
        title: 'Despachos jurídicos',
        text: 'Tu equipo podrá hacer más trabajo en menos tiempo y sin errores'
      },
      entrepreneurs: {
        title: 'Emprendedores', 
        text: 'Desarrolla tu negocio cumpliendo con los requisitos legales a bajo costo'
      },
    },
    ///////// FUNCTIONS ///////
    functions: {
      title: 'FUNCIONALIDADES',
      CTA: 'Conoce más funcionalidades',
      items: [
        {title: 'CONTRATOS LISTOS PARA FIRMAR EN MINUTOS', text:'Con los formularios dinámicos, ingresas la información y generas contratos de la forma más ágil y sencilla.', img: require('@/assets/img/ready.jpg')},
        {title: 'UTILIZA TUS PROPIAS PLANTILLAS', text:'Además de poder utilizar las plantillas precargadas de cada país, Contractflows te permite subir tus propias plantillas.', img: require('@/assets/img/templates.jpg')},
        {title: 'NO OLVIDES LAS FECHAS IMPORTANTES', text:'Con nuestro sistema de alertas podrás tener un control ordenado de los plazos y fechas importantes en los contratos.', img: require('@/assets/img/dates.jpg')},
        {title: 'VISUALIZA LA PRODUCTIVIDAD DE TU EQUIPO', text:'Accede al Dashboard Dinámico para tener acceso en tiempo real de la productividad de tu equipo.', img: require('@/assets/img/dashboardP.jpg')},
      ],
    },
    ///////// FAQ ///////
    faq: {
      title: 'PREGUNTAS FRECUENTES',
      CTA: '¿Tienes más preguntas?',
      caption: 'Ingresa tu correo y te contactaremos',
      send: 'Enviar',
      CTA2: 'Pruébalo Gratis',
      caption2: 'Transforma tus procesos en cuestión de minutos',
      items: [
        {
          action: 'mdi-ticket',
          items: [{ title: 'Solo necesitas un navegador y conexión a internet (No soportamos Internet Explorer).' }],
          title: '¿Necesito instalar algún programa para utilizar el servicio?',
        },
        {
          action: 'mdi-school',
          items: [{ title: 'Se genera un documento Word .docx y se descarga a tu computadora, puedes realizar cualquier cambio.' }],
          title: '¿Puedo hacer cambios en los contratos generados?',
        },
        {
          action: 'mdi-school',
          items: [{ title: 'Tenemos disponible un Word-Addin para que te facilite modificar tus plantillas. Al tenerlas listas, puedes subirlas en la vista de `Plantillas`.' }],
          title: '¿Cómo configuro las minutas de mi oficina o empresa para usarlas en Contractflows?',
        },
        {
          action: 'mdi-school',
          items: [{ title: 'Podemos realizar capacitaciones gratuitas presenciales o virtuales por medio de videollamada. Además contamos con material audiovisual para que aprendas por tu cuenta.' }],
          title: '¿Ofrecen algún tipo de capacitación?',
        },
        {
          action: 'mdi-school',
          items: [{ title: 'Contamos con diferentes categorías de planes. Escoge un tipo de plan, decide entre pago mensual o anual, selecciona el número de usuarios y listo.' }],
          title: '¿Cómo funcionan los planes?',
        },
      ],
    },

    // contact
    contactTitle: 'Contáctanos',
    name: 'Nombre',
    nameRules: [
      v => !!v || 'El nombre es requerido',
      v => (v && v.length <= 30) || 'El nombre debe tener menos de 30 characters'
    ],
    country: 'País',
    countryRules: [
      v => !!v || 'El país es requerido'
    ],
    email: 'Correo electrónico',
    emailRules: [
      v => !!v || 'El correo electrónico es requerido',
      v => /.+@.+\..+/.test(v) || 'El correo electrónico debe ser válido'
    ],
    subject: 'Asunto',
    subjectRules: [
      v => !!v || 'El asunto es requerido'
    ],
    message: 'Mensaje',
    messageRules: [
      v => !!v || 'El mensaje es requerido'
    ],
    agreement: 'Acepto política de privacidad',
    agreementRules: [
      v => !!v || 'Debe aceptar para continuar!'
    ],
    submit: 'Enviar',
    whatsText1: 'Puedes escribirnos a',
    whatsText2: 'nuestro WhatsApp',

    // pricing
    pricingM: {
      personalizedTemplatesText: 'En todos los planes puedes usar tus ',
      personalizedTemplates: 'plantillas personalizadas',
      users: 'Usuario | Usuarios',
      choose: 'Obtén el plan ideal',
      noSize: 'Tenemos lo que necesitas.',
      monthly: 'Mensual',
      annual: 'Anual',
      priceUser: '/ usuario al mes',
      discount: 'Ahorra 20% con pagos anuales',
      bulkDiscount: 'Ahorra 10% adicional al adquirir más de 5 usuarios',
      $: '$',
      priceUserYear: ' / usuario al año',
      priceTotalMonth: 'total al mes',
      priceTotalYear: 'total al año',
      minimum: 'Incluye {n} usuarios',
      additionalUsers: '{price} extra por usuario adicional',
      functions: 'Funcionalidades',
      comparison: 'Comparación de Planes',
      coming: 'Próximamente',
      functionalities: [
        'Acceso a las plantillas de Contractflows',
        'Procesos ilimitados',
        'Plantillas personalizadas ilimitadas',
        'Soporte estándar',
        'Soporte dedicado',
        'Proceso de Revisión',
        'Word Add-in',
        'Dashboard',
        'Sistema de Alertas',
        'Generación de contratos en grupo',
        'Compartir Formularios a terceros',
        'Links de Formularios Públicos',
        'Documentos Adjuntos',
        'Firma Electrónica',
        'Consulta RGP-GT',
        'Planificador',
        'Flujos de trabajo personalizados',
        'Automatización de plantillas con IA'
      ],
      functionalitiesDescribed: {
        'Acceso a las plantillas de Contractflows': 'Utiliza nuestras plantillas listas para su uso.',
        'Procesos ilimitados': 'Crea los procesos que necesites para generar contratos.',
        'Plantillas personalizadas ilimitadas': 'Sube las plantillas que quieras, exclusivas solo para tu uso.',
        'Soporte estándar': 'Soporte por chat y correo.',
        'Soporte dedicado': 'Canal de soporte directo.',
        'Proceso de Revisión': 'Reduce errores al mandar a revisar tus procesos antes de generar los contratos.',
        // eslint-disable-next-line
        'Dashboard': 'Visualiza en qué está trabajando tu equipo y planea para el futuro.',
        'Sistema de Alertas': 'Crea alertas y recordatorios de plazos a vencer para dar un mejor servicio a tu cliente.',
        'Generación de contratos en grupo': 'Genera un grupo de documentos a partir de un archivo Excel.',
        'Compartir Formularios a terceros': 'Adquiere información directamente desde tus clientes o terceros de una forma fácil y segura.',
        'Links de Formularios Públicos': 'Comparte formularios con un link y que tus clientes inicien procesos.',
        'Documentos Adjuntos': 'Agrega documentos adjuntos a tus procesos como evidencia.',
        'Word Add-in': 'Crea plantillas personalizadas con nuestro Add-in, disponible en la tienda Office.',
        'Firma Electrónica': 'Completa tu proceso legal con integración de firma electrónica. Redacta y firma en un mismo lugar.',
        'Consulta RGP-GT': 'Automatiza la consulta al Registro General de la Propiedad de Guatemala, recibe los documentos en tu correo',
        'Planificador': 'Genera azimuts para multiples planos a partir de un archivo de Excel',
        'Flujos de trabajo personalizados': 'Aplica pasos y requerimientos para aprobar un proceso y luego generarlo.',
        'Automatización de plantillas con IA': 'Hace que las plantillas personalizadas sean más fáciles al escanear tus documentos con reconocimiento de entidades usando algoritmos de IA.'
      },
      plans: [
        {
          id: 'basic',
          title: 'Básico',
          price: ['150', '1440'],
          priceUserYear: ['360', '288'],
          priceUser: ['30', '24'],
          minUsers: '5',
          details: {
            'Acceso a las plantillas de Contractflows': true,
            'Procesos ilimitados': true,
            'Plantillas personalizadas ilimitadas': true,
            'Soporte estándar': true,
            'Word Add-in': true,
            'Firma Electrónica': true,
            'Consulta RGP-GT': false,
            'Planificador': false,
            'Proceso de Revisión': false,
            // eslint-disable-next-line
            'Dashboard': false,
            'Sistema de Alertas': false,
            'Generación de contratos en grupo': false,
            'Compartir Formularios a terceros': false,
            'Links de Formularios Públicos': false,
            'Documentos Adjuntos': false,
          }
        },
        {
          id: 'standard',
          title: 'Estándar',
          price: ['250', '4320'],
          priceUserYear: ['480', '384'],
          priceUser: ['40', '32'],
          minUsers: '10',
          details: {
            'Acceso a las plantillas de Contractflows': true,
            'Procesos ilimitados': true,
            'Plantillas personalizadas ilimitadas': true,
            'Soporte estándar': true,
            'Word Add-in': true,
            'Firma Electrónica': true,
            'Consulta RGP-GT': true,
            'Planificador': true,
            'Proceso de Revisión': true,
            // eslint-disable-next-line
            'Dashboard': true,
            'Sistema de Alertas': true,
            'Generación de contratos en grupo': false,
            'Compartir Formularios a terceros': false,
            'Links de Formularios Públicos': false,
            'Documentos Adjuntos': false,
            // eslint-disable-next-line
            'Flujos de trabajo personalizados': 'inProgress',
            'Automatización de plantillas con IA': 'inProgress'
          }
        },
        {
          id: 'advanced',
          title: 'Avanzado',
          price: ['450', '4430'],
          priceUserYear: ['600', '480'],
          priceUser: ['50', '40'],
          minUsers: '20',
          details: {
            'Acceso a las plantillas de Contractflows': true,
            'Procesos ilimitados': true,
            'Plantillas personalizadas ilimitadas': true,
            'Soporte dedicado': true,
            'Word Add-in': true,
            'Firma Electrónica': true,
            'Consulta RGP-GT': true,
            'Planificador': true,
            'Proceso de Revisión': true,
            // eslint-disable-next-line
            'Dashboard': true,
            'Sistema de Alertas': true,
            'Generación de contratos en grupo': true,
            'Compartir Formularios a terceros': true,
            'Links de Formularios Públicos': true,
            'Documentos Adjuntos': true,
            // eslint-disable-next-line
            'Flujos de trabajo personalizados': 'inProgress',
            'Automatización de plantillas con IA': 'inProgress'
          }
        }
      ],
      // template customization
      templateCustomization: 'Perzonalización de Plantillas',
      customizationSlogan: 'Nosotros creamos tus plantillas',
      customizationSlogan2: 'No te preocupes.',
      customizationDes: 'Contractflows ofrece servicio de personalización de plantillas. Asumimos responsabilidad para cumplir con tus requerimientos y garantizamos calidad para tus plantillas. La ventaja es que una vez creada, la puedes usar cuantas veces quieras.',
      customizationPrice: '35',
      customizationPriceDes: 'por plantilla',
      customizationSteps: ['Identificación de documento base', 'Definición de casos de uso', 'Definición de campos', 'Dos revisiones', 'Entrega de plantilla'],

      // single User
      singleTitle: '¿Necesitas ún solo usuario?',
      singlePrice: '45',
      perMonth: 'al mes',
      singleFeatures: {
        'Acceso a las plantillas de Contractflows': true,
        'Procesos ilimitados': true,
        'Plantillas personalizadas ilimitadas': true,
        'Soporte estándar': true,
        // eslint-disable-next-line
        'Word Add-in': true,
      },
      signUp: 'Regístrate'
    },
    pricingSlogan: 'Es simple, solo selecciona cuantos usuarios necesitas',
    users: 'Usuarios',
    itemsInclude: ['Plantillas ilimitadas', 'Generación de documentos ilimitados', 'Procesos ilimitados',
      'Acceso a plantillas predefinidas', 'Servicio al cliente estándar',
      'Renovación de membresía mensual', 'Sin cargos de instalación', 'Listo para empezar'],
    callToAction: 'Subscríbete',
    moreUsers: '¿Necesitas más usuarios?',
    contactAction: 'Contacta al equipo de ventas y encontraremos una buena solución',
    contactButton: 'Contactar Ventas',
    months: '/ mes',
    // footer
    terms: 'Términos y Condiciones',
    privacy: 'Política de Privacidad',
    // support
    support: {
      title: 'Soporte',
      message: 'Te dejamos el link a nuestra documentación contractflows.com/docs. Si el problema persiste, envíanos un correa a:',
      email: 'support@contractflows.com',
      message2: '¡Estaremos contentos de responder tus preguntas y encontraremos una solución juntos!'
    }
  }
}
// eslint-disable-next-line
const i18n = new VueI18n({
  locale: 'es', // set locale
  fallbackLocale: 'es', // set fallback locale
  messages // set locale messages
})

export default i18n
