<template>
  <div id="campos">
    <v-container>
      <v-row id="target2">
        <v-col cols="10" md="9" lg="10">
          <div class="text-body-1 text-justify">
            <div class="text-center text-h4">Tipos de campos:</div>
            <br />
            <div class="text-h7 text-center">
                En un archivo .docx, crea tu plantilla utilizando los siguientes tipos de campos:
            </div>
            <br />
            <div id="campoSimple" v-intersect="{
              handler: onIntersect,
              options: intersectOptions
            }"><br>
              <div class="text-h5 mt-0 text-center" >Campo simple</div>
              <br />
              <div>
                Un campo simple permite al usuario ingresar cualquier combinación
                de letras, números y caracteres especiales.
              </div>
              <br />
              <div class="text-justify">
                Para agregar un campo simple en tu plantilla, escribe el nombre
                del campo entre llaves “{}” en la posición que quieras. Puedes nombrar
                el campo de forma libre, utilizando letras de la A a la
                Z, números y guiones bajos "_". El nombre del campo no puede
                tener acentos, espacios en blanco o caracteres especiales.
              </div>
              <br />
              <div><span class="font-weight-bold">Fórmula: </span>{nombreCampo}</div>
              <br />
              <br />
              <div class="text-decoration-underline">
                Ejemplo 1: Campo simple para ingreso de texto:
              </div>
              <br>
              <div>
                Texto: “________ le vende 10 computadoras por $5,000.00.”
              </div>
              <div>Ejemplo: {vendedor}</div>
              <div>
                Texto con el campo simple: “{vendedor} le vende 10 computadoras por $5,000.00."
              </div>
              <div>Input: “Juan Fernández”</div>
              <div>
                Resultado: “Juan Fernández le vende 10 computadoras por $5,000.00."
              </div>
              <br />
              <br />
              <div class="text-decoration-underline">
                Ejemplo 2: campo simple para números:
              </div>
              <br />
              <div>
                Texto: “Juan Fernández le vende ____ computadoras por $5,000.00.”
              </div>
              <div>Campo simple: {cantidad}</div>
              <div>
                Texto con el campo simple: “Juan Fernández le vende {cantidad} computadoras por $5,000.00.
              </div>
              <div>Input: “10”</div>
              <div>
                Resultado: “Juan Fernández le vende 10 computadoras por $5,000.00."
              </div>
              <br />
              <br />
              <div class="text-decoration-underline">
                Ejemplo 3: Múltiples campos simples:
              </div>
              <br />
              <div>
                Texo: “_______ le vende __ computadoras por ________."
              </div>
              <div>Campos simples: {vendedor}, {cantidad}, {precio}.</div>
              <div>
                Texto con campos simples: “{vendedor} le vende {cantidad} computadoras por {precio}."
              </div>
              <div>Input: “Juan Fernández”, “10”, “$5,000.00”</div>
              <div>
                Resultado: “Juan Fernández le vende 10 computadoras por $5,000.00."
              </div>
            </div>
            <br />
            <v-divider class="my-5"></v-divider>
            <div id="campoDeFecha" v-intersect="{
              handler: onIntersect,
              options: intersectOptions
            }"> <br>
              <div class="text-h5 mt-0 text-center">Campo de fecha</div>
              <br />
              <div class="text-justify">
                Un campo de fecha le permite al usuario ingresar una fecha en el formulario
                al seleccionarla de un calendario. Para agregar un campo de fecha en la plantilla,
                escribe el nombre del campo, con el texto "_date", entre llaves “{}”.
              </div>
              <br />
              <div>
                <span class="font-weight-bold">Fórmula: </span> {nombreDelCampo_date}
              </div>
              <br />
              <br />
              <div class="text-decoration-underline">Ejemplo:</div>
              <br />
              <div>Texto: “El contrato de arrendamiento iniciará el _________”</div>
              <div>Campo de fecha: {inicioContrato_date}</div>
              <div>
                Texto con el campo de fecha: “El contrato de arrendamiento iniciará el {inicioContrato_date}"
              </div>
              <div>Input: 25/10/2020</div>
              <div>
                Resultado: “El contrato de arrendamiento iniciará el veinticinco de octubre de dos mil veinte."
              </div>
              <br />
            </div>
            <br />
            <v-divider class="my-5"></v-divider>
            <div id="campoDeNumeros" v-intersect="{
              handler: onIntersect,
              options: intersectOptions
            }"><br>
              <div class="text-h5 mt-0 text-center">Campo de números</div>
              <br>
              <div>
                Los campos de números permiten al usuario escribir un número en digitos
                para que el resultado se genere en números y en letras.
              </div>
              <br />
              <div>Input: “200”.</div>
              <div>Resultado: “200” y “doscientos”.</div>
              <br />
              <div>El campo de números está compuesto de dos campos:</div>
              <div class="font-weight-bold">Primer campo:</div>
              <div>
                Escribe el nombre del campo entre llaves “{}”. El resultado de este
                campo es el texto ingresado por el usuario.
              </div>
              <br />
              <div>Ejemplo: {monto}.</div>
              <div>Input: “200”. Resultado: “200”.</div>
              <br />
              <div class="font-weight-bold">Segundo campo:</div>
              <div>
                Escribe el nombre del campo, más el texto "_letters" entre llaves “{}”. El resultado de este
                campo es el número escrito en letras.
              </div>
              <br />
              <div>Ejemplo: {monto_letters}.</div>
              <div>Input: “200”. Resultado: “doscientos”.</div>
              <br />
              <div>
                <span class="font-weight-bold">Fórmula: </span>{nombreDelCampo}
                {nombreDelCampo_letters}
              </div>
              <br />
              <div>Puedes escribir los campos en el orden que quieras.</div>
              <br />
              <br />
              <div class="text-decoration-underline">Ejemplo:</div>
              <div>
                <br />
                Texto: “Juan Fernández le vende _______ (____) computadoras por
                $5,000.00.”
              </div>
              <div>Campos de números: {cantidad} {cantidad_letters}</div>
              <div>
                Texto con los campos: “Juan Fernández le vende {cantidad_letters} ({cantidad}) computadoras por
                $5,000.00.”
              </div>
              <div>Input: 10</div>
              <div>
                Resultado: “Juan Fernández le vende diez (10) computadoras por
                $5,000.00.”
              </div>
              <br />
              </div>
            <br />
            <v-divider class="my-5"></v-divider>
            <div id="campoDeCodigos" v-intersect="{
              handler: onIntersect,
              options: intersectOptions
            }"><br>
              <div class="text-h5 mt-0 text-center">Campo de códigos</div>
              <br>
              <div>
                Los campos de códigos permiten al usuario escribir texto que contenga números, letras y símbolos (. - /)
                para que el resultado se genere de esa forma y en letras.
              </div>
              <br />
              <div>Input: “A-25/13”.</div>
              <div>Resultado: "A-25/13" y “A guion veinticinco diagonal trece”.</div>
              <br />
              <div>El campo de códigos está compuesto de dos campos:</div>
              <br>
              <div class="font-weight-bold">Primer campo:</div>
              <div>
                Escribe el nombre del campo entre llaves “{}”. El resultado de este
                campo es el texto ingresado por el usuario.
              </div>
              <br />
              <div>Ejemplo: {serie}.</div>
              <div>Input: “B-17”. Resultado: “B-17”.</div>
              <br />
              <div class="font-weight-bold">Segundo campo:</div>
              <div>
                Escribe el nombre del campo, más el texto "_code" entre llaves “{}”. El resultado de este
                campo es el texto escrito en letras.
              </div>
              <br />
              <div>Ejemplo: {serie_code}.</div>
              <div>Input: "B-17". Resultado: “B guion diecisiete”.</div>
              <br />
              <div>
                <span class="font-weight-bold">Fórmula: </span>{nombreDelCampo}
                {nombreDelCampo_code}
              </div>
              <br />
              <div>Puedes escribir los campos en el orden que quieras.</div>
              <br />
              <br />
              <div class="text-decoration-underline">Ejemplo:</div>
              <div>
                <br />
                Texto: “Resolución bancaria ______________ (_____) de fecha...”
              </div>
              <div>Campos de códigos: {resolucion} {resolucion_code}</div>
              <div>
                Texto con los campos: “Resolución bancaria {resolucion_code} ({resolucion}) de fecha...”
              </div>
              <div>Input: CH120/2020</div>
              <div>
                Resultado: “Resolución bancaria CH ciento veinte diagonal dos mil veinte (CH120/2020) de fecha...”
              </div>
              <br />
              </div>
            <br />
            <v-divider class="my-5"></v-divider>
            <div id="campoDeMonedas" v-intersect="{
              handler: onIntersect,
              options: intersectOptions
            }"><br>
              <div class="text-h5 mt-0 text-center">Campo de monedas</div>
              <br />
              <div>
                Los campos de monedas permiten al usuario escribir un valor de moneda en digitos
                para que el resultado se genere en números y en letras. A diferencia del campo
                de números, este campo toma en cuenta que los décimales son monedas.
              </div>
              <br />
              <div class="font-weight-bold">First field:</div>
              <div>
                Escribe el nombre del campo entre llaves “{}”. El resultado de este
                campo es el texto ingresado por el usuario.
              </div>
              <div>Ejemplo: {precio}.</div>
              <div>Input: “350.23”. Resultado: “350.23”.</div>
              <br />
              <div class="font-weight-bold">Segundo campo:</div>
              <div>
                Escribe el nombre del primer campo con el texto "_currencyUnits"
                entre llaves “{}”. El resultado del campo es el número entero
                (sin decimales) escrito en letras.
              </div>
              <div>Ejemplo: {precio_currencyUnits}</div>
              <div>Input: “350.23”. Resultado: “trescientos cincuenta”</div>
              <br />
              <div class="font-weight-bold">Tercer campo:</div>
              <div>
                Escribe el nombre del primer campo con el texto "_currencyDecimals"
                entre llaves “{}”. El resultado del campo es el monto de centavos
                en letras.
              </div>
              <div>Ejemplo: {precio_currencyDecimals}</div>
              <div>Input: “350.23”. Resultado: “con veintitrés centavos.”</div>
              <br />
              <div>
                <span class="font-weight-bold">Fórmula: </span>{nombreDelCampo}
                {nombreDelCampo_currencyUnits} {nombreDelCampo_currencyDecimals}
              </div>
              <br />
              <div>Puedes escribir los campos de monedas en el orden que quieras. Lo importante
                es que escribas los tres campos.
              </div>
              <br>
              <br />
              <div class="text-decoration-underline">Ejemplo:</div>
              <br />
              <div>
                Texto: “Juan Fernández le vende 10 computadoras por ________ ($_______).”
              </div>
              <div>
               Campos de monedas: {precio} {precio_currencyUnits}
                {precio_currencyDecimals}
              </div>
              <div>
                Texto con campos de monedas: “Juan Fernández le vende 10 computadoras por
                {precio_currencyUnits} dólares {precio_currencyDecimals} (${precio}).”
              </div>
              <div>Input: “5,000.25”</div>
              <div>
                Resultado: “Juan Fernández le vende 10 computadoras por cinco mil dólares
                con veinticinco centavos ($5,000.25).”
              </div>
              <br />
            </div>
            <br />
            <v-divider class="my-5"></v-divider>
            <div id="campoDropdown" v-intersect="{
              handler: onIntersect,
              options: intersectOptions
            }"><br>
              <div class="text-h5 mt-0 text-center">Campo dropdown</div>
              <br />
              <div>
                Un campo dropdown permite al usuario seleccionar el texto a ingresar,
                por medio de una lista de opciones. La lista de opciones se define en la
                configuración del formulario. Las opciones pueden contener cualquier
                combinación de texto, números y caracteres especiales.
              </div>
              <br />
              <div>
                Para agregar un campo dropdown en tu plantilla, escribe el nombre del campo
                más el texto “_drop” entre llaves "{}",
              </div>
              <br />
              <div>
                <span class="font-weight-bold">Fórmula: </span> {nombreDelCampo_drop}
              </div>
              <br />
              <br />
              <div class="text-decoration-underline">Ejemplo 1:</div>
              <br />
              <div>
                Texto: “Juan Fernández le vende 10 ___________ por $5,000.00."
              </div>
              <div>Campo dropdown: {producto_drop}</div>
              <div>
                Texto con campo dropdown: “Juan Fernández le vende 10 {producto_drop} por $5,000.00."
              </div>
              <div>
                Ejemplo de opciones del campo dropdown: "computadoras",
                "impresoras", "teléfonos".
              </div>
              <div>Input: *selecciona "teléfonos"</div>
              <div>
                Resultado: “Juan Fernández le vende 10 teléfonos por $5,000.00."
              </div>
              <br />
              <br />
              <div class="text-decoration-underline">Ejemplo 2:</div>
              <br />
              <div>
                Texto: “Juan Fernández le _____ 10 computadoras por $5,000.00."
              </div>
              <div>Campo dropdown: {transaccion_drop}</div>
              <div>
                Texto con campo dropdown: “Juan Fernández le
                {transaccion_drop} 10 computadoras por $5,000.00.”
              </div>
              <div>
                Ejemplo de opciones del campo dropdown: “compra”, “Vende”.
              </div>
              <div>Input: *selecciona la opción "compra"</div>
              <div>
                Resultado: “Juan Fernández le compra 10 computadoras por $5,000.00.”
              </div>
            </div>
            <br />
            <v-divider class="my-5"></v-divider>
            <div id="campoDeOpcion" v-intersect="{
              handler: onIntersect,
              options: intersectOptions
            }"> <br>
              <div class="text-h5 mt-0 text-center">Campo de opción</div>
              <br />
              <div>
                Un campo de opción permite al usuario seleccionar un checkbox para incluir
                en la plantilla texto o campos opcionales. El contenido del campo de opción
                se configura en la plantilla. Estos campos están compuestos de tres elementos:
              </div>
              <br />
              <div> Campo inicial: {#nombreDelCampo}</div>
              <div>
                Contenido opcional: Puede incluir texto y otros tipos de campos.
              </div>
              <div>Campo final: [/nombreDelCampo}</div>
              <div>
                <span class="font-weight-bold">Fórmula: </span> {#nombreDelCampo} textos o campos opcionales {/nombreDelCampo}
              </div>
              <br />
              <br />
              <div class="text-decoration-underline">Ejemplo:</div>
              <br />
              <div>
                Texto: “Juan Fernández le vende 10 computadoras por $5,000.00."
              </div>
              <div>
                Contenido opcional: “Se incluirán teclados gratis por la compra de las computadoras.”
              </div>
              <div>Campos de opción {#tecladosGratis} {/tecladosGratis}</div>
              <div>
                Texto con campos de opción: “Juan Fernández le vende 10 computadoras por $5,000.00.
                {#tecladosGratis}Se incluirán teclados gratis por la compra de las computadoras.{/tecladosGratis}
              </div>
              <br />
              <div>Input: *la opción es seleccionada</div>
              <div>
                Resultado: "Juan Fernández le vende 10 computadoras por $5,000.00.
                 Se incluirán teclados gratis por la compra de las computadoras."
              </div>
              <br />
              <div>Input: *la opción no es seleccionada</div>
              <div>
                Resultado: “"Juan Fernández le vende 10 computadoras por $5,000.00.”
              </div>
              <br />
              <div>
                Tip: Puedes utilizar campos de opción con el mismo nombre en diferentes partes de la plantilla,
                incluyendo diferente contenido opcional.
              </div>
            </div>
          </div>
        </v-col>
        <v-col class="d-none d-md-flex" lg="2" md="3">
          <subNav :items="items" :isIntersecting="isIntersecting"></subNav>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import SubNav from '@/components/docs/SubNav'

export default {
  name: 'Campos',
  components: {
    SubNav
  },
  data: () => ({
    isIntersecting: false,
    items: [
      { text: 'Campo simple', id: 'campoSimple' },
      { text: 'Campo de fecha', id: 'campoDeFecha' },
      { text: 'Campo de números', id: 'campoDeNumeros' },
      { text: 'Campo de código', id: 'campoDeCodigos' },
      { text: 'Campo de monedas', id: 'campoDeMonedas' },
      { text: 'Campo desplegables', id: 'campoDropdown' },
      { text: 'Campo de opción', id: 'campoDeOpcion' }
    ],
    intersectOptions: {
      trackVisibility: true,
      delay: 200,
      rootMargin: '0px',
      threshold: [0.60, 1.0]
    }
  }),
  methods: {
    onIntersect (entries, observer, isIntersecting) {
    //   // https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
    //   if ((entries[0].isVisible && entries[0].intersectionRatio > 0.50) || (isIntersecting && entries[0].intersectionRatio > 0.50)) {
    //     // console.log(entries[0].target.id)
    //     // console.log(entries[0])
    //     this.isIntersecting = entries[0].target.id
    //   }
    //   // this.isIntersecting = isIntersecting // entries[0].intersectionRatio >= 0.5
    }
  },
  mounted () {
    // const recaptcha = this.$recaptchaInstance
    // recaptcha.hideBadge()
  },
  watch: {
  }
}
</script>

<style></style>
