<template>
  <div id="workflow">
    <v-container>
      <v-row>
        <v-col>
          <div class="text-body-1 ">
             <div class="text-center text-h4">Flujo de trabajo:</div>
             <br>
             <br>
             <div>
               Para crear y completar un proceso debes seguir las siguientes etapas:
             </div>
             <br>
             <ol>
               <li>Iniciar un proceso</li>
               <li>Revisar un proceso</li>
               <li>Generar un documento</li>
             </ol>
             <br>
              <div id="startingNewProcess" v-intersect="{
              handler: onIntersect,
              options: intersectOptions
            }"><br>
              <div class="text-h5 mt-0 text-center">Crear un proceso</div>
              <br />
              <div>Sigue los siguientes pasos para crear un proceso:</div>
              <br />
              <ol>
                <li>
                  En el menú principal, haz click en el botón de Procesos.
                </li>
                <li>Selecciona un fomulario. Puedes acceder a los formularios utilizando la vista por tabla o la vista por directorio.
                  Puedes seleccionarlo de los formularios creados por tu organización o de los proporcionados por Contractflows.
                </li>
                <li>En el campo "Título del nuevo proceso" escribe el nombre de tu documento.</li>
                <li>Haz click en el botón de "Guardar borrador", localizado en la parte inferior de la página.</li>
                <li>Completa todos los cambos del formulario. Es necesario llenar todos los cambios para poder mandar el proceso a revisión.</li>
                <li>Haz click en el botón de "Mandar a revisión" para continuar con el proceso.</li>
              </ol>
              <br />
              <div>
                Si decides continuar llenando la información en otra ocasión, haz click en el botón "Guardar borrador" antes de salir de la página.
                El proceso se guardará en la sección "Tus Borradores".
              </div>
            </div>
            <br />
            <v-divider class="my-5"></v-divider>
            <div id="reviewingAProcess" v-intersect="{
              handler: onIntersect,
              options: intersectOptions
            }"><br>
              <div class="text-h5 mt-0 text-center">Revisar un proceso</div>
              <br />
              <div>
                Sigue los siguientes pasos para revisar un proceso:
              </div>
              <br />
              <ol>
                <li>
                  En el menú principal, haz click en Procesos para Revisión.
                </li>
                <li>Selecciona el proceso a revisar.</li>
                <li>Revisa el contenido de los cambios. Puedes modificar el contenido antes de aprobarlo o de rechazarlo.</li>
                <li>Si quieres rechazar el proceso para que sea envíado al creador del proceso para que lo corrija, haz click en el botón "Rechazar".</li>
                <li>Para aprobar un proceso y enviarlo a la etapa de generar docuemnto, haz click en el botón de "Aprobar".</li>
              </ol>
              <br />
           <v-divider class="my-5"></v-divider>
           <div id="generateDocument" v-intersect="{
              handler: onIntersect,
              options: intersectOptions
            }"><br>
              <div class="text-h5 mt-0 text-center">Generar un documento</div>
              <br />
              <div>
                Sigue los siguentes pasos para generar un documento:
              </div>
              <br />
              <ol>
                <li>
                  En el menú principal, selecciona Procesos.
                </li>
                <li>
                  En la parte superior, haz click en el botón "Genera un documento".
                </li>
                <li>
                  Selecciona el documento que quieres generar.
                </li>
                <li>
                 Haz click en el botón de "Generar documento".
                </li>
              </ol>
              <br />
              <div>
                El documento se descargará de forma automática en tu computadora. Los documentos generados se almacenarán
                en la carpeta que tu computadora tenga configurada para almacenar descargas.
              </div>
              <br>
              <div>
                Puedes generar los documentos cuantas veces quieras.
              </div>
              <br>
            </div>
            </div>
          </div>
          <br>
          <v-divider class="my-5"></v-divider>
          <div id="copyContent" v-intersect="{
              handler: onIntersect,
              options: intersectOptions
            }">
            <div class="text-h5 mt-0 text-center">Copia el contenido de un documento aprobado</div>
              <br />
              <div>
                Puedes completar la información de un borador con la información contenida en un documento aprobado. Esto
                aplica para los campos que tengan en común el fomulario del borrador y el formulario del documento aprobado.
                Los campos en común son aquellos que tienen exactamente el mismo nombre. Los únicos campos cuyo contenido no se
                copia son los campos de fecha, los cuales deben ser ingresados nuevamente.
              </div>
              <br>
              <div>
                Sigue estos pasos para copiar el contenido de un documento aprobado.
              </div>
              <br />
              <ol>
                <li>
                  En el menú principal, haz click en el botón de Procesos.
                </li>
                <li>
                  Si ya tienes creado un borrador, sigue con el siguiente paso. Crea un proceso y guardalo como borrador.
                </li>
                <li>
                  En la parte superior, haz click en el botón "Genera un documento".
                </li>
                <li>
                  Selecciona el documento aprobado del cual quieres copiar el contenido y haz click en el icono copiar.
                </li>
                <li>
                 De la lista de borradores que aparecerá, selecciona el borrador al cual quieres copiar el contenido.
                </li>
                <li>
                 Haz click en el botón "Copiar".
                </li>
              </ol>
              <br />
              <div>
                Una vez realizada la copia, puedes continuar con el proceso y enviarlo a revisión.
              </div>
              <br>
          </div>
          <v-divider class="my-5"></v-divider>
            <div id="batchGenerate" v-intersect="{
              handler: onIntersect,
              options: intersectOptions
            }">
            <div class="text-h5 mt-0 text-center">Batch Documents Generation</div>
              <br />
              <div>
                Es posible generar varios documentos utilizando la información contenida en un documento de Excel.
              </div>
              <br>
              <div>
                Para generar varios documentos, sigue los siguientes pasos:
              </div>
              <br />
              <ol>
                <li>
                  En el menú principal, haz click en Procesos.
                </li>
                <li>
                 Selecciona un formulario.
                </li>
                <li>
                Haz click en el botón "Batch Generate".
                </li>
                <li>
                Haz click en el botón "Click here for template". Se descargará un n documento de Exceldocumento de Excel en tu dispositivo.
                </li>
                <li>
                Abre el documento de Excel que se descargó y completa la información. Las columnas tienen en los encabezados el nombre de los campos. Completa la información en el documento de acuerdo
                al tipo de campo. (Para los Campos de Números, utiliza únicamente números. Para los Campos de Fecha, escribe la fecha en formato YYYY-MM-DD. Para los Campos Opcionales, escribe "True", si quieres
                que la opción sea seleccionada. Para los Campos Desplegables, escribe el texto que quieres que se consigne en el documento.
                Después de llenar la información, guarda el archivo y cierralo.
                </li>
                <li>
                En Contractflows, haz click en "seleccionar un documento", se abrirá una ventana del explorador. Selecciona el archivo Excel y haz click en el botón de "abrir".
                </li>
                <li>
                Haz click en el botón "Batch Generate". Se descargará a tu dispositivo un archivo zip que contiene los documentos generados.
                </li>
              </ol>
              <br />
              <br>
          </div>
        </v-col>
        <v-col class="d-none d-md-flex" lg="2" md="3">
          <subNav :items="items" :isIntersecting="isIntersecting"></subNav>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import SubNav from '@/components/docs/SubNav'

export default {
  name: 'flow',
  components: {
    SubNav
  },
  data: () => ({
    isIntersecting: false,
    items: [
      { text: 'Crear un proceso', id: 'startingNewProcess' },
      { text: 'Revisar un proceso', id: 'reviewingAProcess' },
      { text: 'Generar un documento', id: 'generateDocument' },
      { text: 'Copia el contenido de un documento aprobado', id: 'copyContent' },
      { text: 'Generar en masa', id: 'batchGenerate' }
    ],
    intersectOptions: {
      trackVisibility: true,
      delay: 100,
      rootMargin: '0px',
      threshold: [0.01, 0.02, 0.03, 0.04, 0.05, 0.06, 0.07, 0.08, 0.09, 0.1, 0.11, 0.12, 0.13, 0.14, 0.15, 0.16, 0.17, 0.18, 0.19, 0.2, 0.21, 0.22, 0.23, 0.24, 0.25, 0.26, 0.27, 0.28, 0.29, 0.3, 0.31, 0.32, 0.33, 0.34, 0.35, 0.36, 0.37, 0.38, 0.39, 0.4, 0.41, 0.42, 0.43, 0.44, 0.45, 0.46, 0.47, 0.48, 0.49, 0.5, 0.51, 0.52, 0.53, 0.54, 0.55, 0.56, 0.57, 0.58, 0.59, 0.6, 0.61, 0.62, 0.63, 0.64, 0.65, 0.66, 0.67, 0.68, 0.69, 0.7, 0.71, 0.72, 0.73, 0.74, 0.75, 0.76, 0.77, 0.78, 0.79, 0.8, 0.81, 0.82, 0.83, 0.84, 0.85, 0.86, 0.87, 0.88, 0.89, 0.9, 0.91, 0.92, 0.93, 0.94, 0.95, 0.96, 0.97, 0.98, 0.99, 1, 0]
    }
  }),
  methods: {
    onIntersect (entries, observer, isIntersecting) {
      // https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
      if (entries[0].intersectionRect.top < 100 && (isIntersecting && entries[0].intersectionRatio > 0.50)) {
        // console.log(entries[0].target.id)
        // console.log(entries[0])
        this.isIntersecting = entries[0].target.id
      }
      // this.isIntersecting = isIntersecting // entries[0].intersectionRatio >= 0.5
    }
  },
  mounted () {
    // const recaptcha = this.$recaptchaInstance
    // recaptcha.hideBadge()
  }
}
</script>

<style></style>
