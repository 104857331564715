<template>
  <div id="support">
    <v-container class="text-center" fill-height>
      <v-row>
        <v-col class="text-center">
          <v-sheet height="100%">
            <h2 class="text-h3 py-5">{{ $t('support.title') }}</h2>
            <p class="text-h5">{{ $t('support.message') }}</p>
            <p class="text-h5"><a :href="'mailto:'+$t('support.email')">{{ $t('support.email') }}</a></p>
            <p class="text-h5 pb-5">{{ $t('support.message2') }}</p>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'support',
  data: () => ({

  }),
  methods: {

  }
}
</script>

<style>

</style>
