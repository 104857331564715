import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import centralized from '@/assets/icons/centralized.vue'
import easy from '@/assets/icons/easy.vue'
import eficient from '@/assets/icons/eficient.vue'
import noError from '@/assets/icons/noError.vue'
import secure from '@/assets/icons/secure.vue'
import sign from '@/assets/icons/sign.vue'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#123a5f',
        background: '#F2F2F2',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
      },
      dark: {

      }
    }
  },
  icons: {
    values: {
      centralized: {
        component: centralized
      },
      easy: {
        component: easy
      },
      eficient: {
        component: eficient
      },
      noError: {
        component: noError
      },
      secure: {
        component: secure
      },
      sign: {
        component: sign
      }
    }
  }
})
