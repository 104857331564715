<template>
  <div class="privacy">
    <v-row justify="center">
      <v-col xs="11" sm="10" md="6" lg="5" xl="5">
        <center><h3>Privacy Policy</h3></center>
        <p></p>
        <p>This Privacy Policy describes the way that we obtain and use your personal information in our online platform. </p>
        <p></p>
        <h4>Data Controller: </h4>
        <p></p>
        <p>Crazy Things, S.A. <br>
        Registration number: 105334006<br>
        Address:  20 Street, 5-65, zone 10, 5fth floor, Guatemala, Guatemala. <br>
        Contact: contact@contractflows.com</p>
        <p></p>
        <h4>Contact: </h4>
        <p>You can contact us in case you have any doubt about the use, processing or handling of your information, and in case you want to exercise any right regarding your personal information. Please send an email to: contact@contractflows.com</p>
        <p></p>
        <h4>What personal information do we collect? </h4>
        <p>We collect the necessary personal information to offer you our products and services through our platforms. We collect the following information: </p>
        <ul>
        <li><b>Account information: </b> We may collect your personal information such as name, age, address, email address, phone number, and payment information as part of the process of creating an account. </li>
        <li><b>Website use information: </b>We collect your personal information regarding the type of device being used, operating system, internet connection, your IP address, language configuration, location of your device, among others.  We also collect information from your interaction with our platform such as subscription history</li>
        <li><b>Economic and financial metadata. </b> </li>
        </ul>
        <p></p>
        <p>You are responsible that the information provided to us is true, accurate, and is up to date. You are responsible for any damage or loss caused as a result of the inaccuracy of the information provided. </p>
        <p></p>
        <h4>What is the purpose of data collection and processing? </h4>
        <p>We collect and process your personal information to perform our services, for the following purposes: </p>
        <ul>
        <li><b>Rendering our services: </b>We use your personal information to accept, and process orders, process payments, and to provide you with our services. </li>
        <li><b>Communicate with you: </b>We use your personal information to communicate with you regarding your account, subscriptions, and any service offered by us. </li>
        <li><b>Publicity: </b>We may use information about your behavior and use of our website to recommend products we consider may interest you. </li>
        <li><b>Anonymous reports: </b> We use your information to generate anonymized reports on user habits and purchases. </li>
        <li><b>Legal compliance: </b>Personal information may be collected and processed in order to comply with legal requirements.  </li>
        </ul>
        <p></p>
        <p></p>
        <h4>Security of your information: </h4>
        <p>We take the following measures to protect your information: </p>
        <ul>
        <li>All communications between the web browser and the servers are encrypted through Secure Sockets Layer Software. </li>
        <li>Credit card payments comply with the Payment Card Industry Data Security Standards (PCI DSS) to protect your credit card information and prevent frauds. </li>
        <li>The information in our servers is encrypted and physically secured. </li>
        </ul>
        <p></p>
        <h4>For how long do we store your personal data? </h4>
        <p>We will store your personal information during the required time to carry out the purposes for which they were collected according to this Privacy Policy.  </p>
        <p></p>
        <h4>Data transfers: </h4>
        <p>We may transfer your personal information to other countries, complying with the adequate protection mechanisms. <br>
        If necessary, we will transfer personal information to third parties, such as payment services providers, government institutions. </p>
        <p></p>
        <h4>Your rights: </h4>
        <p>According to the applicable data protection regulations you have the following rights regarding your personal information: </p>
        <ul>
        <li>To not provide certain personal information. Choosing not to provide personal information may limit your use of the website. </li>
        <li>Access your personal information. </li>
        <li>Add, certify or update your personal information. </li>
        <li>Request information regarding the use and processing of your information. </li>
        <li>Request the deletion of your account, and personal information. </li>
        <li>Request the limitation of processing of your personal information. </li>
        <li>Request the portability of personal information. </li>
        <li>Revoke consents regarding the collection or processing of your information. </li>
        <li>Request to limit communications from us. </li>
        </ul>
        <p></p>
        <p>The exercise of your rights is free of cost. <br>
        You are free to file any claim with your local authorities in case you consider your rights have been violated by our collection, processing, and storage of your personal information. Before doing so, we encourage you to communicate with us. </p>
        <p></p>
        <h4>Cookies: </h4>
        <p>When using our platform, we and certain third parties may use cookies to provide you with a better and safer user experience. You can disable cookies from your web browser. </p>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: 'Terms',
  components: {
  },
  data: () => ({
  }),
  mounted () {
    const recaptcha = this.$recaptchaInstance
    recaptcha.hideBadge()
  }
}
</script>
