import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import i18n from '@/plugins/i18n'
import VueSignaturePad from 'vue-signature-pad'
import VueGlide from 'vue-glide-js'
import 'vue-glide-js/dist/vue-glide.css'
import './registerServiceWorker'

Vue.use(VueSignaturePad)
Vue.use(VueGlide)
Vue.use(VueReCaptcha, { siteKey: '6LfypKkZAAAAADNABpW27E0gYmf6Be6j3UPyKC3b',
        renderParameters: {
          badge:'inline'
        } })
Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
