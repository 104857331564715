<template>
  <div id="pricing">
    <v-container>
      <v-row class="text-center pt-5">
        <v-col class="pb-0">
          <div class="text-h3">{{ $t('pricingM.choose') }}</div>
        </v-col>
      </v-row>
      <v-row class="text-center">
        <v-col class="pt-0">
          <div class="text-h6">{{ $t('pricingM.personalizedTemplatesText') }}<span @click="$vuetify.goTo('#custom')" class="primary--text text-decoration-underline" style="cursor: pointer;">{{ $t('pricingM.personalizedTemplates') }}</span></div>
        </v-col>
      </v-row>
      <v-row class="text-center" justify="center" align="center">
        <v-col cols="auto" class="text-right py-0">
          <div :class="annual ? 'text-h5 font-weight-medium' : 'blue--text text-h5 font-weight-medium'">{{ $t('pricingM.monthly') }}</div>
        </v-col>
        <v-col cols="auto" class="text-center py-0">
          <v-switch class="pl-3" id="bigSwitch" color="primary" v-model="annual" inset></v-switch>
        </v-col>
        <v-col cols="auto" class="text-left py-0">
          <div :class="annual ? 'green--text  text-h5 font-weight-medium' : 'text-h5 font-weight-medium'">{{ $t('pricingM.annual') }}</div>
        </v-col>
      </v-row>
      <v-row class="text-center" justify="center">
        <v-col class="pt-3 pb-4" cols="12">
          <v-chip style="border-width:3px" large color="primary" outlined><span class="font-weight-medium text-subtitle-1">{{ $t('pricingM.bulkDiscount') }}</span></v-chip>
        </v-col>
        <v-col cols="10" sm ="9" md="8" lg="7" xl="5">
          <div class="text-h3 text-center" :class="{'primary--text': nUsers>5}">{{nUsers}}</div>
          <div class="text-body-1 text--secondary">{{ $tc('pricingM.users', nUsers) }}</div>
          <v-slider v-model="nUsers" track-color="grey" ticks="always" tick-size="4" always-dirty step="1" min="1" :max="ticks">
            <template v-slot:prepend>
              <v-icon color="primary" @click="decrement">mdi-minus</v-icon>
            </template>
            <template v-slot:append>
              <v-icon color="primary" @click="increment">mdi-plus</v-icon>
            </template>
          </v-slider>
        </v-col>
      </v-row>
      <!-- plan cards -->
      <v-row align="stretch" justify="center" class="text-center">
        <v-col v-for="(item , idx) in plans" :key="idx" cols="12" :md="12/plans.length" :xl="9/plans.length">
          <planCard :plan="item" :annual="annual" :nUsers="nUsers" :planIdx="idx"></planCard>
        </v-col>
      </v-row>
      <!-- comparison table -->
      <v-row class="text-center pt-6">
        <v-col>
          <div class="text-h3">{{ $t('pricingM.comparison') }}</div>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" md="12" xl="9">
          <comparison-table :plans="plans"></comparison-table>
        </v-col>
      </v-row>
      <!-- template customization -->
      <v-row id="custom" class="pt-6" justify="center" align="stretch">
        <!-- <v-col cols="12" md="6" xl="4">
          <single-user></single-user>
        </v-col> -->
        <v-col cols="12" sm="9" md="8" xl="6">
          <custom-templates></custom-templates>
        </v-col>
      </v-row>
      
      <!-- <original></original> -->
    </v-container>
  </div>
</template>

<script>
// import original from '@/components/pricing/original'
import planCard from '@/components/pricing/planCard'
import comparisonTable from '../components/pricing/comparisonTable.vue'
import CustomTemplates from '../components/pricing/customTemplates.vue'
// import SingleUser from '../components/pricing/singleUser.vue'
export default {
  name: 'Pricing',
  components: {
    planCard,
    comparisonTable,
    CustomTemplates
    // SingleUser
    // original
  },
  data: () => ({
    ticks: 25,
    nUsers: 5,
    active: false,
    annual: true
  }),
  computed: {
    plans: function () {
      return this.$t('pricingM.plans')
    },
  },
  methods: {
    decrement () {
      this.nUsers--
    },
    increment () {
      this.nUsers++
    },
    setSnackbar (text, color, timeout = 5000) {
      this.snackbarText = text
      this.snackbarColor = color
      this.snackbarTimeout = timeout
      this.snackbar = true
    },
  },
  mounted () {
    this.$vuetify.goTo('#pricing')
  }
}
</script>

<style>
.v-card__text, .v-card__title {
  word-break: normal; /* maybe !important  */
}
.card-outter {
  position: relative;
  padding-bottom: 50px;
}
.card-actions {
  position: absolute;
  bottom: 0;
  width: 100%
}
#pricing{
  background-color: #F3F3F3;
}

</style>
