<template>
  <div class="home">
    <v-fab-transition>
      <v-btn :href="getWhatsappLink('Hola equipo Contractflows! Quisiera obtener más información.')" target='_blank' v-show="true" color="#25D366" dark fixed bottom left fab>
        <v-icon x-large>mdi-whatsapp</v-icon>
      </v-btn>
    </v-fab-transition>
    <section id="banner">
      <background style="width:100%" :style="'height: calc(100vh - ' + $vuetify.application.top + 'px)'"></background>
      <v-row no-gutters>
        <v-col>
          <div :style="'height: calc(100vh - ' + $vuetify.application.top + 'px)'" class="text-center py-0">
            <v-container fill-height fluid>
              <v-row align="center" justify="center" class="fill-height">
                <v-col class="white--text px-0" :class="[$vuetify.breakpoint.smAndDown ? 'text-center' : 'text-left']" cols="10" md="4" style="z-index: 2;">
                  <div v-if="$vuetify.breakpoint.smAndDown" class="pb-5">
                    <v-img :src="require('@/assets/logo.png')" contain :max-height="$vuetify.breakpoint.smAndDown ? $vuetify.application.top*1.2 + 'px' : $vuetify.application.top*1.8 + 'px'"></v-img>
                    <img class="pt-2" :src="require('@/assets/logoLetters.png')" :height="$vuetify.breakpoint.smAndDown ? $vuetify.application.top*0.5 + 'px' : $vuetify.application.top*0.6 + 'px'">
                  </div>
                  <span :class="[$vuetify.breakpoint.smAndDown ? 'text-h5' : 'text-h4']" class="font-weight-bold">
                    {{$t('slogan.first')}}
                  </span>
                  <span :class="[$vuetify.breakpoint.smAndDown ? 'text-h5' : 'text-h4']" class="font-weight-light">
                    {{$t('slogan.second')}}
                  </span>
                  <v-row class="pt-5">
                    <v-col>
                      <v-btn x-large color="white" class="primary--text" solo href="https://app.contractflows.com/free-trial" target='_blank'>{{$t('CTA')}}</v-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="10" md="7" class="d-none d-sm-none d-md-none d-md-flex primary--text text-center px-0" tag="h1">
                  <v-img class="" :src="require('../assets/img/newScreen.png')" style="z-index:3;" :height="'calc(100vh - 25vh - ' + $vuetify.application.top + 'px)'" contain>
                    <v-theme-provider dark>
                      <v-row class="fill-height" align="center" justify="center">
                        <v-col cols="auto">
                          <v-btn color="primary" @click="video = true" class="align-self-end" fab outlined>
                            <v-icon large>mdi-play</v-icon>
                          </v-btn>
                          <!-- <div light class="primary-caption white--text pt-3">{{$t('videoCTA')}}</div> -->
                        </v-col>
                      </v-row>
                    </v-theme-provider>
                  </v-img>
                </v-col>
                <div class="downButton">
                  <v-row justify="center">
                    <v-col cols="auto" >
                      <v-btn v-if="!$vuetify.breakpoint.smAndDown" text dark tile fab @click="$vuetify.goTo('#clients')">
                        <v-icon >mdi-chevron-double-down</v-icon>
                      </v-btn>
                      <v-btn v-else text dark small tile fab @click="$vuetify.goTo('#quotes')">
                        <v-icon>mdi-chevron-double-down</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
              </v-row>
            </v-container>
          </div>
        </v-col>
      </v-row>
    </section>
    <!-- <section id="clients" class="dark text-center">
      <v-container class="fill-height pt-5">
          <v-row no-gutters justify="center" align="center" class="fill-height">
            <v-col class="px-0 text-center" cols="12" md="8">
              <div class="text-h6 primary--text font-weight-light">{{$t('clients.title')}}</div>
              <v-row class="pt-0" align="center" justify="center">
                <v-col cols="auto" class="pb-0" v-for="client of clients" :key=client.name>
                  <v-img :src="client.img" contain max-height="100" max-width="120"></v-img>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
      </v-container>
    </section> -->
    <!-- <section id="quotes" class="dark text-center">
      <v-container class="fill-height pt-5" fluid>
          <v-row no-gutters justify="center" align="center" class="fill-height">
            <v-col class="px-0 text-center" cols="12">
              <div class="text-h6 primary--text font-weight-light pb-5">{{$t(' ')}}</div>
              <vue-glide :autoplay="4000" :bound="true" :perView="$vuetify.breakpoint.smAndDown ? 1 : 3" :hoverpause="false">
                <vue-glide-slide
                  v-for="quote in $t('quotes.items')" :key="quote.text">
                  <v-card flat class="elevation-0 my-4" :width="$vuetify.breakpoint.smAndDown ? '80%' : '80%'" style="z-index: 999;" color="transparent">
                    <v-card-text>
                      <div class="font-italic">"{{ quote.text }}"</div>
                    </v-card-text>
                    <v-card-text class="pt-0 text-right">
                      <div>
                        -{{ quote.name }}<span v-if="quote.company != '' && quote.company != null"> @ {{ quote.company }}</span>
                      </div>
                      <div>
                        {{ quote.sector }}
                      </div>
                    </v-card-text>
                  </v-card>
                </vue-glide-slide>
              </vue-glide>
            </v-col>
          </v-row>
      </v-container>
    </section> -->
    <section id="benefits">
      <div class="custom-shape-divider-top-1635369547">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M1200 120L0 16.48 0 0 1200 0 1200 120z" class="shape-fill"></path>
        </svg>
      </div>
      <v-container fluid>
        <v-row justify="center" class="fill-height pt-10">
          <v-col v-for="n in $t('benefits.main')" :key="n.text" cols="6" md="4" lg="2" xl="2">
            <v-card class="text-center py-10 fill-height elevation-20" color="#ededed" style="cursor: initial;">
              <v-card-text>
                <v-icon size="80" v-html="n.icon" color="primary"></v-icon>
                <div class="text-h6 black--text pt-5">{{n.text}}</div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-container>
        <v-row justify="center" class="pt-5">
          <v-col cols="11" md="10" lg="8" xl="6">
            <v-card color="#a8a7a6" class="rounded-lg">
              <v-card-text class="white--text">
                <v-row justify="center" align="center" class="py-3">
                  <v-col cols="auto" class="text-center">
                    <div class="text-h5 font-weight-medium">{{$t('benefits.CTA')}}</div>
                  </v-col>
                  <v-col cols="auto">
                    <v-btn x-large color="primary" class="text" solo href="https://app.contractflows.com/free-trial" target='_blank'>{{$t('CTA')}}</v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section id="howItWorks" >
      <div class="custom-shape-divider-top-1635369547">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M1200 120L0 16.48 0 0 1200 0 1200 120z" class="shape-fill"></path>
        </svg>
      </div>
      <v-container class="text-center white--text" style="padding-top:140px" dark>
        <v-row justify="center" class="pt-10 pb-4">
          <v-col cols="12" md="9" class="">
            <div class="white--text text-h4 font-weight-bold">{{$t('howItWorks.title')}}</div>
            <div class="white--text font-weight-light">{{$t('howItWorks.caption')}}</div>
          </v-col>
        </v-row>
        <v-hover v-slot:default="{ hover }">
          <v-row id="selectTemplate" align="center" justify="center">
            <v-col cols="12" md="5">
              <v-icon size="100" color="#FFFFFF" class="pb-8">mdi-checkbox-marked-circle-outline</v-icon>
              <div class="text-h4 font-weight-bold">{{$t('howItWorks.steps.select.title')}}</div>
              <div class="text-h6 font-weight-regular">{{$t('howItWorks.steps.select.text')}}</div>
            </v-col>
            <v-col cols="10" md="6" lg="5" xl="4">
              <v-card hover id="directoryCard" class="directory" min-height="350px" :style="hover ? 'box-shadow: 0px 0px 10px 10px rgba(255, 255, 255, 0.5);' : ''" style="cursor: initial;">
                <v-card-text class="fill-height">
                  <div>{{$t('howItWorks.steps.select.caption')}}</div>
                  <v-row align="center" justify="center">
                    <v-col>
                      <v-treeview
                        class="text-left"
                        :active.sync="active2"
                        :open="open2"
                        :items="directory"
                        item-key="id"
                        hoverable
                        activatable
                        open-on-click
                      > 
                        <template v-slot:prepend="{ item, open }">
                          <v-icon v-if="!item.file">
                            {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
                          </v-icon>
                          <v-icon v-else>
                            {{ files[item.file] }}
                          </v-icon>
                        </template>
                      </v-treeview>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-hover>
        <v-hover v-slot:default="{ hover }">
          <v-row id="fillInfo" align="center" justify="center">
            <v-col cols="12" md="5">
              <v-icon  size="100" color="#FFFFFF" class="pb-8">mdi-file-document-edit-outline</v-icon>
              <div class="text-h4 font-weight-bold">{{$t('howItWorks.steps.fill.title')}}</div>
              <div class="text-h6 font-weight-regular">{{$t('howItWorks.steps.fill.text')}}</div>
            </v-col>
            <v-col cols="10" md="6" lg="5" xl="4">
              <v-card hover id="fillInfoCard" class="text-left" min-height="350px" :style="hover ? 'box-shadow: 0px 0px 10px 10px rgba(255, 255, 255, 0.5);' : ''" style="cursor: initial;">
                <v-card-text class="black--text">
                  <v-row align="center" justify="center" class="fill-height">
                    <v-col>
                      <v-row>
                        <v-col>
                          <h3 class="text-h6">Datos de la parte interesada</h3>
                        </v-col>
                      </v-row>
                      <v-form v-model="valid" ref="how">
                        <v-row>
                          <v-col class="py-0" cols="12" md="6" xl="6" lg="6">
                            <v-autocomplete v-model="persona" :label="$t('howItWorks.steps.fill.example.personType')" persistent-hint hint="" :items="['Física', 'Jurídica']" :rules="optionGroupFieldRequired" :multiple="false" :clearable="false"></v-autocomplete>
                          </v-col>
                          <v-col class="py-0" cols="12" md="6" xl="6" lg="6">
                            <v-autocomplete v-model="contactType" :label="$t('howItWorks.steps.fill.example.contactType')" persistent-hint hint="" :items="['Telefónica', 'Correo Electrónico']" :rules="optionGroupFieldRequired" :multiple="false" :clearable="false"></v-autocomplete>
                          </v-col>
                        </v-row>
                        <v-row v-if="persona == 'Física'">
                          <v-col cols="12" md="6" xl="6" lg="6" class="py-0">
                            <v-menu :close-on-content-click="false" :nudge-right="0" transition="scale-transition" offset-y min-width="290px">
                              <template v-slot:activator="{ on }">
                                <v-text-field v-model="date" :label="$t('howItWorks.steps.fill.example.date')" prepend-icon="mdi-calendar" :rules="fieldRules" required readonly v-on="on" persistent-hint :hint="$t('howItWorks.steps.fill.example.dateHint')"></v-text-field>
                              </template>
                              <v-date-picker v-model="date"></v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="12" md="6" xl="6" lg="6" class="py-0">
                            <v-textarea v-model="name" :rules="fieldRules" :label="$t('howItWorks.steps.fill.example.name')"  required auto-grow rows="1" row-height="15" persistent-hint :hint="$t('howItWorks.steps.fill.example.nameHint')"></v-textarea>
                          </v-col>
                          <v-col cols="12" md="6" xl="6" lg="6" class="py-0">
                            <v-textarea v-if="contactType=='Telefónica'" v-model="phoneNumber" :rules="fieldRules" :label="$t('howItWorks.steps.fill.example.number')"  required auto-grow rows="1" row-height="15" persistent-hint :hint="$t('howItWorks.steps.fill.example.numberHint')"></v-textarea>
                            <v-textarea v-else-if="contactType=='Correo Electrónico'" v-model="phoneNumber" :rules="fieldRules" :label="$t('howItWorks.steps.fill.example.email')"  required auto-grow rows="1" row-height="15" persistent-hint :hint="$t('howItWorks.steps.fill.example.emailHint')"></v-textarea>
                          </v-col>
                          <v-col cols="12" md="6" xl="6" lg="6" class="py-0">
                            <v-text-field type="number" v-model="identifier" :rules="fieldRules" :label="$t('howItWorks.steps.fill.example.reference')"  required auto-grow rows="1" row-height="15" persistent-hint :hint="$t('howItWorks.steps.fill.example.referenceHint')"></v-text-field>
                          </v-col>
                          
                        </v-row>
                        <v-row v-else-if="persona == 'Jurídica'">
                          <v-col cols="12" md="6" xl="6" lg="6" class="py-0">
                            <v-menu :close-on-content-click="false" :nudge-right="0" transition="scale-transition" offset-y min-width="290px">
                              <template v-slot:activator="{ on }">
                                <v-text-field v-model="date" :label="$t('howItWorks.steps.fill.example.date')" prepend-icon="mdi-calendar" :rules="fieldRules" required readonly v-on="on" persistent-hint :hint="$t('howItWorks.steps.fill.example.dateHint')"></v-text-field>
                              </template>
                              <v-date-picker v-model="date"></v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="12" md="6" xl="6" lg="6" class="py-0">
                            <v-textarea v-model="name" :rules="fieldRules" :label="$t('howItWorks.steps.fill.example.name')"  required auto-grow rows="1" row-height="15" persistent-hint :hint="$t('howItWorks.steps.fill.example.nameHint')"></v-textarea>
                          </v-col>
                          <v-col cols="12" md="6" xl="6" lg="6" class="py-0">
                            <v-textarea v-model="company" :rules="fieldRules" :label="$t('howItWorks.steps.fill.example.company')"  required auto-grow rows="1" row-height="15" persistent-hint :hint="$t('howItWorks.steps.fill.example.companyHint')"></v-textarea>
                          </v-col>
                          <v-col cols="12" md="6" xl="6" lg="6" class="py-0">
                            <v-textarea v-if="contactType=='Telefónica'" v-model="phoneNumber" :rules="fieldRules" :label="$t('howItWorks.steps.fill.example.number')"  required auto-grow rows="1" row-height="15" persistent-hint :hint="$t('howItWorks.steps.fill.example.numberHint')"></v-textarea>
                            <v-textarea v-else-if="contactType=='Correo Electrónico'" v-model="email" :rules="emailRules" :label="$t('howItWorks.steps.fill.example.email')"  required auto-grow rows="1" row-height="15" persistent-hint :hint="$t('howItWorks.steps.fill.example.emailHint')"></v-textarea>
                          </v-col>
                          <v-col cols="12" md="6" xl="6" lg="6" class="py-0">
                            <v-text-field type="number" v-model="identifier" :rules="fieldRules" :label="$t('howItWorks.steps.fill.example.reference')"  required auto-grow rows="1" row-height="15" persistent-hint :hint="$t('howItWorks.steps.fill.example.referenceHint')"></v-text-field>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-hover>
        <v-hover v-slot:default="{ hover }">
          <v-row id="generate" align="center" justify="center">
            <v-col cols="12" md="5">
              <v-icon size="100" color="#FFFFFF" class="pb-8">mdi-file-word-outline</v-icon>
              <div class="text-h4 font-weight-bold">{{$t('howItWorks.steps.generate.title')}}</div>
              <div class="text-h6 font-weight-regular">{{$t('howItWorks.steps.generate.text')}}</div>
            </v-col>
            <v-col cols="10" md="6" lg="5" xl="4">
              <v-card hover id="generateCard" min-height="350px" :style="hover ? 'box-shadow: 0px 0px 10px 10px rgba(255, 255, 255, 0.5);' : ''" style="cursor: initial;">
                <v-card-text class="black--text" >
                  <v-row class="fill-height">
                    <v-col>
                      <v-icon x-large color="primary" dark>mdi-file-word-box</v-icon>
                    </v-col>
                  </v-row>
                  <v-row class="text-left fill-height" align="center" justify="center">
                    <v-col transition="slide-x-transition">
                      <div class="text-h6">{{generated}}</div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-hover>
        <v-hover v-slot:default="{ hover }">
          <v-row id="sign" align="center" justify="center">
            <v-col cols="12" md="5">
              <v-icon size="100" color="#FFFFFF" class="pb-8 ml-5" v-html="'$sign'"></v-icon>
              <div class="text-h4 font-weight-bold">{{$t('howItWorks.steps.sign.title')}}</div>
              <div class="text-h6 font-weight-regular">{{$t('howItWorks.steps.sign.text')}}</div>
            </v-col>
            <v-col cols="10" md="6" lg="5" xl="4">
              <v-card hover id="signCard" height="350px" :style="hover ? 'box-shadow: 0px 0px 10px 10px rgba(255, 255, 255, 0.5);' : ''" style="cursor: initial;">
                <v-card-text class="black--text">
                  <v-row>
                    <v-col v-col cols="12" class="pb-0">
                      <div class="text-body-1">{{$t('howItWorks.steps.sign.example.signHere')}}</div>
                      <div> {{name}} </div>
                    </v-col>
                  </v-row>
                  <v-row align="center" justify="center" class="text-center">
                    <v-col cols="12">
                      <VueSignaturePad height="200px" width="100%" ref="signaturePad" style="border-style: solid; border-width: 0.5px"/>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-row justify="center">
                    <v-col cols="auto">
                      <v-btn small @click="reset" :disabled="submitting">{{$t('howItWorks.steps.sign.example.erase')}}</v-btn>
                    </v-col>
                    <v-col cols="auto">
                      <v-btn small color="primary" :disabled="false" :loading="submitting" @click="submitContact()">{{$t('howItWorks.steps.sign.example.send')}}</v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-hover>
      </v-container>
    </section>
    <section id="plans">
      <div class="custom-shape-divider-top-1635369757">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M1200 120L0 16.48 0 0 1200 0 1200 120z" class="shape-fill"></path>
        </svg>
      </div>
      <v-container style="padding-top:140px">
        <v-row justify="center" class="pt-8">
          <v-col cols="11" md="10" lg="9" xl="6">
            <v-card color="primary" class="rounded-lg">
              <v-card-text class="white--text">
                <v-row justify="center" align="center" class="py-3">
                  <v-col cols="11" md="8" lg="8" xl="8" class="text-center">
                    <div class="text-h5 font-weight-medium">{{$t('plans.text')}}</div>
                  </v-col>
                  <v-col cols="auto">
                    <v-btn dark x-large color="#a8a7a6" class="text" solo :href="'https://calendly.com/contractflows-demo/s'" target='_blank'>{{$t('plans.CTA')}}</v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="text-center pt-8">
          <v-col>
            <div class="text-h4 pb-3">{{$t('plans.title')}}</div>
            <v-btn dark x-large color="primary" class="text" solo to='pricing'>{{$t('plans.seePlans')}}</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section id="useCases" class="text-center white--text">
      <div class="custom-shape-divider-top-1635369547">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M1200 120L0 16.48 0 0 1200 0 1200 120z" class="shape-fill"></path>
        </svg>
      </div>
      <v-container style="padding-top:140px">
        <v-row class="py-5">
          <v-col>
            <div class="white--text text-h4 font-weight-bold">{{$t('useCases.title')}}</div>
          </v-col>
        </v-row>
        <v-row dark class="pb-8" justify="center">
          <v-col cols="10" sm="6" md="5" lg="4" xl="4">
            <v-card dark color="#333333" elevation="5">
              <v-card-text class="white--text elevation-20">
                <v-row>
                  <v-col>
                    <v-img aspect-ratio="1.65" :src="require('@/assets/img/inmobiliarias.jpg')"></v-img>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <div class="white--text text-h5 font-weight-medium">{{$t('useCases.realEstate.title')}}</div>
                    <div class="text-subtitle-1">{{$t('useCases.realEstate.text')}}</div>
                    <!-- <v-btn class="mt-3" outlined :href="getWhatsappLink('Hola equipo Contractflows! Quiero agendar una demo y saber más de los diferentes casos de uso.')" target='_blank'>{{$t('useCases.CTA')}}</v-btn> -->
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="10" sm="6" md="5" lg="4" xl="4">
            <v-card dark color="#333333" elevation="8">
              <v-card-text class="white--text elevation-20">
                <v-row>
                  <v-col>
                    <v-img aspect-ratio="1.65" :src="require('@/assets/img/despachos.jpg')"></v-img>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <div class="white--text text-h5 font-weight-medium">{{$t('useCases.lawFirms.title')}}</div>
                    <div class="text-subtitle-1">{{$t('useCases.lawFirms.text')}}</div>
                    <!-- <v-btn class="mt-3" outlined :href="getWhatsappLink('Hola equipo Contractflows! Quiero agendar una demo y saber más de los diferentes casos de uso.')" target='_blank'>{{$t('useCases.CTA')}}</v-btn> -->
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="10" sm="6" md="5" lg="4" xl="4">
            <v-card dark color="#333333" elevation="8">
              <v-card-text class="white--text elevation-20">
                <v-row>
                  <v-col>
                    <v-img aspect-ratio="1.65" :src="require('@/assets/img/emprendedores.jpg')"></v-img>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <div class="white--text text-h5 font-weight-medium">{{$t('useCases.entrepreneurs.title')}}</div>
                    <div class="text-subtitle-1">{{$t('useCases.entrepreneurs.text')}}</div>
                    <!-- <v-btn class="mt-3" outlined :href="getWhatsappLink('Hola equipo Contractflows! Quiero agendar una demo y saber más de los diferentes casos de uso.')" target='_blank'>{{$t('useCases.CTA')}}</v-btn> -->
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section id="functions">
      <div class="custom-shape-divider-top-1635369662">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M1200 120L0 16.48 0 0 1200 0 1200 120z" class="shape-fill"></path>
        </svg>
      </div>
      <v-container style="padding-top:140px">
        <v-row justify="center" class="py-6">
          <v-col class="text-center">
            <div class="text-h4 font-weight-bold">{{$t('functions.title')}}</div>
          </v-col>
        </v-row>
      
        <v-row justify="center">
          <v-col :key="language" cols="12" md="11" class="px-0 mx-0 ">
            <vue-glide :key="breakpoint" v-model="active" :type="'slider'" :breakpoints="{960: {perView: 2, autoplay:4000}}" :startAt="0" :bullet="false" :autoplay="false" :perView="4" class="text-center py-4 px-0" :hoverpause="true" :keyboard="true" :bound="true" :rewind="true">
              <vue-glide-slide v-for="i in $t('functions.items')" :key="i.title" class="mx-0 px-3">
                <v-card color="gray" height="55vh" hover class="mb-5" style="cursor: initial;">
                  <v-card-text class="pa-0">
                    <v-img :src="i.img" height="55vh" dark>
                      <div class="fill-height bottom-gradient pt-3">
                        <v-container fill-height class="pb-0">
                        <v-row class="fill-height">
                          <v-col class="pb-0">
                            <v-row class="fill-height" align="end" justify="center">
                              <v-col class="pb-0">
                                <div class="text-h6 font-weight-bold">
                                  {{i.title}}
                                </div>
                              </v-col>
                            </v-row>
                            <!-- <v-row justify="center">
                              <v-col class="pt-0">
                                <div class="text-body-2">
                                  {{i.text}}
                                </div>
                              </v-col>
                            </v-row> -->
                          </v-col>
                        </v-row>
                        </v-container>
                      </div>
                    </v-img>
                  </v-card-text>
                </v-card>
              </vue-glide-slide>
              <template slot="control" v-if="breakpoint == 2">
                <v-btn text fab data-glide-dir="<">
                  <v-icon dark> mdi-arrow-left</v-icon>
                </v-btn>
                <v-btn text fab data-glide-dir=">">
                  <v-icon dark> mdi-arrow-right</v-icon>
                </v-btn>
              </template>
            </vue-glide>
          </v-col>
        </v-row>
      </v-container>
      <div class="text-center pt-5">
        <v-btn x-large color="primary" dark :href="'https://calendly.com/contractflows-demo/s'" target='_blank'>{{$t('functions.CTA')}}</v-btn>
      </div>
    </section>
    
    <section id="faq" class="white--text">
      <div class="custom-shape-divider-top-1635369547">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M1200 120L0 16.48 0 0 1200 0 1200 120z" class="shape-fill"></path>
        </svg>
      </div>
      <v-container style="padding-top:140px">
        <v-row justify="center" class="text-center pt-5">
          <v-col>
            <div class="text-h4 font-weight-bold">{{$t('faq.title')}}</div>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12" md="6">
            <v-list dark color="#333333" class="gray--text">
              <v-list-group v-for="item in $t('faq.items')" :key="item.title" v-model="item.active" no-action active-class="white--text">
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title class="grey--text" v-text="item.title"></v-list-item-title>
                  </v-list-item-content>
                </template>
                <v-list-item v-for="child in item.items" :key="child.title">
                  <v-list-item-content>
                    <div class="white--text text-body-1">{{child.title}}</div>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
            </v-list>
          </v-col>
        </v-row>
        <v-row justify="center" class="text-center py-6">
            <v-col cols="auto">
              <div class="text-h4">{{$t('faq.caption2')}}</div>
              <v-btn x-large color="white" class="primary--text my-6" href="https://app.contractflows.com/free-trial">{{$t('faq.CTA2')}}</v-btn>
          </v-col>
        </v-row>
        <v-row class="text-center pb-5" align="center" justify="center">
          <v-col cols="auto">
            <div class="text-h5">{{$t('faq.CTA')}}</div>
            <div class="text-caption">{{$t('faq.caption')}}</div>
          </v-col>
          <v-col cols="7" md="7" lg="3" xl="2">
            <v-form v-model="emailForm" ref="emailf">
              <v-text-field v-model="email2" dark color="white" x-large outlined :label="$t('email')" :rules="emailRules" placeholder="you@example.com" hide-details></v-text-field>
            </v-form>
          </v-col>
          <v-col cols="auto">
            <v-btn :loading="submitting" @click="submitEmail()">{{$t('faq.send')}}</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <!-- <div :style="'height:140px'"></div> -->
    <!-- <div :style="'height:'+$vuetify.application.footer+'px'"></div> -->
    <v-dialog v-model="video" width="600" >
      <!-- <v-card> -->
        <iframe id="videoFrame" width="600" height="337.5" src="https://drive.google.com/file/d/1eCuAzQxZCAW9Z17N8jv42R7_AFoiJqVW/preview" frameborder="0" allowfullscreen allow="autoplay"></iframe>
      <!-- </v-card> -->
    </v-dialog>
    <v-snackbar v-model="snackbar" :color="snackbarColor">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
// @ is an alias to /src
import icon from "../assets/logo.png";
var writtenNumber = require('written-number')
writtenNumber.defaults.lang = 'es'
import moment from 'moment'
import Background from '../components/background.vue';
moment.locale('es')
const axios = require('axios')
// import '@/assets/devices.css'
// import '@/assets/device-mockups.css'
import '@/assets/devices.min.css'
import backgroundUrl from "@/assets/home.jpg";

export default {
  name: 'Home',
  components: {
    Background
  },
  data: () => ({
    backgroundUrl,
    isIntersecting: false,
    intersectOptions: {
      trackVisibility: true,
      delay: 100,
      rootMargin: '0px',
      threshold: [0.01, 0.02, 0.03, 0.04, 0.05, 0.06, 0.07, 0.08, 0.09, 0.1, 0.11, 0.12, 0.13, 0.14, 0.15, 0.16, 0.17, 0.18, 0.19, 0.2, 0.21, 0.22, 0.23, 0.24, 0.25, 0.26, 0.27, 0.28, 0.29, 0.3, 0.31, 0.32, 0.33, 0.34, 0.35, 0.36, 0.37, 0.38, 0.39, 0.4, 0.41, 0.42, 0.43, 0.44, 0.45, 0.46, 0.47, 0.48, 0.49, 0.5, 0.51, 0.52, 0.53, 0.54, 0.55, 0.56, 0.57, 0.58, 0.59, 0.6, 0.61, 0.62, 0.63, 0.64, 0.65, 0.66, 0.67, 0.68, 0.69, 0.7, 0.71, 0.72, 0.73, 0.74, 0.75, 0.76, 0.77, 0.78, 0.79, 0.8, 0.81, 0.82, 0.83, 0.84, 0.85, 0.86, 0.87, 0.88, 0.89, 0.9, 0.91, 0.92, 0.93, 0.94, 0.95, 0.96, 0.97, 0.98, 0.99, 1, 0]
    },
    selectedTemplate: [],
    video: false,
    whatsappWebLink: 'https://web.whatsapp.com/send?phone=50255122473',
    whatsappAppLink: 'https://wa.me/50255122473',
    offsetTop: 0,
    hidden: true,
    clients: [
      {name: '270', img: require('@/assets/clients/270.png')},
      // {name: 'Alegalis', img: require('@/assets/clients/alegalis.png')},
      {name: 'Legal Oursourcing', img: require('@/assets/clients/legalOutsourcing.png')},
      // {name: 'Legal Plus', img: require('@/assets/clients/legalPlus.png')},
      {name: 'StreamGT', img: require('@/assets/clients/streamGT.png')},
      {name: 'Core Learning', img: require('@/assets/clients/coreLearning.svg')},
      // {name: 'Core Learning', img: require('@/assets/clients/carrillo.svg')}
    ],
    active: 0,
    active2: 0,
    model: null,
    config: {
      num: [1, 1],
      rps: 2,
      radius: [1, 100],
      life: [10, 10],
      v: [0.1, 0.5],
      tha: [0, 0],
      body: icon,
      alpha: [0.6, 0],
      scale: [0.3, 0.8],
      position: {x:1,y:1,width: 1,height:500}, //"center",
      cross: "dead",
    },
    benefits: [
      {icon: '$eficient', text:"20x más eficiente"},
      {icon: '$noError', text:"Sin error humano"},
      {icon: '$centralized', text:"Centralizado"},
      {icon: '$secure', text:"Confidencial y seguro"},
      {icon: '$easy', text:"Fácil de usar"},
    ],

    // como funciona
    directory: [],
    files: {
      html: 'mdi-language-html5',
      js: 'mdi-nodejs',
      json: 'mdi-json',
      md: 'mdi-markdown',
      pdf: 'mdi-file-pdf',
      png: 'mdi-file-image',
      txt: 'mdi-file-document-outline',
      xls: 'mdi-file-excel',
    },
    active2: [],
    open2: [],
    optionGroupFieldRequired: [
      v => !!v || 'Campo es requerido',
    ],
    fieldRules: [
      v => !!v || 'El campo es requerido',
    ],
    emailRules: [
      v => !!v || 'El correo es requerido',
      v => /.+@.+\..+/.test(v) || 'El correo debe ser valido'
    ],
    submitting: false,
    valid: false,
    persona: 'Jurídica',
    contactType: 'Telefónica',
    name: null,
    phoneNumber: null,
    email: null,
    company: null,
    profession: null,
    industry: null,
    identifier: null,
    date: null,
    industries: ['legal', 'inmobiliaria', 'financiera', 'agricultura', 'tecnología',],
    professions: ['abogado y notario', 'abogada y notaria','ingeniero', 'ingeniera', 'procurador', 'procuradora', 'asistente legal'],
    email2: null,
    emailForm: false,
    // generated: null,

    snackbar: false,
    snackbarText: '',
    snackbarTimeout: 5000,
    snackbarColor: ''
  }),
  computed: {
    quotes: function() {
      return this.$t('quotes.items')
    },
    language: function () {
      return this.$store.getters.LANG
    },
    breakpoint: function() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 2
        case 'sm': return 2
        case 'md': return 4
        case 'lg': return 4
        case 'xl': return 4
      }
      // return this.$vuetify.breakpoint.name
    },
    calculateBannerHeight: function(){
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '33vh'
        case 'sm': return '21vh'
        case 'md': return '21vh'
        case 'lg': return '27vh'
        case 'xl': return '21vh'
      }
    },
    // El dia ____, nombre_persona manifiesta que quire ser contactado por el equipo de Contractflows via tipo_contacto, al numero {numero}  
    //                            ,de la empresa {empresa}                                                                 a la siguiente direccion {correo} . Referencia referenciaLetras (referenciaNumero)
    generated: function() {
      var date = this.date != null && this.date != '' ? this.convertDate(this.date) : '___________'
      var name = this.name != null && this.name != '' ? this.name : '___________'
      var company = this.company != null && this.company != '' ? this.company : '___________'
      var phoneNumber = this.phoneNumber != null && this.phoneNumber != '' ? this.phoneNumber : '___________'
      var email = this.email != null && this.email != '' ? this.email : '___________'
      var identifier = this.identifier != null && this.identifier != '' ? this.identifier : '___________'
      var identifierLetters = this.identifier != null && this.identifier != '' ? this.converNumber(this.identifier) : '___________'
      var base = 
        [{text: `El día ${date}, ${name},`},
        {text: `de la empresa ${company},`, condition: this.persona=='Jurídica'},
        {text: `manifiesta que quiere ser contactado por el equipo de Contractflows`},
        {text: `por vía telefónica, al número ${phoneNumber}.`, condition: this.contactType == 'Telefónica'},
        {text: `vía correo electrónico, a la siguiente dirección: ${email}.`, condition: this.contactType == 'Correo Electrónico'},
        {text: `Referencia ${identifierLetters} (${identifier}).`}
        ]
      var texts = []
      base.forEach(item => {
        if(item.condition === true){
          texts.push(item.text)
        }
        else if(item.condition == null){
          texts.push(item.text)
        }
      })
      return texts.join(' ')

      // if(this.persona == 'Física'){
      //   var date = this.date != null && this.date != '' ? this.convertDate(this.date) : '___________'
      //   var name = this.name != null && this.name != '' ? this.name : '___________'
      //   var identifier = this.identifier != null && this.identifier != '' ? this.identifier : '___________'
      //   var identifierLetters = this.identifier != null && this.identifier != '' ? this.converNumber(this.identifier) : '___________'
      //   var profession = this.profession != null && this.profession != '' ? this.profession : '___________'
      //   var phoneNumber = this.phoneNumber != null && this.phoneNumber != '' ? this.phoneNumber : '___________'
      //   var base = `Durante el día ${date}, la persona identificada como  ${name}, con identificador ${identifierLetters} (${identifier}), ${profession}, quisiera indicar poder ser contactada por el equipo de Contractflows para conocer más sobre el producto al número de teléfono ${phoneNumber}.`
      //   return base
      // } else {
      //   var date = this.date != null && this.date != '' ? this.convertDate(this.date) : '___________'
      //   var name = this.name != null && this.name != '' ? this.name : '___________'
      //   var identifier = this.identifier != null && this.identifier != '' ? this.identifier : '___________'
      //   var identifierLetters = this.identifier != null && this.identifier != '' ? this.converNumber(this.identifier) : '___________'
      //   var industry = this.industry != null && this.industry != '' ? this.industry : '___________'
      //   var phoneNumber = this.phoneNumber != null && this.phoneNumber != '' ? this.phoneNumber : '___________'
      //   return `Durante el día ${date}, un representante de la empresa ${name}, con identificador ${identifierLetters} (${identifier}), basada en la industria ${industry}, quisiera indicar poder ser contactada por el equipo de Contractflows para conocer más sobre el producto al número de teléfono ${phoneNumber}.`
      // }
    }
  },
  methods: {
    // onIntersect (entries, observer, isIntersecting) {
    //   if (entries[0].intersectionRect.top < 550 && (isIntersecting && entries[0].intersectionRatio > 0.99)) {
    //     // console.log(entries[0].target.id)
    //     console.log(entries[0])
    //     this.isIntersecting = entries[0].target.id
    //     // this.isIntersecting = isIntersecting
    //     console.log(this.isIntersecting)
    //   }
    // },
    setSnackbar (text, color, timeout = 5000) {
      this.snackbarText = text
      this.snackbarColor = color
      this.snackbarTimeout = timeout
      this.snackbar = true
    },
    getWhatsappLink(text=null){
      if(text == null){
        if(this.$vuetify.breakpoint.mobile){
          return this.whatsappAppLink
        }
        return this.whatsappWebLink
      }
      if(this.$vuetify.breakpoint.mobile){
        return encodeURI(this.whatsappAppLink + '?text='+text)
      }
      return encodeURI(this.whatsappWebLink + '&text='+text)
    },
    onScroll (e) {
      this.offsetTop = e.target.scrollTop
    },
    reset() {
      this.$refs.signaturePad.clearSignature()
    },
    getDate(){
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();

      today = yyyy + '-' + mm + '-' + dd
      return today
    },
    converNumber(number){
      number = parseFloat(number)
      if(isNaN(number)){
        return false
      }
      var finalNumber = writtenNumber(number, {lang: 'es'}).replace('uno', 'un')
      if(finalNumber.endsWith('un')){
        finalNumber = finalNumber+'o'
      }
      return finalNumber
    },
    convertDate(date){
      date = moment(date).format("LL")
      var dateArray = date.split(' ')
      dateArray[0] = this.converNumber(dateArray[0])
      dateArray[dateArray.length - 1] = this.converNumber(dateArray[dateArray.length - 1])
      return dateArray.join(' ')
    },
    async submitContact () {
      const vm = this
      vm.submitting = true
      // eslint-disable-next-line
      if (!this.$refs.how.validate()) {
        this.$vuetify.goTo('#fillInfo')
        this.submitting = false
        this.setSnackbar('Revisa los campos', 'error')
        return
      }
      await this.$recaptchaLoaded()
      const token = await this.$recaptcha('contactHowItWorks')
      var data = JSON.stringify({
        email: 'howitworksform@contractflows.com',
        name: this.name,
        subject: 'Contacto Contractflows',
        message: this.generated,
        agreement: true,
        country: 'unknown country',
        recaptcha: token
      })
      var config = {
        method: 'POST',
        url: process.env.VUE_APP_CONTACT,
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      }
      // eslint-disable-next-line
      axios(config).then(function (response) {
        vm.setSnackbar('¡Te contactaremos!', 'success')
        vm.submitting = false
        // console.log(JSON.stringify(response.data))
        // eslint-disable-next-line
      }).catch(function (error) {
        vm.submitting = false
        vm.setSnackbar('Error al enviar la información', 'error')
        console.error(error)
      })
    },
    async submitEmail () {
      const vm = this
      vm.submitting = true
      // eslint-disable-next-line
      if (!this.$refs.emailf.validate()) {
        this.submitting = false
        this.setSnackbar('Revisa los campos', 'error')
        return
      }
      await this.$recaptchaLoaded()
      const token = await this.$recaptcha('contactHowItWorks')
      var data = JSON.stringify({
        email: this.email2,
        name: 'Unkown Name',
        subject: 'Contacto Contractflows Correo',
        message: 'Contactar al correo ' + this.email2,
        agreement: true,
        country: 'unknown country',
        recaptcha: token
      })
      var config = {
        method: 'POST',
        url: process.env.VUE_APP_CONTACT,
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      }
      // eslint-disable-next-line
      axios(config).then(function (response) {
        vm.setSnackbar('¡Te contactaremos!', 'success')
        vm.submitting = false
        vm.$refs.emailf.reset()
        // console.log(JSON.stringify(response.data))
        // eslint-disable-next-line
      }).catch(function (error) {
        vm.submitting = false
        vm.setSnackbar('Error al enviar la información', 'error')
        console.error(error)
      })
    },
  },
  mounted () {
    // const recaptcha = this.$recaptchaInstance
    // recaptcha.hideBadge()
    this.directory = require('../../directory.json')
    this.identifier = Math.floor(Math.random()*90000) + 10000
    this.date = this.getDate()
  },
  watch: {
    active2: function(newVal, oldVal){
      if(newVal.length>0){
        this.$vuetify.goTo('#fillInfo', {duration: 300, easing: 'easeInOutCubic',})
      }
    },
    video: function(newVal, oldVal){
      if(newVal){
        const sleep = (milliseconds) => {
          return new Promise(resolve => setTimeout(resolve, milliseconds))
        }
        sleep(2000).then(() => {
          console.log('buenas')
          const elem = this.$refs.myVideo
          elem.click()
        })
      }
    }
  }
}
</script>

<style>
.downButton{
  position: absolute;
  bottom: 0px;
}

section{
  position: relative;
}

.v-card__text, .v-card__title {
  word-break: normal; /* maybe !important  */
}
#banner {
  background-color: #123a5f;
  
}
#clients {
  background-color: #b2d4f5;
}
#quotes {
  background-color: #e4e4e4;
}
/* #benefits {
  background-color: #FFFFFF;
} */

#howItWorks {
  background-color: #333333;
}
#useCases {
  background-color: #123a5f;
}
#faq {
  background-color: #333333;
}

.directory{
  display: flex !important;
  flex-direction: column;
}
.directory .v-card__text{
  flex-grow: 1;
  overflow: auto;
}
.bottom-gradient {
  background-image: linear-gradient(to top, rgba(0, 0, 0, 1.0) 0%, transparent 300px);
}

/* LIGHT BLUE */
.custom-shape-divider-top-1635369486 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}
.custom-shape-divider-top-1635369486 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 140px;
}
.custom-shape-divider-top-1635369486 .shape-fill {
    fill: #e4e4e4;
}

/* WHITE */
.custom-shape-divider-top-1635369547 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-top-1635369547 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 140px;
}

.custom-shape-divider-top-1635369547 .shape-fill {
    fill: #FFFFFF;
}

/* PRIMARY */
.custom-shape-divider-top-1635369662 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-top-1635369662 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 140px;
}

.custom-shape-divider-top-1635369662 .shape-fill {
    fill: #123A5F;
}

/* DARK GREY */
.custom-shape-divider-top-1635369757 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-top-1635369757 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 140px;
}

.custom-shape-divider-top-1635369757 .shape-fill {
    fill: #333333;
}

</style>
