<template>
  <div id="settingTemplate">
    <v-container>
      <v-row>
        <v-col>
          <div class="text-body-1 text-justify">
            <div class="text-center text-h4">Setting up a template:</div>
            <div id="uploadingTemplate" v-intersect="{
              handler: onIntersect,
              options: intersectOptions
            }">
              <br />
              <br />
              <div class="text-h5 mt-0 text-center">Uploading a template</div>
              <br />
              <div>
                After you have completed your template, you can upload it to ContractFlows following these steps:
              </div>
              <br />
              <ol>
                <li>In the templates page, click on the "File Input" field. An explorer window will be displayed.</li>
                <li>
                  Select the template you want to upload and click on the open button.
                </li>
                <li>Click on the Upload File button.</li>
              </ol>
              <br />
              <div>Before uploading a template check that all the fields were added correctly.
                If there is an error in the template, it won't be possible to upload it.
              </div>
            </div>
            <v-divider class="my-5"></v-divider>
            </div>
          <div class="text-body-1 text-justify">
            <div id="fieldLabels" v-intersect="{
              handler: onIntersect,
              options: intersectOptions
            }">
              <br />
              <div class="text-h5 mt-0 text-center">Setting field labels</div>
              <br />
              <div>
                You can set tag names to each field to make it easier for the
                users to fill information using the templaes. To set the tag
                names follow these steps:
              </div>
              <br />
              <ol>
                <li>In the templates page, click on the settings button of the template you want to configure.</li>
                <li>In the Manage Placeholder Names section, click on the field you want to rename.</li>
                <li>Type a new name for the field.</li>
                <li>Repeat the steps for each field.</li>
                <li>Click on the "Set New Tag Names" button to save the settings.</li>
              </ol>
              <br />
              <div>You can rename the fields as many times as needed.</div>
              </div>
             <v-divider class="my-5"></v-divider>
            <div id="fieldHints" v-intersect="{
              handler: onIntersect,
              options: intersectOptions
            }"> <br />
            </div>
              <br />
             <div class="text-h5 mt-0 text-center">Setting field hints</div>
             <br>
             <div>
                You can include hints below the fields in the template. This hints can be a description of the field
                or an example of how the user can fill the information. To set hints follow these steps:
            </div>
            <br>
              <ol>
                <li>In the templates page, click on the settings button of the template you want to configure.</li>
                <li>In the Hints section, click on the field you want to set a hint.</li>
                <li>Type the hint for the field.</li>
                <li>Repeat the steps for each field you want to set a hint. You don't need to set a hint for all fields. It's an optional feature.</li>
                <li>Click on the "Set New Hints" button to save the settings.</li>
              </ol>
              <br />
              <div>You can change the hints as many times as needed.</div>
            <v-divider class="my-5"></v-divider>
            <div id="groupLabelsAndOrder" v-intersect="{
              handler: onIntersect,
              options: intersectOptions
            }"> <br />
              <div class="text-h5 mt-0 text-center">Setting group labels and order</div>
              <br />
              <div>To configure groups names and order follow these simple steps:</div>
              <br />
              <ol>
                <li>In the templates page, click on the settings button of the template you want to configure.</li>
                <li>
                  In the Group Labels and Order section, click on the group you
                  want to rename.
                </li>
                <li>Type a new name for the group.</li>
                <li>Repeat the steps for each group.</li>
                <li>To order the groups, click and drag them.</li>
                <li>
                  Click on the "Set Labels and Order" button to save the
                  settings.
                </li>
              </ol>
              <br />
              <div>
                You can rename and reorder the groups as many times as needed.
              </div>
            </div>
            <v-divider class="my-5"></v-divider>
            <div id="dropdownFields" v-intersect="{
              handler: onIntersect,
              options: intersectOptions
            }"> <br />
              <div class="text-h5 mt-0 text-center">Setting dropdown fields</div>
              <br />
              <div>
                To configure the dropdown fields follow these simple steps:
              </div>
              <br />
              <ol>
                <li>In the templates page, click on the settinsg button of the template you want to configure.</li>
                <li>
                  In the dropdown fields section, click on the field you want to
                  configure.
                </li>
                <li>
                  Type the entry you want to appear in the dropdown list, and
                  press enter.
                </li>
                <li>Type as many entries as you need.</li>
                <li>Repeat the steps for each dropdown field.</li>
                <li>
                  Click on the "Set Dropdown Fields" button to save the
                  settings.
                </li>
              </ol>
              <br />
              <div>
                To delete an entry from the dropdown list options just click on
                the x button.
              </div>
           <v-divider class="my-5"></v-divider>
           <div id="directory" v-intersect="{
              handler: onIntersect,
              options: intersectOptions
            }"> <br />
              <div class="text-h5 mt-0 text-center">Setting up a directory</div>
              <br />
              <div>
                You can organize your templates using directories. Directories work as folders that contain the templates.
              </div>
              <br>
              <div>
                To set a directory, follow these steps:
              </div>
              <br />
              <ol>
                <li>In the templates page, click on the settings button of the template you want to configure.</li>
                <li>
                  In the Template Directory section type a diagonal symbol "/".
                </li>
                <li>
                  Type the name of the directory. The name can contain letters, numbers and underscores "_". It can´t contain special characters or blank spaces.
                </li>
                <li>
                  To include a subdirectory, type a diagonal symbol "/".
                </li>
                <li>
                  Type the name of the subdirectory.
                </li>
                <li>
                  Click on the "Set Directory" button to save the
                  changes.
                </li>
              </ol>
              <br />
              <div>
                Directories can contain many subdirectories.
              </div>
              <br>
              <div>
                Example:  /agreements/leasing
              </div>
              <br>
              <div>
              The directory would appear this way:
              </div>
            </div>
            </div>
          </div>
          <br>
          <v-img src='@/assets/img/directory.png' max-width="50%">
               </v-img>
        <v-divider class="my-5"></v-divider>
            <div id="copyConfiguration" v-intersect="{
              handler: onIntersect,
              options: intersectOptions
            }"> <br />
              <div class="text-h5 mt-0 text-center">Copy a template´s configuration</div>
              <br />
              <div>
                A template´s configuration can be copied to be used in another template. The configuration can be copied for the fields
                that are shared between the templates. These are the fields with the same name.
                <br>
                To copy a template´s configuration, follow these steps:
              </div>
              <br />
              <ol>
                <li>In the templates page, click on the copy button of the template you want to copy the configuration.</li>
                <li>From the list that will appear, choose the template from which you want to copy the configuration.</li>
                <li>Click on the "Copy" button.</li>
              </ol>
              <br />
              <div>
                The template that received the copied configuration will include the text "copy" in its description.
              </div>
              </div>
        </v-col>
        <v-col class="d-none d-md-flex" lg="2" md="3">
          <subNav :items="items" :isIntersecting="isIntersecting"></subNav>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import SubNav from '@/components/docs/SubNav'

export default {
  name: 'settingTemplate',
  components: {
    SubNav
  },
  data: () => ({
    isIntersecting: false,
    items: [
      { text: 'Uploading a template', id: 'uploadingTemplate' },
      { text: 'Field labels', id: 'fieldLabels' },
      { text: 'Field hints', id: 'fieldHints' },
      { text: 'Group labels and order', id: 'groupLabelsAndOrder' },
      { text: 'Dropdown fields', id: 'dropdownFields' },
      { text: 'Directory', id: 'directory' },
      { text: 'Copy configuration', id: 'copyConfiguration' }
    ],
    intersectOptions: {
      trackVisibility: true,
      delay: 100,
      rootMargin: '0px',
      threshold: [0.01, 0.02, 0.03, 0.04, 0.05, 0.06, 0.07, 0.08, 0.09, 0.1, 0.11, 0.12, 0.13, 0.14, 0.15, 0.16, 0.17, 0.18, 0.19, 0.2, 0.21, 0.22, 0.23, 0.24, 0.25, 0.26, 0.27, 0.28, 0.29, 0.3, 0.31, 0.32, 0.33, 0.34, 0.35, 0.36, 0.37, 0.38, 0.39, 0.4, 0.41, 0.42, 0.43, 0.44, 0.45, 0.46, 0.47, 0.48, 0.49, 0.5, 0.51, 0.52, 0.53, 0.54, 0.55, 0.56, 0.57, 0.58, 0.59, 0.6, 0.61, 0.62, 0.63, 0.64, 0.65, 0.66, 0.67, 0.68, 0.69, 0.7, 0.71, 0.72, 0.73, 0.74, 0.75, 0.76, 0.77, 0.78, 0.79, 0.8, 0.81, 0.82, 0.83, 0.84, 0.85, 0.86, 0.87, 0.88, 0.89, 0.9, 0.91, 0.92, 0.93, 0.94, 0.95, 0.96, 0.97, 0.98, 0.99, 1, 0]
    }
  }),
  methods: {
    onIntersect (entries, observer, isIntersecting) {
      // https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
      if (entries[0].intersectionRect.top < 100 && (isIntersecting && entries[0].intersectionRatio > 0.50)) {
        // console.log(entries[0].target.id)
        // console.log(entries[0])
        this.isIntersecting = entries[0].target.id
      }
      // this.isIntersecting = isIntersecting // entries[0].intersectionRatio >= 0.5
    }
  },
  mounted () {
    // const recaptcha = this.$recaptchaInstance
    // recaptcha.hideBadge()
  }
}
</script>

<style></style>
