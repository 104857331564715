import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics"

const config = {
  apiKey: process.env.VUE_APP_FIRECONFIG_APIKEY,
  authDomain: process.env.VUE_APP_FIRECONFIG_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_FIRECONFIG_DBURL,
  projectId: process.env.VUE_APP_FIRECONFIG_PROJECTID,
  storageBucket: process.env.VUE_APP_FIRECONFIG_STORAGE,
  messagingSenderId: process.env.VUE_APP_FIRECONFIG_MSGSENDERID,
  appId: process.env.VUE_APP_FIRECONFIG_APPID,
  measurementId: process.env.VUE_APP_FIRECONFIG_MEASUREMENTID
}
const app = initializeApp(config)
const analytics = getAnalytics(app)

export {
  analytics
}