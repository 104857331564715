<template>
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 103.79 103.79">
    <path class="cls-1" d="M51.9-1a52.9,52.9,0,1,0,52.89,52.9A53,53,0,0,0,51.9-1Zm4,97.61v-5a4,4,0,0,0-8,0v5A45,45,0,0,1,7.19,55.9H11a4,4,0,0,0,0-8H7.19A45,45,0,0,1,47.9,7.19v5a4,4,0,0,0,8,0v-5A45,45,0,0,1,96.61,47.9H91.1a4,4,0,0,0,0,8h5.51A45,45,0,0,1,55.9,96.61Z"/><path class="cls-1" d="M74.31,62.15,55.9,51.62V29.41a4,4,0,0,0-8,0V52.94c0,.15,0,.3,0,.45a4,4,0,0,0,2,4L70.34,69.09a4,4,0,1,0,4-6.94Z"/>
  </svg>
</template>

<script>
export default {
  name: 'eficient'
}
</script>

<style>
  .cls-1{
    fill:#0e3a5e;
  }
</style>