<template>
  <div id="fields">
    <v-container>
      <v-row id="target2">
        <v-col>
          <div class="text-body-1 text-justify">
            <div class="text-center text-h4">Type of fields:</div>
            <br />
            <div class="text-h7 text-center">
                In the .docx file, write your document using the following
                types of fields:
            </div>
            <br />
            <div id="simpleField" v-intersect="{
              handler: onIntersect,
              options: intersectOptions
            }"><br>
              <div class="text-h5 mt-0 text-center" >Simple field</div>
              <br />
              <div>
                A simple field allows the user to input any combination of
                letters, numbers, and special characters.
              </div>
              <br />
              <div class="text-justify">
                To add a simple field in your template, write the name of the
                field between curly brackets “{}” in the position you want the
                inputted information to be displayed. You can name the fields
                however you want, using letters from A to Z, numbers from 0-9,
                and underscores “_”. The name of the field can´t contain
                accents, blank spaces or any other special character.
              </div>
              <br />
              <div><span class="font-weight-bold">Formula: </span>{fieldName}</div>
              <br />
              <br />
              <div class="text-decoration-underline">
                Example 1: Simple field for text input:
              </div>
              <br />
              <div>
                Text: “________ agrees to sell 10 laptops for $5,000.00.”
              </div>
              <div>Example: {seller}</div>
              <div>
                Text with simple field: “{seller} agrees to sell 10 laptops for
                $5,000.00.”
              </div>
              <div>Input: “John Smith”</div>
              <div>
                Result: “John Smith agrees to sell 10 laptops for $5,000.00.”
              </div>
              <br />
              <br />
              <div class="text-decoration-underline">
                Example 2: Simple field for numbers:
              </div>
              <br />
              <div>
                Text: “John Smith agrees to sell ___ laptops for $5,000.00.”
              </div>
              <div>Simple field: {amount}</div>
              <div>
                Text with simple field: “John Smith agrees to sell {amount}
                laptops for $5,000.00
              </div>
              <div>Input: “10”</div>
              <div>
                Result: “John Smith agrees to sell 10 laptops for $5,000.00.”
              </div>
              <br />
              <br />
              <div class="text-decoration-underline">
                Example 3: Multiple simple fields:
              </div>
              <br />
              <div>
                Text: “_________ agrees to sell ____ laptops for ________”
              </div>
              <div>Simple fields: {seller}, {amount}, {price}.</div>
              <div>
                Text with simple fields: “{seller} agrees to sell {amount}
                laptops for {price}”
              </div>
              <div>Input: “John Smith”, “10”, “$5,000.00”</div>
              <div>
                Result: “John Smith agrees to sell 10 laptops for $5,000.00.”
              </div>
            </div>
            <br />
            <v-divider class="my-5"></v-divider>
            <div id="dateField" v-intersect="{
              handler: onIntersect,
              options: intersectOptions
            }"> <br>
              <div class="text-h5 mt-0 text-center">Date field*</div>
              <br />
              <div class="text-justify">
                A date field allows the user to input a date by selecting it
                from a calendar. To add a date field in your template, write the
                name of the field, appended with the text “_date” between curly
                brackets “{}”.
              </div>
              <br />
              <div>
                <span class="font-weight-bold">Formula: </span> {fieldName_date}
              </div>
              <br />
              <br />
              <div class="text-decoration-underline">Example:</div>
              <br />
              <div>Text: “The lease agreement will start on _________”</div>
              <div>Date field: {agreement_date}</div>
              <div>
                Text with date field: “The lease agreement will start on
                {agreement_date}”
              </div>
              <div>Input: 25/10/2020</div>
              <div>
                Result: “The lease agreement will start on October 25, 2020.
              </div>
              <br />
              <div class="font-weight-bold">
                *Date fields currently work with Spanish. English date
                generation will be added shortly.
              </div>
            </div>
            <br />
            <v-divider class="my-5"></v-divider>
            <div id="numberField" v-intersect="{
              handler: onIntersect,
              options: intersectOptions
            }"><br>
              <div class="text-h5 mt-0 text-center">Number field*</div>
              <br>
              <div>
                Number fields are used when the user needs to input only
                numbers, and when the output number must also be written in
                letters.
              </div>
              <br />
              <div>Input: “200”.</div>
              <div>Outputs: “200” and “two hundred”.</div>
              <br />
              <div class="font-weight-bold">First field:</div>
              <div>
                Write the name of the field between curly brackets “{}”. The
                output will display the number as inputted by the user.
              </div>
              <br />
              <div>Example: {amount}.</div>
              <div>Input: “200”. Output: “200”.</div>
              <br />
              <div class="font-weight-bold">Second field:</div>
              <div>
                Write the name of the first field between curly brackets “{}”
                appended with the text “_letters”. The output will display the
                number written in letters.
              </div>
              <br />
              <div>Example: {amount_letters}.</div>
              <div>Input: “200”. Output: “two hundred”.</div>
              <br />
              <div>
                <span class="font-weight-bold">Formula: </span>{fieldName}
                {fieldName_letters}
              </div>
              <br />
              <div>You can write the fields in any order.</div>
              <br />
              <br />
              <div class="text-decoration-underline">Example:</div>
              <br />
              <div>
                Text: “John Smith agrees to sell _______ (____) laptops for
                $5,000.00.”
              </div>
              <div>Number fields: {amount} {amount_letters}</div>
              <div>
                Text with fields: “John Smith agrees to sell {amount_letters}
                ({amount}) laptops for $5,000.00.”
              </div>
              <div>Input: 10</div>
              <div>
                Result: “John Smith agrees to sell ten (10) laptops for
                $5,000.00.”
              </div>
              <br />
              <div class="font-weight-bold">
                *Number fields currently work with Spanish.
              </div>
            </div>
            <br />
            <v-divider class="my-5"></v-divider>
            <div id="codeField" v-intersect="{
              handler: onIntersect,
              options: intersectOptions
            }"><br>
              <div class="text-h5 mt-0 text-center">Code field*</div>
              <br>
              <div>
                Code fields are used when the user needs to input
                numbers, letters and symbols (/ - .) and when the output must also be written in
                letters.
              </div>
              <br />
              <div>Input: “A-25/13”.</div>
              <div>Outputs: “A-25/13” and “A dash twenty five slash thirteen”.</div>
              <br />
              <div class="font-weight-bold">First field:</div>
              <div>
                Write the name of the field between curly brackets “{}”. The
                output will display the text as inputted by the user.
              </div>
              <br />
              <div>Example: {serialNumber}.</div>
              <div>Input: “25EA2”. Output: “25EA2”.</div>
              <br />
              <div class="font-weight-bold">Second field:</div>
              <div>
                Write the name of the first field between curly brackets “{}”
                appended with the text “_code”. The output will display the
                text written in letters.
              </div>
              <br />
              <div>Example: {serialNumber_code}.</div>
              <div>Input: “25EA2”. Output: “twenty five EA two”.</div>
              <br />
              <div>
                <span class="font-weight-bold">Formula: </span>{fieldName}
                {fieldName_code}
              </div>
              <br />
              <div>You can write the fields in any order.</div>
              <br />
              <br />
              <div class="text-decoration-underline">Example:</div>
              <br />
              <div>
                Text: “John Smith agrees to sell a laptop with serial number _____ (__) for
                $500.00.”
              </div>
              <div>Code fields: {serialNumber} {serialNumber_code}</div>
              <div>
                Text with fields: “John Smith agrees to sell a laptop with serial number {serialNumber_code} ({serialNumber}) for
                $500.00.”
              </div>
              <div>Input: A.123/Z</div>
              <div>
                Result: “John Smith agrees to sell a laptop with serial number A dot one hundred and twenty three slash Z (A.123/Z) for
                $500.00.”
              </div>
              <br />
              <div class="font-weight-bold">
                *Code fields currently work with Spanish.
              </div>
            </div>
            <br />
            <v-divider class="my-5"></v-divider>
            <div id="currencyField" v-intersect="{
              handler: onIntersect,
              options: intersectOptions
            }"><br>
              <div class="text-h5 mt-0 text-center">Currency field*</div>
              <br />
              <div>
                Currency fields are used when the user needs to input only
                numbers, and when the currency output number must also be
                written in letters.
              </div>
              <br />
              <div class="font-weight-bold">First field:</div>
              <div>
                Write the name of the field between curly brackets “{}”. The
                output will display the number as inputted by the user.
              </div>
              <div>Example: {amount}.</div>
              <div>Input: “350.23”. Output: “350.23”.</div>
              <br />
              <div class="font-weight-bold">Second field:</div>
              <div>
                Write the name of the first field between curly brackets “{}”
                appended with the text “_currencyUnits”. The output will display
                the amount written in letters.
              </div>
              <div>Example: {amount_currencyUnits}</div>
              <div>Input: “350.23”. Output: “three hundred and fifty”</div>
              <br />
              <div class="font-weight-bold">Third field:</div>
              <div>
                Write the name of the first field between curly brackets “{}”
                appended with the text “_currencyDecimals”. The output will
                display the cents written in letters.
              </div>
              <div>Example: {amount_currencyDecimals}</div>
              <div>Input: “350.23”. Output: “with twenty-three cents.”</div>
              <br />
              <div>
                <span class="font-weight-bold">Formula: </span>{fieldName}
                {fieldName_currencyUnits} {fieldName_currencyDecimals}
              </div>
              <br />
              <br />
              <div class="text-decoration-underline">Example:</div>
              <br />
              <div>
                Text: “John Smith agrees to sell 10 laptops for ______ dollars
                ($.____)”
              </div>
              <div>
                Currency fields: {price} {price_currencyUnits}
                {price_currencyDecimals}
              </div>
              <div>
                Text with currency fields: “John Smith agrees to sell 10 laptops
                for {price_currencyUnits} dollars {price_currencyDecimals}
                ($.{price})”
              </div>
              <div>Input: “5,000.25”</div>
              <div>
                Result: “John Smith agrees to sell 10 laptops for five
                thousand dollars with twenty-five cents ($.5,000.25)”
              </div>
              <br />
              <div lass="font-weight-bold">
                *Currency fields currently work with Spanish.
              </div>
            </div>
            <br />
            <v-divider class="my-5"></v-divider>
            <div id="dropdownField" v-intersect="{
              handler: onIntersect,
              options: intersectOptions
            }"><br>
              <div class="text-h5 mt-0 text-center">Dropdown field</div>
              <br />
              <div>
                A dropdown field allows the user to select as input one of the
                dropdown options from a list. The options of the list are
                defined by the users, and can contain any combination of
                letters, numbers, and special characters. The options lists can
                be defined in the template settings. To learn how to modify the
                field settings in a template click here.
              </div>
              <br />
              <div>
                To add a dropdown field in your template, write the name of the
                field appended with the text “_drop” between curly brackets
                “{}”.
              </div>
              <br />
              <div>
                <span class="font-weight-bold">Formula: </span> {fieldName_drop}
              </div>
              <br />
              <br />
              <div class="text-decoration-underline">Example 1:</div>
              <br />
              <div>
                Text: “John Smith agrees to sell 10 ________ for $5,000.00.”
              </div>
              <div>Dropdown field: {product_drop}</div>
              <div>
                Text with dropdown field: “John Smith agrees to sell 10
                {product_drop} for $5,000.00.”
              </div>
              <div>
                Example of user defined options for this case: “computers”,
                “tablets”, “smartphones”.
              </div>
              <div>Input: *selects tablets</div>
              <div>
                Result: “John Smith agrees to sell 10 tablets for $5,000.00.”
              </div>
              <br />
              <br />
              <div class="text-decoration-underline">Example 2:</div>
              <br />
              <div>
                Text: “John Smith agrees to ______ 10 laptops for $5,000.00.”
              </div>
              <div>Dropdown field: {transaction_drop}</div>
              <div>
                Text with dropdown field: “John Smith agrees to
                {transaction_drop} 10 laptops for $5,000.00.”
              </div>
              <div>
                Example of user defined options for this case: “buy”, “sell”.
              </div>
              <div>Input: *selects buy</div>
              <div>
                Result: “John Smith agrees to buy 10 tablets for $5,000.00.”
              </div>
            </div>
            <br />
            <v-divider class="my-5"></v-divider>
            <div id="optionField" v-intersect="{
              handler: onIntersect,
              options: intersectOptions
            }"> <br>
              <div class="text-h5 mt-0 text-center">Option field</div>
              <br />
              <div>
                An option field allows the user to mark a checkbox to include
                optional text in the documents. The optional text is defined in
                the document. Every option field is made up of three elements:
              </div>
              <br />
              <div>Option text start: {#fieldName}</div>
              <div>
                Option: It can contain any text, including other types of
                fields.
              </div>
              <div>Option text end: [/fieldName}</div>
              <div>
                <span class="font-weight-bold">Formula: </span> {#fieldName} option
                text {/fieldName}
              </div>
              <br />
              <br />
              <div class="text-decoration-underline">Example:</div>
              <br />
              <div>
                Text: “John Smith agrees to sell 10 laptops for $5,000.00.”
              </div>
              <div>
                Optional text: “Free keyboards will be included in the sale.”
              </div>
              <div>Option fields: {#freeKeyboards} {/freeKeyboards}</div>
              <div>
                Text with option fields: “John Smith agrees to sell 10 laptops
                for $5,000.00. {#freeKeyboards} Free keyboards will be included
                in the sale. {/freeKeyboards} ”
              </div>
              <div>Input: *checkbox is checked</div>
              <div>
                Result: “John Smith agrees to sell 10 laptops for $5,000.00.
                Free keyboards will be included in the sale.”
              </div>
              <div>Input: *checkbox is unchecked</div>
              <div>
                Result: “John Smith agrees to sell 10 laptops for $5,000.00.”
              </div>
              <br />
              <div>
                Tip: You can use the same field name in different parts of the
                document, with different option texts.
              </div>
            </div>
          </div>
        </v-col>
        <v-col class="d-none d-md-flex" lg="2" md="3">
          <subNav :items="items" :isIntersecting="isIntersecting"></subNav>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import SubNav from '@/components/docs/SubNav'

export default {
  name: 'Fields',
  components: {
    SubNav
  },
  data: () => ({
    isIntersecting: false,
    items: [
      { text: 'Simple field', id: 'simpleField' },
      { text: 'Date field', id: 'dateField' },
      { text: 'Number field', id: 'numberField' },
      { text: 'Code field', id: 'codeField' },
      { text: 'Currency field', id: 'currencyField' },
      { text: 'Dropdown field', id: 'dropdownField' },
      { text: 'Option field', id: 'optionField' }
    ],
    intersectOptions: {
      trackVisibility: true,
      delay: 100,
      rootMargin: '0px',
      threshold: [0.01, 0.02, 0.03, 0.04, 0.05, 0.06, 0.07, 0.08, 0.09, 0.1, 0.11, 0.12, 0.13, 0.14, 0.15, 0.16, 0.17, 0.18, 0.19, 0.2, 0.21, 0.22, 0.23, 0.24, 0.25, 0.26, 0.27, 0.28, 0.29, 0.3, 0.31, 0.32, 0.33, 0.34, 0.35, 0.36, 0.37, 0.38, 0.39, 0.4, 0.41, 0.42, 0.43, 0.44, 0.45, 0.46, 0.47, 0.48, 0.49, 0.5, 0.51, 0.52, 0.53, 0.54, 0.55, 0.56, 0.57, 0.58, 0.59, 0.6, 0.61, 0.62, 0.63, 0.64, 0.65, 0.66, 0.67, 0.68, 0.69, 0.7, 0.71, 0.72, 0.73, 0.74, 0.75, 0.76, 0.77, 0.78, 0.79, 0.8, 0.81, 0.82, 0.83, 0.84, 0.85, 0.86, 0.87, 0.88, 0.89, 0.9, 0.91, 0.92, 0.93, 0.94, 0.95, 0.96, 0.97, 0.98, 0.99, 1, 0]
    }
  }),
  computed: {
    thresholdList () {
      const thresholds = []
      const numSteps = 100

      for (let i = 1.0; i <= numSteps; i++) {
        const ratio = i / numSteps
        thresholds.push(ratio)
      }

      thresholds.push(0)
      return thresholds
    }
  },
  methods: {
    onIntersect (entries, observer, isIntersecting) {
      // https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
      if (entries[0].intersectionRect.top < 100 && (isIntersecting && entries[0].intersectionRatio > 0.50)) {
        // console.log(entries[0].target.id)
        // console.log(entries[0])
        this.isIntersecting = entries[0].target.id
      }
      // this.isIntersecting = isIntersecting // entries[0].intersectionRatio >= 0.5
    }
  },
  mounted () {
    // const recaptcha = this.$recaptchaInstance
    // recaptcha.hideBadge()
  },
  watch: {
  }
}
</script>

<style></style>
