<template>
  <div id="organizingTemplate">
    <v-container>
      <v-row>
        <v-col>
          <div class="text-body-1 text-justify">
            <div class="text-center text-h4">Organizing a template</div>
            <br />
            <br>
            <div id="groupingFields" v-intersect="{
              handler: onIntersect,
              options: intersectOptions
            }">
              <div class="text-h5 mt-0 text-center" text-center>Grouping fields</div>
              <br />
              <div>
                To organize the template and to make filling information easier,
                you can group the fields. To do so, write the name of the group
                with a dot at the end, followed by the name of the field, all
                between curly brackets “{}”. You can name the groups however you
                want, using letters from A to Z, numbers from 0-9, and
                underscore “_”. The name of the group can´t contain accents,
                blank spaces or any other special character.
              </div>
              <br />
              <div>
                <span class="font-weight-bold">Formula: </span> {groupName.fieldName}
              </div>
              <br />
              <div>Grouping can work with any type of fields.</div>
              <br />
              <br />
              <div class="text-decoration-underline">
                Examples: Grouped fields:
              </div>
              <br />
              <div>Simple field: {terms.product}</div>
              <div>Date field: {terms.sale_date}</div>
              <div>
                Number fields: {product.amount_letters} ({product.amount})
              </div>
              <div>Dropdown field: {terms.transaction_drop}</div>
              <div>
                Option field: {#product.freeKeyboards] Option text
                {/product.freeKeyboards}
              </div>
            </div>
            <v-divider class="my-5"></v-divider>
            <div id="optionalGroups" v-intersect="{
              handler: onIntersect,
              options: intersectOptions
            }"> <br>
              <div class="text-h5 mt-0 text-center">Optional Groups</div>
              <br />
              <div>
                An optional group is a derivative of an optional field. Optional groups are displayed as a checkbox on top of the form generated inside Contractflows and have associated fields assigned to them.
                When selected, a new group section will appear within the form alongside the associated fields.
              </div> <br/>
              <div>
                If the optional group is not selected, the optional content (text and fields) will not be written when the document is generated.
              </div><br>
              <div>
                Every optional group is made up of three elements:
              </div>
              <br>
              <ol>
                <li>
                  Optional group start: {#groupName}
                </li><br>
                <li>
                  Optional group content:
                  <ul>
                    <li>
                      Text:
                    </li>
                    <div>
                      It can contain any text.
                    </div>
                    <li>
                      Fields:
                    </li>
                    <div>
                      It can contain fields that are organized into the group.
                    </div>
                  </ul>
                </li><br>
                <li>
                  Optional group end: {/groupName}
                </li>
              </ol>
              <br>
              <div>
                <span class="font-weight-bold">Formula: </span> {#groupName} Optional group content (text and fields)
                 {/groupName}
              </div><br>
              <br />
              <div class="text-decoration-underline">Example of an optional group:</div>
              <br>
              <div>
                Text: “John Smith agrees to sell 10 laptops for $5,000.00.”
              </div>
              <div>
                Optional group content: “{freeKeyboards.amount} free keyboards will be included in the sale.”
              </div>
              <br>
              <div>
                Optional group fields: {#freeKeyboards}  {/freeKeyboards}
              </div>
              <div>
                Text with optional group: “John Smith agrees to sell 10 laptops
                for $5,000.00. {#freeKeyboards} {freeKeyboards.amount} free keyboards will be included
                in the sale. {/freeKeyboards} ”
              </div>
              <br>
              <div>
                If the optional group is selected:
              </div>
              <div>
                *The optional group is displayed in the ContractFlows form, containing the field "{freeKeyboards.amount}"
              </div>
              <div>
                Input: 10
              </div>
              <div>
                Output:
                “John Smith agrees to sell 10 laptops
                for $5,000.00. 10 free keyboards will be included
                in the sale.”
              </div>
            </div>
            <br />
          </div>
        </v-col>
        <v-col class="d-none d-md-flex" lg="2" md="3">
          <subNav :items="items" :isIntersecting="isIntersecting"></subNav>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import SubNav from '@/components/docs/SubNav'

export default {
  name: 'organizingTemplate',
  components: {
    SubNav
  },
  data: () => ({
    isIntersecting: false,
    items: [
      { text: 'Grouping fields', id: 'groupingFields' },
      { text: 'Optional groups', id: 'optionalGroups' }
    ],
    intersectOptions: {
      trackVisibility: true,
      delay: 100,
      rootMargin: '0px',
      threshold: [0.01, 0.02, 0.03, 0.04, 0.05, 0.06, 0.07, 0.08, 0.09, 0.1, 0.11, 0.12, 0.13, 0.14, 0.15, 0.16, 0.17, 0.18, 0.19, 0.2, 0.21, 0.22, 0.23, 0.24, 0.25, 0.26, 0.27, 0.28, 0.29, 0.3, 0.31, 0.32, 0.33, 0.34, 0.35, 0.36, 0.37, 0.38, 0.39, 0.4, 0.41, 0.42, 0.43, 0.44, 0.45, 0.46, 0.47, 0.48, 0.49, 0.5, 0.51, 0.52, 0.53, 0.54, 0.55, 0.56, 0.57, 0.58, 0.59, 0.6, 0.61, 0.62, 0.63, 0.64, 0.65, 0.66, 0.67, 0.68, 0.69, 0.7, 0.71, 0.72, 0.73, 0.74, 0.75, 0.76, 0.77, 0.78, 0.79, 0.8, 0.81, 0.82, 0.83, 0.84, 0.85, 0.86, 0.87, 0.88, 0.89, 0.9, 0.91, 0.92, 0.93, 0.94, 0.95, 0.96, 0.97, 0.98, 0.99, 1, 0]
    }
  }),
  methods: {
    onIntersect (entries, observer, isIntersecting) {
      // https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
      if (entries[0].intersectionRect.top < 100 && (isIntersecting && entries[0].intersectionRatio > 0.50)) {
        // console.log(entries[0].target.id)
        // console.log(entries[0])
        this.isIntersecting = entries[0].target.id
      }
      // this.isIntersecting = isIntersecting // entries[0].intersectionRatio >= 0.5
    }
  },
  mounted () {
    // const recaptcha = this.$recaptchaInstance
    // recaptcha.hideBadge()
  }
}
</script>

<style></style>
