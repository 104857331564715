<template>
  <div id="functions">
    <v-container>
      <!-- Generacion de documentos -->
      <!-- Plantillas -->
      <!-- Alertas -->
      <!-- Formularios Publicos -->
      <!-- Herramientas especificas a cada pais -->
      <!-- Dashboard -->
      <!-- Documentos adjuntos -->
      <!-- Generacion de documentos en grupo -->
      <!-- Integraciones con firma electronica -->
      <!-- roles y equipos de trabajo -->
      <!-- proceso de revision -->

      <!-- Repositorio de documentos con AI -->
      <!-- propiedad horizontal -->
            
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src


export default {
  name: 'Functions',
  components: {
    
  },
  data: () => ({

  })
}
</script>
