/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready (registration) {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
      registration.unregister().then(async () => {
        console.log('unregistered')
        // optionally, get controlled pages to refresh:
        // for (const client of await clients.matchAll()) {
        //   client.navigate(client.url);
        // }
      })
    },
    registered (registration) {
      console.log('Service worker has been registered.')
      registration.unregister().then(async () => {
        console.log('unregistered')
        // optionally, get controlled pages to refresh:
        // for (const client of await clients.matchAll()) {
        //   client.navigate(client.url);
        // }
      })
    },
    cached (registration) {
      console.log('Content has been cached for offline use.')
      registration.unregister().then(async () => {
        console.log('unregistered')
        // optionally, get controlled pages to refresh:
        // for (const client of await clients.matchAll()) {
        //   client.navigate(client.url);
        // }
      })
    },
    updatefound (registration) {
      console.log('New content is downloading.')
      registration.unregister().then(async () => {
        console.log('unregistered')
        // optionally, get controlled pages to refresh:
        // for (const client of await clients.matchAll()) {
        //   client.navigate(client.url);
        // }
      })
    },
    updated (registration) {
      console.log('New content is available; please refresh.')
      registration.unregister().then(async () => {
        console.log('unregistered')
        // optionally, get controlled pages to refresh:
        // for (const client of await clients.matchAll()) {
        //   client.navigate(client.url);
        // }
      })
    },
    offline (registration) {
      console.log('No internet connection found. App is running in offline mode.')
      registration.unregister().then(async () => {
        console.log('unregistered')
        // optionally, get controlled pages to refresh:
        // for (const client of await clients.matchAll()) {
        //   client.navigate(client.url);
        // }
      })
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
