<template>
  <div class="subNav">
    <v-card style="position:fixed" class="mx-auto" flat absolute>
      <v-list flat>
        <!-- <v-subheader>REPORTS</v-subheader> -->
        <v-list-item-group v-model="item" active-class="activeList" no-action color="primary" right>
          <v-list-item
            v-for="(item, i) in items"
            :value="item.id"
            :key="i"
            @click="scrollTo(item.id)"
          >
            <!-- <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon> -->
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'SubNav',
  props: {
    items: {
      type: Array,
      required: true
    },
    isIntersecting: {
      required: true
    },
    scrollOptions: {
      duration: 600,
      offset: 500,
      easing: 'easeInOutQuart'
    }
  },
  data: () => ({
    item: null
    // items: [
    //   { key: 'fields', text: 'Fields', icon: 'mdi-text' },
    //   { key: 'organizingTemplate', text: 'Organizing a template', icon: 'mdi-file-document-outline' },
    //   { key: 'configuringTemplate', text: 'Configuring a template', icon: 'mdi-file-cog-outline' }
    // ]
  }),
  methods: {
    scrollTo (id) {
      this.$vuetify.goTo('#' + id, this.scrollOptions)
    }
  },
  mounted () {
    this.item = this.items[0].id
  },
  watch: {
    isIntersecting: function () {
      // console.log(this.isIntersecting)
      this.item = this.isIntersecting
    },
    item: function () {
      // console.log(this.isIntersecting)
      // this.item = null
    }
  }
}
</script>

<style>
.v-card__text, .v-card__title {
  word-break: normal; /* maybe !important  */
}
</style>
