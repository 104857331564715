<template>
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120.51 107.99">
    <path class="cls-1" d="M52.44,46.1a6.51,6.51,0,0,0,4.53,2A6.59,6.59,0,0,0,61.61,46Q67,40.65,72.32,35.29l2-2a6.12,6.12,0,0,0,1.3-7,5.72,5.72,0,0,0-6.36-3.46A9.46,9.46,0,0,0,65,25.11c-2.21,2.05-4.3,4.17-6.51,6.42l-1.51,1.52c-.66-.71-1.29-1.39-2-2a6.42,6.42,0,0,0-8.87,0,6.18,6.18,0,0,0,0,8.71C48.1,41.83,50.17,43.91,52.44,46.1Z"/><path class="cls-1" d="M120.49,10.42C120.49,3,117.42,0,109.92,0H10.58C3.17,0,0,3.14,0,10.45q0,32.47,0,65c0,7.17,3.09,10.23,10.31,10.25q12.3,0,24.61,0h9c2.08,0,4.22,0,4.54.34s.17,2.44,0,4.49a1.58,1.58,0,0,1-.08.33l-1.76,0c-2.1,0-4.27-.06-6.4,0a13.71,13.71,0,0,0-12.61,11c-.26,1.28-.48,3.16.67,4.55.85,1,2.26,1.53,4.25,1.54,18.81-.06,37.44-.06,55.38,0h0c2,0,3.31-.5,4.15-1.53,1.14-1.4.91-3.3.65-4.61a13.84,13.84,0,0,0-13-11c-2.08-.09-4.21-.07-6.26,0H72.07c0-.57-.09-1-.1-1.42a15.5,15.5,0,0,1,.1-3.51,12.86,12.86,0,0,1,3.4-.21h34.77c7.29,0,10.26-3,10.26-10.27Q120.52,42.9,120.49,10.42ZM55.43,90.58V85.94H65v4.64ZM40.56,97.65c12.12-.17,25-.18,39.27,0A5.79,5.79,0,0,1,85.2,101H35.35A5.41,5.41,0,0,1,40.56,97.65ZM113.8,76.24c0,2-.78,2.76-2.77,2.79-2.35,0-4.74,0-7.06,0H13.26c-2.71,0-5.28,0-5.93-.66s-.63-3.24-.61-6c0-.63,0-1.25.08-2h107v.86C113.81,73,113.81,74.61,113.8,76.24ZM109.68,6.73c3.93,0,4.12.19,4.12,4q0,25.39,0,50.79c0,.55,0,1.12-.08,1.76H6.73v-18q0-17.24,0-34.46c0-3.92.19-4.11,4-4.11Q60.24,6.71,109.68,6.73Z"/>
  </svg>
</template>

<script>
export default {
  name: 'easy'
}
</script>

<style>
  .cls-1{
    fill:#0e3a5e;
  }
</style>
