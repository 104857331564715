import { render, staticRenderFns } from "./SubNav.vue?vue&type=template&id=60f07b45&"
import script from "./SubNav.vue?vue&type=script&lang=js&"
export * from "./SubNav.vue?vue&type=script&lang=js&"
import style0 from "./SubNav.vue?vue&type=style&index=0&id=60f07b45&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports