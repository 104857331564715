<template>
  <div id="contact">
    <v-container>
      <v-row class="text-center pt-5">
        <v-col class="pb-0">
          <div class="text-h3">{{ $t('contactTitle') }}</div>
        </v-col>
      </v-row>
      <v-row justify="center" class="text-center pt-5">
        <v-col xs="12" sm="10" md="9" lg="8" xl="7">
          <div class="pb-2"></div>
            <v-row>
              <v-col cols="12" xl="7" lg="6">
                <v-card color="#F4F4F4" class="py-6">
                  <v-card-text>
                    <v-form ref="form" v-model="valid" lazy-validation>
                      <v-row>
                        <v-col cols="12" class="py-0">
                          <v-text-field
                            v-model="name"
                            :label="$t('name')"
                            :rules="$t('nameRules')"
                            solo
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="py-0">
                          <v-autocomplete
                            :items="countries"
                            item-text="name"
                            :label="$t('country')"
                            v-model="country"
                            :rules="$t('countryRules')"
                            solo
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" class="py-0">
                          <v-text-field
                            v-model="email"
                            :label="$t('email')"
                            :rules="$t('emailRules')"
                            solo
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="py-0">
                          <v-text-field
                            v-model="subject"
                            :label="$t('subject')"
                            :rules="$t('subjectRules')"
                            solo
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="py-0">
                          <v-textarea
                            v-model="message"
                            :label="$t('message')"
                            :rules="$t('messageRules')"
                            solo
                          ></v-textarea>
                        </v-col>
                        <v-col xs="12" sm="12" md="12" lg="12" xl="12" class="text-left py-0">
                          <v-checkbox v-model="agreement" :color="agreement && 'grey' || 'primary'" required :rules="$t('agreementRules')">
                            <template v-slot:label>
                              <div :class="agreement && 'grey--text' || 'text'" class="ml-0">{{ $t('agreement') }}</div>
                              <v-spacer></v-spacer>
                              <v-scroll-x-transition>
                                <v-icon v-if="agreement" color="success">
                                  mdi-check
                                </v-icon>
                              </v-scroll-x-transition>
                            </template>
                          </v-checkbox>
                        </v-col>
                        <v-col cols="12" class="mx-auto text-center">
                          <v-btn color="primary" x-large :loading="submitting" @click="submitContact()" block>
                            {{ $t('submit') }}
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" xl="5" lg="6">
                <v-card color="#F4F4F4" lass="py-6">
                  <v-card-text class="text-center black--text">
                    <v-row>
                      <v-col>
                        <div class="text-h5 font-weight-medium">{{ $t('whatsText1') }}</div>
                        <div class="text-h5 font-weight-medium">{{ $t('whatsText2') }}</div>
                      </v-col>
                    </v-row>
                    <v-row justify="center">
                      <v-col cols="12">
                        <v-btn x-large :href="getWhatsappLink('Hola equipo Contractflows! Quisiera obtener más información.')" target='_blank' color="#25D366" dark fab>
                          <v-icon size="55">mdi-whatsapp</v-icon>
                        </v-btn>
                      </v-col>
                      <v-col class="pt-0" cols="12">
                        <div class="text-caption">+502 5512 2473</div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          <div class="py-12"></div>
        </v-col>
      </v-row>
    </v-container>

    <v-snackbar v-model="snackbar" :color="snackbarColor">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
const axios = require('axios')
// const superagent = require('superagent')

export default {
  name: 'Contact',
  components: {
  },
  data: () => ({
    whatsappWebLink: 'https://web.whatsapp.com/send?phone=50255122473',
    whatsappAppLink: 'https://wa.me/50255122473',
    recaptchaKey: '6LfypKkZAAAAADNABpW27E0gYmf6Be6j3UPyKC3b',
    valid: true,
    name: '',
    nameRules: [
      v => !!v || 'Name is required',
      v => (v && v.length <= 30) || 'Name must be less than 30 characters'
    ],
    country: '',
    countryRules: [
      v => !!v || 'Country is required'
    ],
    email: '',
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
    ],
    subject: '',
    subjectRules: [
      v => !!v || 'Subject is required'
    ],
    message: '',
    messageRules: [
      v => !!v || 'Message is required'
    ],
    countries: [],
    agreement: false,
    submitting: false,
    snackbar: false,
    snackbarText: '',
    snackbarTimeout: 5000,
    snackbarColor: ''
  }),
  methods: {
    getWhatsappLink(text=null){
      if(text == null){
        if(this.$vuetify.breakpoint.mobile){
          return this.whatsappAppLink
        }
        return this.whatsappWebLink
      }
      if(this.$vuetify.breakpoint.mobile){
        return encodeURI(this.whatsappAppLink + '?text='+text)
      }
      return encodeURI(this.whatsappWebLink + '&text='+text)
    },
    validate () {
      return this.$refs.form.validate()
    },
    reset () {
      this.$refs.form.reset()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    },
    async submitContact () {
      const vm = this
      vm.submitting = true
      // eslint-disable-next-line
      if (!this.validate()) {
        this.setSnackbar('Check form for errors', 'error')
        return
      }
      await this.$recaptchaLoaded()
      const token = await this.$recaptcha('contactForm')
      var data = JSON.stringify({
        email: this.email,
        name: this.name,
        subject: this.subject,
        message: this.message,
        country: this.country,
        agreement: this.agreement,
        recaptcha: token
      })
      var config = {
        method: 'POST',
        url: process.env.VUE_APP_CONTACT,
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      }
      // eslint-disable-next-line
      axios(config).then(function (response) {
        vm.setSnackbar('Info sent successfully!', 'success')
        vm.submitting = false
        vm.reset()
        vm.resetValidation()
        // console.log(JSON.stringify(response.data))
        // eslint-disable-next-line
      }).catch(function (error) {
        vm.submitting = false
        vm.setSnackbar('Error making the request', 'error')
        console.error(error)
      })
    },
    setSnackbar (text, color, timeout = 5000) {
      this.snackbarText = text
      this.snackbarColor = color
      this.snackbarTimeout = timeout
      this.snackbar = true
    }
  },
  mounted () {
    // const recaptcha = this.$recaptchaInstance
    // recaptcha.showBadge()
    this.countries = require('../../countries.json')
    this.$vuetify.goTo('#contact')
  }
}
</script>

<style>
.v-card__text, .v-card__title {
  word-break: normal; /* maybe !important  */
}
</style>
