<template>
  <div>
    <v-sheet elevation="3">
      <v-simple-table fixed-header>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-center">
                <div class="text-h4 py-5">{{ $t('pricingM.functions') }}</div>
              </th>
              <th class="text-center text-h6" v-for="(plan, idx) in plans" :key="idx">
                {{ plan.title }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="func in functionalities" :key="func">
              <td class="text-center">
                {{func}}
                <v-tooltip bottom max-width="200">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon small color="grey" dark v-bind="attrs" v-on="on">
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span>{{ $t('pricingM.functionalitiesDescribed')[func] }}</span>
                </v-tooltip>
              </td>
              <td class="text-center" v-for="plan in plans" :key="plan.title">
                <v-icon v-if="plan.details[func] === true" color="green darken-2">mdi-check-circle</v-icon>
                <div v-else-if="plan.details[func] === 'inProgress'">{{ $t('pricingM.coming') }}</div>
                <v-icon dense v-else disabled>mdi-close</v-icon>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-sheet>
  </div>
</template>

<script>
export default {
  name: 'comparisonTable',
  props: {
    plans: {
      required: true,
      type: Array
    }
  },
  components: {
  },
  data: () => ({
  }),
  computed: {
    formattedPlans: function () {
      this.plans.forEach(key => {
        console.log(key)
      })
      return []
    },
    functionalities: function () {
      return this.$t('pricingM.functionalities')
    }
  },
  methods: {
  }

}
</script>

<style>

</style>
